import React, { useEffect, useState } from "react";
import { withRouter } from "utilities/withRouter";
import { useSelector } from "react-redux";
import "./groupInformation.css";

import { Localize } from "utilities/localize";
import GroupType from "../groupType/groupType";
import GroupName from "../groupName/groupName";
import GroupNoOfTravellers from "../groupNoOfTravellers/groupNoOfTravellers";

function GroupInformation(props) {
    const [rerender, setRerender] = useState(false);
    let { quote, handleStep1Data, errors } = props;
    let { groupInformation } = quote;
    let sfdc = useSelector((state) => state.sfdc);

    useEffect(() => {
        
        setTimeout(() => {
            updateDetails();
            setRerender(!rerender);
        }, 100);
    },[sfdc, quote, rerender]);

    function updateDetails(){
        if (sfdc?.data && sfdc?.data !== null) {
            let groupInfo = sfdc?.data?.data;
            groupInformation = groupInfo;
            quote.groupInformation = groupInformation;
        }
    }

    if (!groupInformation) {
        return null;
    }
    let { groupName, groupType, noOfTravellers } = groupInformation || {};
   

    return (
        <div className="group-information-container">

            <div className="row font-bold margin-bottom-2x">
                {Localize("qma-group-information-header")}
            </div>

            <div className="row responsive">
                <div className="col-3">
                    <GroupName
                        quote={quote}
                        errors={errors}
                        groupName={groupName}
                        error={errors?.groupName}
                        handleStep1Data={handleStep1Data} />
                </div>

                <div className="col-3">
                    <GroupType quote={quote} errors={errors} groupType={groupType} error={errors?.groupType} handleStep1Data={handleStep1Data} />
                </div>

                <div className="col-3-no-margin-r">
                    <GroupNoOfTravellers quote={quote} errors={errors} noOfTravellers={noOfTravellers} error={errors?.noOfTravellers} handleStep1Data={handleStep1Data} />
                </div>
            </div>
        </div>
    );
}
// }


export default withRouter(GroupInformation);