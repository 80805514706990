import * as actions from "./actions";

export default function groupContractReducer(state = {}, action) {
    switch (action.type) {

    case actions.GET_GROUP_CONTRACT_SUCCESS:
    case actions.GET_GROUP_CONTRACT_FAIL:
    case actions.GET_GROUP_CONTRACT_UPDATE_SUCCESS:
    case actions.GET_GROUP_CONTRACT_UPDATE_FAIL:
    case action.GET_GROUP_CONTRACT_EMAIL_SUCCESS:
    case action.GET_GROUP_CONTRACT_EMAIL_FAIL:
    {
        return action.payload;
    }
    default:
        return state;

    }
}
