import React, { Fragment } from "react";
import { withRouter } from "utilities/withRouter";

const StayTuned = (props) => {
    return (
        <Fragment>
            <div className="text-center box-container">
                <div className="loading-image-center margin-bottom-1x">
                    <img
                        src= {window?.location?.origin + "/images/Loading_screen.gif" }
                        alt="loading please wait!.."
                    />
                </div>
                <div className="row option-heading margin-bottom-1x">
                    <div className="heart"></div>
                </div>
                <div className="row">
                    {props.localize("qma-best-quote")}
                </div>
            </div>
        </Fragment>
    );
};
export default withRouter(StayTuned);
