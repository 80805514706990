import { client } from "api/client";
import * as actions from "modules/common/store/actions";
import { CONFIGURATION } from "utilities/configuration";
import { GetLanguageCode } from "utilities/localize";
import * as utility from "utilities/utils";

export default function getDepartureCities(providerCode, productType, isManual) {

    const LANGUAGE = GetLanguageCode();
    const PRODUCT_TYPE = productType.toLowerCase();
    const SEARCH_ALIAS = CONFIGURATION.SEARCH_ALIAS;
    let DEPARTURE_URL = process.env.REACT_APP_DEPARTURE + "?lang=" + LANGUAGE + "&type=" + PRODUCT_TYPE + "&alias=" + SEARCH_ALIAS;
    
    if(!isManual) {
        DEPARTURE_URL +=  "&dest=" + providerCode;
    }

    return async (dispatch) => {
        dispatch({
            type: actions.RESET_DEPARTURE
        });
        return await client.get(DEPARTURE_URL).then((response) => {

            let cities = [];
            
            response?.map(departureCity => {
                cities.push({ code: departureCity.Code, name: departureCity.Name, providerCode: departureCity.Code, displayText: departureCity.Name + " (" + departureCity.Code + ")" });
            });

            return dispatch({
                type: actions.GET_DEPARTURE_CITIES_SUCCESS,
                payload: utility.getResponse(cities)
            });

        })
            .catch((error) => {
                return dispatch({
                    type: actions.GET_DEPARTURE_CITIES_FAIL,
                    payload: utility.getErrorResponse(error)
                });
            });

    };

}
