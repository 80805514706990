import { CONFIGURATION } from "utilities/configuration";

function search(state) {
    let { params } = state;

    params.quoteNumber = "";
    params.groupName = "";
    params.createdOn = null;
    params.departureDate = null;
    params.destination = "";
    params.departure = "";
    params.pageIndex = CONFIGURATION.PAGE_INDEX;
    params.pageSize = CONFIGURATION.PAGE_RECORD_SIZE;
    params.brand = process.env.REACT_APP_BRAND.toUpperCase();

    return { params };
}

const clearData = {
    search
};

export default clearData;
