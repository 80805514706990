import { client } from "api/client";
import * as actions from "modules/common/store/actions";
import * as utility from "utilities/utils";

export default function getReQuoteDetails(quoteNumber) {

    return async (dispatch) => {

        return await client.get(process.env.REACT_APP_RE_QUOTE_GET + "/" + quoteNumber).then((response) => {

            return dispatch({
                type: actions.GET_RE_QUOTE_SUCCESS,
                payload: utility.getResponse(response)
            });

        })
            .catch((error) => {

                return dispatch({
                    type: actions.GET_RE_QUOTE_FAIL,
                    payload: utility.getErrorResponse(error)
                });

            });


    };

}
