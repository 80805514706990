export const quoteModel = {
    quoteNumber: "",
    groupInformation: {
        groupName: "",
        groupType: "",
        noOfTravellers: ""
    },
    productTypes: [],
    deviationTypes: [],
    additionalRequirements: "",
    brand: process.env.REACT_APP_BRAND.toUpperCase()
};
