import { client } from "api/client";
import * as actions from "modules/common/store/actions";
import * as utility from "utilities/utils";

export default function getGroupContract() {

    return async (dispatch) => {

        return await client.get(process.env.REACT_APP_GROUP_CONTRACT).then((response) => {
            
            const languageCode = response?.data?.language.toLowerCase();
            const userLanguage = (languageCode === "french" ? "fr" : "en");
            localStorage.setItem("userLanguage", userLanguage);

            return dispatch({
                type: actions.GET_GROUP_CONTRACT_SUCCESS,
                payload: utility.getResponse(response)
            });

        })
            .catch((error) => {

                return dispatch({
                    type: actions.GET_GROUP_CONTRACT_FAIL,
                    payload: utility.getErrorResponse(error)
                });

            });


    };

}
