import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { CONSTANTS } from "modules/common/constants";
import Button from "@mui/material/Button";
import { Icon } from "@ICON_REFERENCE";
import ReactHtmlParser from "react-html-parser";

import Promotion from "components/common/promotions/promotions";
import commonUtilities from "utilities/common";
import { Localize } from "utilities/localize";
import { CONFIGURATION } from "utilities/configuration";
import ProductTypeIcon from "../../../common/productTypeIcon/productTypeIcon";

import "./productTypeItinerary.css";

class ProductTypeItinerary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isPromotion: false
        };
    }

    showPromotions = (e) => {
        document.body.style.overflow = "hidden";
        let { isPromotion } = this.state;
        isPromotion = true;
        e.stopPropagation();
        this.setState({ isPromotion });

    };

    resetPromotions = (e) => {
        document.body.style.overflow = "visible";
        let { isPromotion } = this.state;
        isPromotion = false;
        e.stopPropagation();
        this.setState({ isPromotion });

    };

    render() {
        let { isPromotion } = this.state;
        const { quote, productType, quoteOption, product, handleItinerary } = this.props;
        let { itineraries } = product;
        const isItinerary = true;

        const date = new Date();
        date.setHours(0,0,0,0);
        const TODAY = date.toDateString();

        if (itineraries) {
            return (
                <div className="row view-quote-product-type product-type-container">
                    {itineraries?.map((itinerary, index) => (
                        itinerary?.isActive ? 
                            <div key={index} className="row">
                                <div
                                    id={"itinerary_" + itinerary.itineraryNumber}
                                    data-testid="itinerary-click"
                                    className={
                                        itinerary.isExpanded !== false
                                            ? "view-quote-product-type-itinerary-row active-row margin-top"
                                            : "view-quote-product-type-itinerary-row margin-top"
                                    }
                                    onClick={(event) =>
                                        handleItinerary(event, itinerary, isItinerary)
                                    }
                                >
                                    <div className="pull-left option-icon">
                                        <ProductTypeIcon productType={quoteOption?.productType?.code?.toLowerCase()} />
                                    </div>

                                    <div className="pull-right">
                                        <div className="pull-left">
                                            <Icon
                                                name={
                                                    itinerary.isExpanded !== false
                                                        ? "arrow-up"
                                                        : "arrow-down"
                                                }
                                                className="icon-12"
                                            />
                                        </div>
                                    </div>
                                    <div className="itinerary-header">
                                        {Localize("qma-itinerary") + " "}
                                        {itinerary.itineraryNumber}
                                        {itinerary.status === "Inactive" || itinerary.status === "Pending" ? null :
                                            (itinerary.itineraryValidDate !== "" &&
                                            itinerary.itineraryValidDate !== null ? (
                                                commonUtilities.calculateDuration(TODAY, itinerary.itineraryValidDate) < 0 ? (
                                                    <span> | {Localize("qma-validity")} : {Localize("qma-expired")}</span> 
                                                ) : commonUtilities.calculateDuration(
                                                        TODAY,
                                                        itinerary.itineraryValidDate
                                                    ) < 4 &&
                                                        commonUtilities.calculateDuration(
                                                            TODAY,
                                                            itinerary.itineraryValidDate
                                                        ) >= 0 ? (
                                                <div className="itinerary-validity">
                                                    <span className="itinerary-validity-vertical-bar"> | </span>{Localize("qma-validity")}:<span class="swg-icon swg-icon-alert--o icon-12 font-icon margin-left margin-right"></span>
                                                    <span class="font-warning">{Localize("qma-expires-on") + " "}{moment(itinerary.itineraryValidDate).format("DD MMM YYYY")}</span>
                                                </div>
                                                ) : (
                                                <div className="itinerary-validity">
                                                    <span className="itinerary-validity-vertical-bar"> | </span>{Localize("qma-validity")}:<span class="swg-icon swg-icon-calendar icon-12 font-icon margin-left margin-right"></span>
                                                    <span>{Localize("qma-expires-on") + " "}{moment(itinerary.itineraryValidDate).format("DD MMM YYYY")}</span>
                                                </div>
                                                )
                                            ) : (
                                                <span> | {Localize("qma-validity")} : {Localize("qma-expired")}</span>
                                            ))
                                        }
                                    </div>
                                    <div className="pull-right margin-right hide-add-ons itinerary-short-info">
                                        <div className="pull-left margin-right">
                                            {quoteOption?.productType?.code?.trim().toLowerCase() !== CONSTANTS?.HOTEL?.toLowerCase() && <Icon name={quoteOption?.productType?.code?.trim().toLowerCase() === CONSTANTS?.CRUISE?.toLowerCase() ? CONSTANTS.CRUISE.toLowerCase() : CONSTANTS.FLIGHT} />}
                                        </div>
                                        <div className="pull-left margin-right">
                                            {itinerary.heading}
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className={
                                        itinerary.isExpanded !== false
                                            ? "show view-quote-product-type-itinerary-details"
                                            : "print-itinerary hide"
                                    }
                                >
                                    {

                                        (quoteOption?.productType?.code.trim().toLowerCase() === "flight" || quoteOption?.productType?.code.trim().toLowerCase() === "hotel" ||
                                            quoteOption?.productType?.code.trim().toLowerCase() === "package" || quoteOption?.productType?.code.trim().toLowerCase() === "cruise")
                                            ? <div className="row">
                                                {itinerary?.itineraryNotes !== null ?
                                                    <div>
                                                        <strong>
                                                            {Localize("qma-itinerary-notes")}
                                                        </strong>
                                                        <div>
                                                            {itinerary?.itineraryNotes}
                                                        </div>
                                                    </div> : ""}
                                            </div> : ""}

                                    {

                                        (quoteOption?.productType?.code.trim().toLowerCase() === "flight" ||
                                            quoteOption?.productType?.code.trim().toLowerCase() === "package" || quoteOption?.productType?.code.trim().toLowerCase() === "cruise")
                                            ? <div className="row">
                                                <div className="view-quote-product-type-itinerary-details-col-1">
                                                    <div className="row margin-top-2x">
                                                        <div className="pull-left font-icon">
                                                            <ProductTypeIcon productType="flight" />
                                                        </div>
                                                        <strong>
                                                            {Localize("qma-flight")}
                                                            {itinerary?.itineraryDetail?.flight?.sourceToDestination?.flightNo}
                                                        </strong>
                                                    </div>
                                                    <div className="row">
                                                        <div className="pull-left margin-right">
                                                            {itinerary?.itineraryDetail?.flight?.sourceToDestination?.departure?.code !== ""
                                                                ? "(" +
                                                                itinerary.itineraryDetail?.flight?.sourceToDestination?.departure?.code +
                                                                ") "
                                                                : ""}
                                                            {itinerary.itineraryDetail?.flight?.sourceToDestination?.departure?.name !== ""
                                                                ? itinerary.itineraryDetail?.flight?.sourceToDestination?.departure?.name
                                                                : ""}
                                                        </div>

                                                        <div className="pull-left margin-right rotate-90 margin-top-1-4">
                                                            <ProductTypeIcon productType="flight" />
                                                        </div>

                                                        <div className="pull-left margin-right">
                                                            {itinerary.itineraryDetail?.flight?.sourceToDestination?.arrival?.code !== ""
                                                                ? "(" +
                                                                itinerary.itineraryDetail?.flight?.sourceToDestination?.arrival?.code +
                                                                ") "
                                                                : ""}
                                                            {itinerary.itineraryDetail?.flight?.sourceToDestination?.arrival?.name !== ""
                                                                ? itinerary.itineraryDetail?.flight?.sourceToDestination?.arrival?.name
                                                                : ""}
                                                        </div>  

                                                    </div>
                                                </div>
                                                <div className="view-quote-product-type-itinerary-details-col-2">

                                                    <div className="row margin-top-2x">
                                                        {Localize("qma-departing-on") + " "}
                                                        {moment(itinerary?.itineraryDetail?.flight?.sourceToDestination?.departure?.date).format(CONFIGURATION.DATE_FORMAT)}
                                                        {" " + Localize("qma-at") + " "}
                                                        {itinerary.itineraryDetail?.flight?.sourceToDestination?.departure?.time}
                                                    </div>

                                                    <div className="row">
                                                        {Localize("qma-arriving-on") + " "}
                                                        {moment(itinerary?.itineraryDetail?.flight?.sourceToDestination?.arrival?.date).format(CONFIGURATION.DATE_FORMAT)}
                                                        {" " + Localize("qma-at") + " "}
                                                        {itinerary.itineraryDetail?.flight?.sourceToDestination?.arrival?.time}
                                                    </div>
                                                </div>

                                                <div className="view-quote-product-type-itinerary-details-col-3">
                                                    <div className="row margin-top-2x">{" "}</div>
                                                    <div className="row">
                                                        {itinerary?.itineraryDetail?.flight?.sourceToDestination?.airlineName}
                                                    </div>
                                                </div>
                                                {itinerary?.itineraryDetail?.flight?.sourceToDestination?.via !== null && itinerary?.itineraryDetail?.flight?.sourceToDestination?.via !== "" &&
                                                <div className="row">
                                                    <div className="pull-left margin-right">
                                                        <strong>{Localize("qma-via").toUpperCase() + " " + itinerary?.itineraryDetail?.flight?.sourceToDestination?.via}</strong>  
                                                    </div>
                                                </div>}                                                
                                            </div>
                                            : null
                                    }

                                    {
                                        (quoteOption?.productType?.code.trim().toLowerCase() === "flight" ||
                                            quoteOption?.productType?.code.trim().toLowerCase() === "package" || quoteOption?.productType?.code.trim().toLowerCase() === "cruise")
                                            ? <div className="row margin-top-2x">
                                                <div className="view-quote-product-type-itinerary-details-col-1">
                                                    <div className="row margin-top-2x">
                                                        <div className="pull-left font-icon">
                                                            <ProductTypeIcon productType="flight" />
                                                        </div>
                                                        <strong>
                                                            {Localize("qma-flight")}
                                                            {itinerary?.itineraryDetail?.flight?.destinationToSource?.flightNo}
                                                        </strong>
                                                    </div>
                                                    <div className="row">
                                                        <div className="pull-left margin-right">
                                                            {itinerary?.itineraryDetail?.flight?.destinationToSource?.departure?.code !== ""
                                                                ? "(" +
                                                                itinerary?.itineraryDetail?.flight?.destinationToSource?.departure?.code +
                                                                ") "
                                                                : ""}
                                                            {itinerary?.itineraryDetail?.flight?.destinationToSource?.departure?.name !== ""
                                                                ? itinerary.itineraryDetail?.flight?.destinationToSource?.departure?.name
                                                                : ""}
                                                        </div>
                                                        <div className="pull-left margin-right rotate-90 margin-top-1-4">
                                                            <ProductTypeIcon productType="flight" />
                                                        </div>
                                                        <div className="pull-left margin-right">
                                                            {itinerary?.itineraryDetail?.flight?.destinationToSource?.arrival?.code !== ""
                                                                ? "(" +
                                                                itinerary?.itineraryDetail?.flight?.destinationToSource?.arrival?.code +
                                                                ") "
                                                                : ""}
                                                            {itinerary?.itineraryDetail?.flight?.destinationToSource?.arrival?.name !== ""
                                                                ? itinerary?.itineraryDetail?.flight?.destinationToSource?.arrival?.name
                                                                : ""}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="view-quote-product-type-itinerary-details-col-2">
                                                    <div className="row margin-top-2x">
                                                        {Localize("qma-departing-on") + " "}
                                                        {moment(itinerary?.itineraryDetail?.flight?.destinationToSource?.departure?.date).format(CONFIGURATION.DATE_FORMAT)}
                                                        {" " + Localize("qma-at") + " "}
                                                        {itinerary?.itineraryDetail?.flight?.destinationToSource?.departure?.time}
                                                    </div>
                                                    <div className="row">
                                                        {Localize("qma-arriving-on") + " "}
                                                        {moment(itinerary?.itineraryDetail?.flight?.destinationToSource?.arrival?.date).format(CONFIGURATION.DATE_FORMAT)}
                                                        {" " + Localize("qma-at") + " "}
                                                        {itinerary?.itineraryDetail?.flight?.destinationToSource?.arrival?.time}
                                                    </div>
                                                </div>
                                                <div className="view-quote-product-type-itinerary-details-col-3">
                                                    <div className="row margin-top-2x">{" "}</div>
                                                    <div className="row">
                                                        {itinerary?.itineraryDetail?.flight?.destinationToSource?.airlineName}
                                                    </div>
                                                </div>
                                                {itinerary?.itineraryDetail?.flight?.destinationToSource?.via !== null && itinerary?.itineraryDetail?.flight?.destinationToSource?.via !== "" &&
                                                <div className="row">
                                                    <div className="pull-left margin-right">
                                                        <strong>{Localize("qma-via").toUpperCase() + " " + itinerary?.itineraryDetail?.flight?.destinationToSource?.via}</strong>
                                                    </div>
                                                </div>}
                                            </div>                                            
                                            : null
                                    }

                                    {
                                        quoteOption?.productType?.code.trim().toLowerCase() === "flight"
                                            ? ""
                                            : <div className="row margin-top-2x">
                                                <div className="row">
                                                    <div className="row">
                                                        <div className="pull-left font-icon">
                                                            <ProductTypeIcon productType="hotel" />
                                                        </div>
                                                        <strong>{product.name}</strong>
                                                    </div>
                                                    <div className="row">
                                                        {product.city + ", " + product.country}
                                                    </div>
                                                </div>

                                                <div className="row margin-top-1x">
                                                    <div className="hotel-pic">
                                                        {itinerary?.itineraryDetail?.roomType?.url?.trim().length > 0 ? (
                                                            <img src={itinerary?.itineraryDetail?.roomType?.url} />
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>

                                                    <div className="view-quote-product-type-itinerary-details-guest-col">
                                                        <div className="row">
                                                            <strong>{itinerary?.itineraryDetail?.roomType?.name}</strong> &nbsp;

                                                            {itinerary?.status?.toLowerCase() === "unavailable" || (quoteOption.globalRestrictions.length > 0 || itinerary?.itineraryDetail?.roomType?.hasRestrictions) && (itinerary?.rulesDisplayPrice === null || itinerary?.rulesDisplayPrice?.toLowerCase() === "no")?
                                                            (
                                                                ""
                                                            ): (quoteOption.globalRestrictions.length > 0 || itinerary?.itineraryDetail?.roomType?.hasRestrictions) && itinerary?.rulesDisplayPrice?.toLowerCase() === "yes"? <span className="itinerary-limitedInventoryText"><strong>{"! " + itinerary?.rulesMessage}</strong></span>:
                                                            (quoteOption.globalRestrictions.length <= 0 && !itinerary?.itineraryDetail?.roomType?.hasRestrictions) && itinerary?.rulesDisplayPrice?.toLowerCase() === "yes" ? <span className="itinerary-limitedInventoryText"><strong>{"! " + itinerary?.rulesMessage}</strong></span>:""                                                               
                                                            }
                                                        </div>


                                                        <div className="row">
                                                            {itinerary?.itineraryDetail?.roomType?.information}
                                                        </div>

                                                        <div className="row">
                                                            {itinerary?.itineraryDetail?.roomType?.minMaxOccupancyText}
                                                        </div>

                                                        <div className="row">
                                                            <strong>{itinerary?.itineraryDetail?.roomType?.minAdultInformationText}</strong>
                                                        </div>

                                                        
                                                        { itinerary?.itineraryDetail?.roomType?.pricing?.length > 0 && quoteOption?.globalRestrictions?.length <= 0 && !itinerary?.itineraryDetail?.roomType?.hasRestrictions && itinerary?.status?.toLowerCase() !== "pending" && itinerary?.status?.toLowerCase() !== "unavailable" ? (
                                                            <div className="row margin-top-2x  person-price-details">
                                                                <div className="col-3">
                                                                    <strong>
                                                                        {Localize("qma-guest-per-room")}
                                                                    </strong>
                                                                </div>
                                                                <div className="pull-left">
                                                                    <strong>
                                                                        {Localize("qma-early-booking")}
                                                                    </strong>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ((itinerary?.status?.toLowerCase() === "" && itinerary?.rulesDisplayPrice?.toLowerCase() === "yes") || itinerary?.status?.toLowerCase() === "adjusted" || itinerary?.status?.toLowerCase() === "manual") ? "":
                                                            (itinerary?.status?.toLowerCase() === "pending" || itinerary?.status?.toLowerCase() === "unavailable") || (quoteOption.globalRestrictions.length > 0 || itinerary?.itineraryDetail?.roomType?.hasRestrictions) || (quoteOption.globalRestrictions.length <= 0 && !itinerary?.itineraryDetail?.roomType?.hasRestrictions && (itinerary?.rulesDisplayPrice?.toLowerCase() === "no"))
                                                                    ? <div className="warning-bar margin-top-2x">
                                                                        <div className="warning-icon">
                                                                        <Icon
                                                                        name="alert"
                                                                        className="icon-16 font-yellow"
                                                                        />
                                                                        </div>
                                                                        <div className="warning-text">
                                                                        <div className="row">
                                                                        <strong>{itinerary?.status?.toLowerCase() === "pending" ? itinerary?.itineraryDetail?.pendingStatusDisplayText : (itinerary?.status?.toLowerCase() === "unavailable") ? itinerary?.unAvailableItineraryMessage :(quoteOption.globalRestrictions.length > 0 || itinerary?.itineraryDetail?.roomType?.hasRestrictions) ? itinerary?.itineraryDetail?.pendingStatusDisplayText : (quoteOption.globalRestrictions.length <= 0 && !itinerary?.itineraryDetail?.roomType?.hasRestrictions && itinerary?.rulesDisplayPrice?.toLowerCase() === "no") ? itinerary?.rulesMessage : ""}</strong>
                                                                        </div>
                                                                    </div>
                                                                    </div> : ""                                                               
                                                        )}

                                                        <div className="row margin-top">
                                                            {itinerary?.status?.toLowerCase() === "pending" || itinerary?.status?.toLowerCase() === "unavailable" || itinerary?.itineraryDetail?.roomType?.pricing?.length <= 0 ? "" : (quoteOption.globalRestrictions.length <= 0 && !itinerary?.itineraryDetail?.roomType?.hasRestrictions || itinerary?.status?.toLowerCase() === "adjusted" || itinerary?.status?.toLowerCase() === "manual") && itinerary?.itineraryDetail?.roomType?.pricing?.map((guest, guestIndex) => (
                                                                <div key={guestIndex} className="row d-flex">
                                                                    <div className="early-pricing-row col-3">
                                                                        {
                                                                            guest.ageRange === "INF" ? `${guest.guestPerRoomOrdinal} ${Localize("qma-infant")}` :
                                                                                (guest.ageRange === "" ? (guest.guestPerRoom === 1
                                                                                    ? `${guest.guestPerRoomOrdinal} ${Localize("qma-adult")}`
                                                                                    : `${guest.guestPerRoomOrdinal} ${Localize("qma-adults")}`) :
                                                                                    `${guest.guestPerRoomOrdinal} ${Localize("qma-child")} (Age ${guest.ageRange})`)
                                                                        }


                                                                    </div>
                                                                    <div className=" early-booking-row col-6 d-flex">
                                                                        <div className="pull-left margin-right">
                                                                            { commonUtilities.translateCurrencyFormat(guest.sellingPrice) }
                                                                        </div>

                                                                        {guest.ebbPax !== "" && guest.ebbPax !== 0 ? (
                                                                            <div className="pull-left d-flex">
                                                                                <div className="pull-left margin-right">
                                                                                    <Icon
                                                                                        name="deals"
                                                                                        className="icon-12 font-green"
                                                                                    />
                                                                                </div>
                                                                                <div className="pull-left d-flex">
                                                                                    {Localize("qma-saving-of")} &nbsp;
                                                                                    { commonUtilities.translateCurrencyFormat(guest.ebbPax) }&nbsp;{Localize("qma-saving-of-included")}
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                    }

                                    {
                                        quoteOption?.productType?.code.trim().toLowerCase() === "flight"
                                            ?
                                            (itinerary?.status?.toLowerCase() === "pending" || itinerary?.status?.toLowerCase() === "unavailable") ?
                                                <div className="warning-bar margin-top-2x">
                                                    <div className="warning-icon">
                                                        <Icon
                                                            name="alert"
                                                            className="icon-16 font-yellow"
                                                        />
                                                    </div>
                                                    <div className="warning-text">
                                                        <div className="row">
                                                            <strong>
                                                                {itinerary?.status?.toLowerCase() === "pending" ? itinerary?.itineraryDetail?.pendingStatusDisplayText + " " : itinerary?.unAvailableItineraryMessage}
                                                            </strong>
                                                        </div>
                                                    </div>
                                                </div>
                                                :

                                                <div>
                                                    <div className="row margin-top-2x">
                                                        <div className="col-2">
                                                            <strong>
                                                                {Localize("qma-persons")}
                                                            </strong>
                                                        </div>
                                                        <div className="pull-left">
                                                            <strong>
                                                                {Localize("qma-price")}
                                                            </strong>
                                                        </div>
                                                    </div>

                                                    <div className="row price-container margin-top">

                                                        <div className="row d-flex">
                                                            <div className="col-2">
                                                                {Localize("qma-adults")}
                                                            </div>
                                                            <div className="pull-left">
                                                                {"$" + itinerary?.itineraryDetail?.flight?.adultTotalPrice}
                                                            </div>
                                                        </div>
                                                        <div className="row d-flex">
                                                            <div className="col-2">
                                                                {Localize("qma-child-age")}
                                                            </div>
                                                            <div className="pull-left margin-right">
                                                                {"$" + itinerary?.itineraryDetail?.flight?.childTotalPrice}
                                                            </div>
                                                        </div>
                                                        <div className="row d-flex">
                                                            <div className="col-2">
                                                                {Localize("qma-infant")}
                                                            </div>
                                                            <div className="pull-left">
                                                                {"$" + itinerary?.itineraryDetail?.flight?.infantTotalPrice}
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="row margin-top">
                                                        {ReactHtmlParser(itinerary?.itineraryDetail?.taxAndFees?.displayText)}
                                                    </div>
                                                    {itinerary?.itineraryDetail?.promotionValidityAndTaxSavingDisplayText && <div className="row margin-top">
                                                        {ReactHtmlParser(itinerary?.itineraryDetail?.promotionValidityAndTaxSavingDisplayText)}
                                                    </div>}
                                                    <div className="row margin-top">
                                                        <div data-testid="promotion-click" className="is-desktop itinerary-promotion-row" onClick={this.showPromotions}>
                                                            <div className="pull-left d-flex">

                                                                <div className="option-icon margin-top-negative-4 promotions-icon">
                                                                    <Icon name="promotion" className="icon-16" />
                                                                </div>
                                                                <div className="pull-left">
                                                                    <div className="pull-left margin-left font-bold">
                                                                        {ReactHtmlParser(itinerary?.itineraryDetail?.promotionLinkText)}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className={isPromotion ? "pop-over-bg show" : "hide"}>

                                                                <div className="close-btn-row">
                                                                    <Button className="pull-right" data-testid="close-btn" variant="default" onClick={this.resetPromotions}>
                                                                        <Icon name="close--n" className="icon font-grey" />
                                                                    </Button>
                                                                </div>

                                                                <div className="banner background-dark font-white">
                                                                    <div className="banner-img">

                                                                    </div>
                                                                    <h1 className="font-white">
                                                                        {Localize("qma-current-promotions")}
                                                                    </h1>

                                                                </div>

                                                                <Promotion promotions={quote?.data?.promotions} />
                                                            </div>
                                                        </div>

                                                        <div data-testid="promotion-click" className="is-mobile itinerary-promotion-row" onClick={() => commonUtilities.scrollToSection("promotions")}>
                                                            <div className="pull-left">
                                                                <div className="option-icon margin-top-negative-4 promotions-icon">
                                                                    <Icon name="promotion" className="icon-16" />
                                                                </div>
                                                                <div className="pull-left">
                                                                    <div className="pull-left margin-left font-bold">
                                                                        {ReactHtmlParser(itinerary?.itineraryDetail?.promotionLinkText)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            : <div>
                                                {itinerary?.itineraryDetail?.roomType ? (
                                                    <div className="row">
                                                        <div className="row margin-top-2x">
                                                            {ReactHtmlParser(itinerary?.itineraryDetail?.taxAndFees?.displayText)}
                                                        </div>
                                                        {itinerary?.itineraryDetail?.promotionValidityAndTaxSavingDisplayText && <div className="row margin-top">
                                                            {ReactHtmlParser(itinerary?.itineraryDetail?.promotionValidityAndTaxSavingDisplayText)}
                                                        </div>}

                                                        {itinerary?.itineraryDetail?.roomType?.ebbDueDateDisplayText && <div className="offer-row d-flex">

                                                            <div className="option-icon pull-left">
                                                                <Icon name="deals" className="icon-16" />
                                                            </div>

                                                            <div className="pull-left option-text">
                                                                <div className="pull-left margin-left font-bold">
                                                                    {itinerary?.itineraryDetail?.roomType?.ebbDueDateDisplayText}
                                                                </div>
                                                            </div>
                                                        </div>}


                                                        <div data-testid="promotion-click" className="is-desktop itinerary-promotion-row" onClick={this.showPromotions}>
                                                            <div className="pull-left d-flex">

                                                                <div className="option-icon margin-top-negative-4 promotions-icon">
                                                                    <Icon name="promotion" className="icon-16" />
                                                                </div>
                                                                <div className="pull-left">
                                                                    <div className="pull-left margin-left font-bold">
                                                                        {ReactHtmlParser(itinerary?.itineraryDetail?.promotionLinkText)}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className={isPromotion ? "pop-over-bg show" : "hide"}>

                                                                <div className="close-btn-row">
                                                                    <Button className="pull-right" data-testid="close-btn" variant="default" onClick={this.resetPromotions}>
                                                                        <Icon name="close--n" className="icon font-grey" />
                                                                    </Button>
                                                                </div>

                                                                <div className="banner background-dark font-white">
                                                                    <div className="banner-img">

                                                                    </div>
                                                                    <h1 className="font-white">
                                                                        {Localize("qma-current-promotions")}
                                                                    </h1>

                                                                </div>

                                                                <Promotion promotions={quote?.data?.promotions} />
                                                            </div>
                                                        </div>

                                                        <div data-testid="promotion-click" className="is-mobile itinerary-promotion-row" onClick={() => commonUtilities.scrollToSection("promotions")}>
                                                            <div className="pull-left">
                                                                <div className="option-icon margin-top-negative-4 promotions-icon">
                                                                    <Icon name="promotion" className="icon-16" />
                                                                </div>
                                                                <div className="pull-left">
                                                                    <div className="pull-left margin-left font-bold">
                                                                        {ReactHtmlParser(itinerary?.itineraryDetail?.promotionLinkText)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {itinerary?.status?.toLowerCase() === "pending" || itinerary?.status?.toLowerCase() === "unavailable" || itinerary?.hideRoomConcession ? 
                                                            null : 
                                                            <div className="row margin-top hide-add-ons concession-list">
                                                                {itinerary?.itineraryDetail?.roomType?.concessionDisplayText?.map((concession, index) => 
                                                                    <div>{ReactHtmlParser(concession)}</div>
                                                                )}
                                                            </div>
                                                        }
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                    }
                                </div>
                            </div> : null
                    ))}
                </div>
            );
        }
        else {
            return (
                <div className="row view-quote-product-type product-type-container">
                    {quoteOption?.deviationItinerary?.status?.toLowerCase() === "inactive" ? "" :
                        <div>
                            {((
                                <div className="row">
                                    <div
                                        id={"itinerary_" + quoteOption?.deviationItinerary?.itineraryNumber}
                                        data-testid="itinerary-click"
                                        className={
                                            quoteOption?.deviationItinerary.isExpanded !== false
                                                ? "view-quote-product-type-itinerary-row active-row margin-top"
                                                : "view-quote-product-type-itinerary-row margin-top"
                                        }
                                        onClick={(event) =>
                                            handleItinerary(event, quoteOption?.deviationItinerary, isItinerary)
                                        }
                                    >
                                        <div className="pull-left option-icon">
                                            <ProductTypeIcon productType="package" />
                                        </div>

                                        <div className="pull-right">
                                            <div className="pull-left">
                                                <Icon
                                                    name={
                                                        quoteOption?.deviationItinerary.isExpanded !== false
                                                            ? "arrow-up"
                                                            : "arrow-down"
                                                    }
                                                    className="icon-12"
                                                />
                                            </div>
                                        </div>
                                        <div className="itinerary-header">
                                            {Localize("qma-itinerary") + " "}
                                            {quoteOption?.deviationItinerary.itineraryNumber}
                                            {quoteOption?.deviationItinerary.itineraryValidDate !== "" && quoteOption?.deviationItinerary.itineraryValidDate !== null ?
                                                commonUtilities.calculateDuration(
                                                    TODAY,
                                                    quoteOption?.deviationItinerary.itineraryValidDate
                                                ) < 0 ? (
                                                    <span> | {Localize("qma-validity")} : {Localize("qma-expired")}</span>
                                                    ) : commonUtilities.calculateDuration(
                                                            TODAY,
                                                            quoteOption?.deviationItinerary.itineraryValidDate
                                                        ) < 4 &&
                                                            commonUtilities.calculateDuration(
                                                                TODAY,
                                                                quoteOption?.deviationItinerary.itineraryValidDate
                                                            ) >= 0 ? (
                                                                <div className="itinerary-validity">
                                                                    <span className="itinerary-validity-vertical-bar"> | </span>{Localize("qma-validity")}:<span class="swg-icon swg-icon-alert--o icon-12 font-icon margin-left margin-right"></span>
                                                                    <span class="font-warning">{Localize("qma-expires-on") + " "}{moment(quoteOption?.deviationItinerary.itineraryValidDate).format("DD MMM YYYY")}</span>
                                                                </div>                  
                                                            ) : (
                                                                <div className="itinerary-validity">
                                                                    <span className="itinerary-validity-vertical-bar"> | </span>{Localize("qma-validity")}:<span class="swg-icon swg-icon-calendar icon-12 font-icon margin-left margin-right"></span>
                                                                    {Localize("qma-expires-on") + " "}
                                                                    {moment(quoteOption?.deviationItinerary.itineraryValidDate).format("DD MMM YYYY")}
                                                                </div>
                                                            )
                                                        : null}

                                        </div>
                                        <div className="pull-right margin-right hide-add-ons itinerary-short-info">
                                            <div className="pull-left margin-right">
                                                {quoteOption?.deviationItinerary.heading}
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className={
                                            quoteOption?.deviationItinerary.isExpanded !== false
                                                ? "show view-quote-product-type-itinerary-details"
                                                : "print-itinerary hide"
                                        }
                                    >
                                        {(quoteOption?.productType?.code.trim().toLowerCase() === "deviation")
                                            ?
                                            <div className="row">
                                                {quoteOption?.deviationItinerary?.deviationRemarkDetails?.length > 0 ?
                                                    <div>
                                                        {quoteOption?.deviationItinerary?.deviationRemarkDetails[0]?.remarks === null ? "" :
                                                            <strong>
                                                                {Localize("qma-deviation-remarks")}
                                                            </strong>}
                                                        <div>
                                                            {quoteOption?.deviationItinerary?.deviationRemarkDetails?.map((remark) =>
                                                                <div>{remark?.remarks}</div>
                                                            )}

                                                        </div>
                                                    </div> : ""
                                                }
                                            </div> : ""
                                        }

                                        {
                                            (quoteOption?.deviationItinerary?.deviationFlightDetails[0]?.inbound !== null && quoteOption?.deviationItinerary?.deviationFlightDetails[0]?.outbound !== null)
                                                ? <div className="row margin-top-2x">
                                                    <div>
                                                        <div className="row margin-top-2x">
                                                            <div className="pull-left font-icon">
                                                                <ProductTypeIcon productType="flight" />
                                                            </div>
                                                            <strong>
                                                                {Localize("qma-flight")}
                                                            </strong>
                                                        </div>
                                                        {quoteOption?.deviationItinerary?.deviationFlightDetails?.map((flight) => {
                                                            return (<div className="row">
                                                                <div className="pull-left margin-right">
                                                                    {flight?.inbound}
                                                                </div>
                                                                <div className="pull-left margin-right rotate-90 margin-top-1-4">
                                                                    <ProductTypeIcon productType="flight" />
                                                                </div>
                                                                <div className="pull-left margin-right">
                                                                    {flight?.outbound}
                                                                </div>
                                                            </div>);
                                                        })}
                                                    </div>
                                                </div>
                                                : null
                                        }



                                        {

                                            <div className="row margin-top-2x">
                                                <div className="row">
                                                    <div className="row">
                                                        <div className="pull-left font-icon">
                                                            <ProductTypeIcon productType="hotel" />
                                                        </div>
                                                        <strong>{quoteOption?.deviationItinerary?.deviationRequest[0]?.products[0]?.name}</strong>
                                                    </div>
                                                    <div className="row">
                                                        {quoteOption?.deviationItinerary?.deviationRequest[0]?.products[0]?.city + ", " + quoteOption?.deviationItinerary?.deviationRequest[0]?.products[0]?.country}
                                                    </div>
                                                </div>

                                                <div className="row margin-top-1x">
                                                    <div className="hotel-pic">
                                                        {
                                                            <img src={quoteOption?.deviationItinerary?.deviationRequest[0]?.products[0]?.roomTypes[0]?.url} />
                                                        }
                                                    </div>

                                                    <div className="view-quote-product-type-itinerary-details-guest-col">
                                                        <div className="row">
                                                            <strong>{quoteOption?.deviationItinerary?.deviationRequest[0]?.products[0]?.roomTypes[0]?.name}</strong>

                                                        </div>

                                                        { quoteOption?.deviationItinerary?.status?.toLowerCase() !== "adjusted" ? 
                                                            ( quoteOption?.deviationItinerary?.deviationPendingStatusDisplayText ? 
                                                                <div className="warning-bar margin-top-2x">
                                                                    <div className="warning-icon">
                                                                        <Icon
                                                                            name="alert"
                                                                            className="icon-16 font-yellow"
                                                                        />
                                                                    </div>
                                                                    <div className="warning-text">
                                                                        <div className="row">
                                                                            <strong>{quoteOption?.deviationItinerary?.deviationPendingStatusDisplayText}</strong>
                                                                        </div>
                                                                    </div>
                                                                </div> : "")
                                                            :
                                                            <>

                                                            {(quoteOption?.deviationItinerary?.deviationPricingDetails?.length > 0 ?
                                                                <div className="row margin-top-2x">
                                                                    <div className="col-3 price-header">
                                                                        <strong>
                                                                            {Localize("qma-price-per-person")}
                                                                        </strong>
                                                                    </div>
                                                                    <div className="pull-left">
                                                                        <strong>
                                                                            {Localize("qma-price-description")}
                                                                        </strong>
                                                                    </div>
                                                                </div> : ""
                                                            )}

                                                            <div className="row margin-top price-details-container">
                                                                {((
                                                                    <div className="row">
                                                                        <div className="col-3 col-left-price-value">
                                                                            {quoteOption?.deviationItinerary?.deviationPricingDetails?.map((pricing) =>
                                                                                <div className="price-val">{"$" + pricing?.price}</div>
                                                                            )}


                                                                        </div>
                                                                        <div className="col-6 col-left-price-details">
                                                                            {quoteOption?.deviationItinerary?.deviationPricingDetails?.map((pricing) =>
                                                                                <div> {pricing?.priceDescription}</div>
                                                                            )}


                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                        }


                                    </div>


                                </div>
                            ))}</div>}
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => ({
    contentfulMasterData: state.contentfulMasterData
});

export default connect(mapStateToProps)(ProductTypeItinerary);
