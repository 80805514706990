import React, { useState } from "react";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import QuoteModal from "../quoteModal";
import { Localize } from "utilities/localize";
import commonUtilities from "utilities/common";

import "./addQuoteOption.css";

const AddQuoteOption = (props) => {

    const isIQMUser = commonUtilities.isIQMUser();
    const [isShow, setIsShow] = useState(false);
    const { data, isClone } = props;
    const { contentfulMasterData } = useSelector((state) => state);
    const contentfulData = contentfulMasterData?.data?.items;
    const addOption = commonUtilities?.getContentfulDataById(
        contentfulData,
        "quote-summary-add-option"
    );


    return (
        <div className="information-row grey-color-bg box-sizing" data-testid="addquoteoption">
            <QuoteModal data={data} isClone={isClone} isShow={isShow} handleModal={() => setIsShow(false)} />

            <div className="information-text" data-testid="info-text">
                {addOption}
            </div>
            <div className="pull-right information-button" >
                <Button className="pull-right" data-testid="info-btn" variant="contained" onClick={() => setIsShow(true)}>
                    {"+" + Localize("qma-add-option")}
                </Button>
            </div>
        </div>
    );
};

export default AddQuoteOption;
