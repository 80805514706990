import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import { connect } from "react-redux";
import { Section } from "@sunwing/components";
import quote from "services/quote/quote.service";
import View from "components/quote/view/index";
import commonUtilities from "utilities/common";
import moment from "moment";

class GroupDetails extends Component {

    componentDidMount() {
        const { quoteNumber } = this.props;
        if (quoteNumber) {
            this.props.dispatch(quote.get(quoteNumber)).then(response => console.log("letter", response));
        }
    }

    render() {
        let { quote, params, quoteNumber, contentfulMasterData } = this.props;
        const contentfulData = contentfulMasterData?.data?.items;

        const pageInformation = commonUtilities?.getContentfulDataById(
            contentfulData,
            process.env.REACT_APP_BRAND + "-qma-view-quote-page-information"
        );

        return (
            <div className="row margin-bottom-4x ">
                <div className="row">
                    <Section>
                        <div className="page-main-container margin-bottom-1x">
                            <div className="page-header-container">
                                <div className="section-header">
                                    <h1>
                                        Generated Quote Letter
                                    </h1>
                                </div>
                                <div className="row margin-bottom-1x">
                                    {ReactHtmlParser(pageInformation)}
                                </div>
                            </div>
                        </div>
                    </Section>
                    <View quote={quote} />

                </div>
            </div>






        );
    }

}

const mapStateToProps = (state) => ({
    quote: state.quote,
    contentfulMasterData: state.contentfulMasterData
});

export default connect(mapStateToProps)(GroupDetails);