import React, { Fragment } from "react";
import moment from "moment";

import { Localize } from "utilities/localize";
import { CONFIGURATION } from "utilities/configuration";
import ProductTypeIcon from "../common/productTypeIcon/productTypeIcon";

const ProductType = (props) => {

    const { productType } = props;

    const andLabel = Localize("qma-and");
    const childrenLabel = Localize(productType?.noOfChildren > 1 ? "qma-children" : "qma-child");
    const adultLabel = Localize(productType?.noOfAdults > 1 ? "qma-adults" : "qma-adult");

    return (
        <Fragment>
            {productType ? (
                <div className="row" data-testid="prod-comp">
                    <div className="row" >

                        {
                            productType?.departure?.code && `${Localize("qma-from")} (${productType?.departure?.code}) ${productType?.departure?.name} `
                        }
                        {
                            productType?.destination?.providerCode && `${Localize("qma-to")} (${productType.destination?.providerCode}) ${productType?.destination?.name}`
                        }

                    </div>

                    <div className="row">
                        {
                            Localize("qma-on") + " " + moment(productType.departureDate).format(CONFIGURATION.DATE_FORMAT) +
                            " - " + moment(productType.returnDate).format(CONFIGURATION.DATE_FORMAT) +
                            " " + Localize("qma-with") + " " + Number(productType.noOfAdults) + adultLabel + " "
                        }
                        {
                            Number(productType?.noOfChildren) > 0 && `${Localize("qma-and")}  ${Number(productType.noOfChildren)} ${Localize(productType?.noOfChildren > 1 ? "qma-children" : "qma-child")}`}

                    </div>

                    <div className="row flex-wrap">
                        {productType.products?.map((product, productIndex) => (
                            <div key={productIndex} className={product.name.trim().length ? "show col-3 margin-top-1x" : "hide"}>
                                <div className="row text-ellipsis">

                                    {
                                        product.name.trim().length > 0
                                            ? <div className="pull-left font-orange">
                                                <ProductTypeIcon productType="hotel" />
                                            </div>
                                            : ""
                                    }

                                    {product.name}
                                </div>

                                {product.roomTypes?.map((roomType, roomTypeIndex) => (
                                    <div key={roomTypeIndex} className="row text-ellipsis">{roomType.name}</div>
                                ))}
                            </div>
                        ))}
                    </div>

                </div>
            ) : (
                ""
            )}
        </Fragment>
    );
};
export default ProductType;