import React, { Component } from "react";
import { connect } from "react-redux";
import { Autocomplete, FormLabel, MenuItem, TextField } from "@mui/material";

import commonUtilities from "utilities/common";
import { Localize } from "utilities/localize";

class Departure extends Component {
  
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.departureCitiesMasterData !== this.props.departureCitiesMasterData) {
            let { departureCitiesMasterData } = this.props;
            let productType = commonUtilities.getProductType(this.props);

            if (productType) {
                let departureCities = departureCitiesMasterData?.data
                    ? departureCitiesMasterData?.data || []
                    : [];

                if (departureCities?.length === 1) {
                    if (!this.props?.data?.isClone) {
                        this.handleData(null, departureCities[0]);
                    }
                }
            }
        }
    }

    handleData = (event, city) => {
        let { handleStep2Data, onDepartureChange } = this.props;

        let productType = commonUtilities.getProductType(this.props);

        const { departure } = productType;

        departure.code = (city) ? city.code : "";
        departure.name = (city) ? city.name : "";
        departure.providerCode = (city) ? city.providerCode : "";

        handleStep2Data({ ...productType });
        onDepartureChange();
    };

    render() {
        let { departureCitiesMasterData, error } = this.props;
        let productType = commonUtilities.getProductType(this.props);

        if (!productType) return;
        const { departure, destination } = productType;
        let departureCities = departureCitiesMasterData?.data
            ? departureCitiesMasterData?.data || []
            : [];

        let selectPlaceHolder = Localize("qma-select-label");

        return (
            <div className="row departure-wrapper">
                <FormLabel>
                    {Localize("qma-departure-city")}
                </FormLabel>
                <Autocomplete
                    disabled={destination.code === "" || !(departureCities?.length > 0)}
                    autoHighlight
                    value={departure?.code ? departure : ""}
                    onChange={(event, city) => this.handleData(event, city)}
                    options={departureCities}
                    isOptionEqualToValue={(option) =>
                        option.name === departure.name
                    }
                    getOptionLabel={(city) =>
                        city ? `${city.name} (${city.providerCode})` : ""
                    }
                    renderOption={(props, city, index) => (
                        <MenuItem {...props} keys={index}>
                            {city.name} ({city.code})
                        </MenuItem>
                    )}
                    renderInput={(params) => (
                        <TextField
                            placeholder={selectPlaceHolder}
                            className={(error?.trim().length === 0) ? "formControl" : "errorControl formControl"}
                            {...params}
                        />
                    )}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    departureCitiesMasterData: state.departureCitiesMasterData
});

export default connect(mapStateToProps)(Departure);
