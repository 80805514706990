import React, { Component, Fragment } from "react";
import DatePicker from "react-datepicker";
import { Button, OutlinedInput, Switch } from "@mui/material";
import { connect } from "react-redux";
import moment from "moment";
import { Icon } from "@ICON_REFERENCE";

import { IconButton, InputAdornment } from "@mui/material";

import { Section } from "@sunwing/components";
import quote from "services/quote/quote.service";
import { withRouter } from "utilities/withRouter";
import PageHeader from "components/common/pageHeader/pageHeader";
import Moment from "moment";
import ProductTypeIcon from "components/common/productTypeIcon/productTypeIcon";

import { Localize } from "utilities/localize";
import commonUtilities from "utilities/common";
import { CONFIGURATION } from "utilities/configuration";
import updateQuoteItinerary from "services/quoteItinerary/quoteItinerary.service";

import "./viewQuoteItinerary.scss";
import MarkAsComplete from "components/markAsComplete";
import Popup from "./Popup";

class ViewQuoteItinerary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      isShowDelete: false,
      defaultData: {},
      titleIsValid: {},
      isPopupOpen: false,
      isAddAdultColumn: false,
      isAddChildColumn: false,
      childValue: "",
      firstNumber: "2",
      ageFrom: "2",
      ageTo: "2",
      textBoxIndex: null,
      hideRoomConcession: false,
      saveButton:false,
      showRemoveAdultButton:false,
      showRemoveChildButton:false,
    };
  }
  componentDidMount() {
    this.getQuoteItinerary();
  }
  getQuoteItinerary() {
    let { quoteNumber, itineraryID } = this.props.params;

    this.props
      .dispatch(quote.getQuoteItinerary({ quoteNumber, itineraryID }))
      .then((response) => {
        if (response?.payload?.data) {
          let { data, defaultData } = this.state;
          data = response.payload?.data;
          defaultData = structuredClone(response.payload?.data);
          this.setState({
            saveButton: false,
            data,
            defaultData,
            hideRoomConcession:
              data.hideRoomConcession === undefined ||
              data.hideRoomConcession === null
                ? false
                : data.hideRoomConcession
          });
        }
      });
  }

  addButton = () => {
    this.setState({
      data: {
        ...this.state?.data,
        commercialControls: [...this.state?.data?.commercialControls, {}]
      }
    });
  };

  handleItinerary = (event) => {
    let { data } = this.state;
    data["itineraryNotes"] = event?.target?.value;
    this.setState({
      data
    });
  };

  handleMarginOverride = (val, rowItem) => {
    let payload = this.state?.data;
    const regex = /^[\-]?[0-9\b]+$/;
    if (val === "" || regex.test(val)) {
      let { marginOverride } = this.state?.data;
      let value = val === "" ? null : parseInt(val, 10);
      marginOverride[rowItem] = value;
      payload.isMarginUpdated = true;
      this.setState({
        marginOverride
      });
    }
  };

  handleHotelDetails = (value, rowItem) => {
    let { hotelDetails } = this.state?.data;
    hotelDetails[rowItem] = value;
    this.setState({
      hotelDetails
    });
  };

  handleChangeFlight = (value, rowItem, columnItem) => {
    let { flightDetails } = this.state?.data;
    flightDetails[rowItem][columnItem] = value;
    this.setState({
      flightDetails
    });
  };

  handleChangeFlightTime = (val, rowItem, columnItem1, columnItem2) => {
    let { flightDetails } = this.state?.data;
    flightDetails[rowItem][columnItem1][columnItem2] = val;
    this.setState({
      flightDetails
    });
  };

  handleChangeConcession = (value, row, rowItem) => {
    let { concessionDetails } = this.state?.data;
    concessionDetails[row][rowItem] = value;
    this.setState({
      concessionDetails
    });
  };

  handleDueDate = (value, row, rowItem) => {
    let { data } = this.state;
    data["pricingDetails"][row][rowItem] = value;
    this.setState({
      data
    });
  };

  handleCommercialControl = (event, row, value) => {
    let { data } = this.state;
    data["commercialControls"][row][value] = event;
    this.setState({ data });
  };

  setPricingChange = (price, pricingRow, fieldIdex) => {
    const regex = /^[0-9]+\.?[0-9]?[0-9]?$/;
    if (price === "" || regex.test(price)) {
      let { pricingDetails } = this.state?.data;
      pricingDetails[pricingRow]["field"][fieldIdex]["value"] = price;
      this.setState({ pricingDetails });
    }
  };

  handleHideRoomConcession() {
    let { data } = this.state;
    data.hideRoomConcession = !this.state.hideRoomConcession;
    this.setState({
      hideRoomConcession: data.hideRoomConcession,
      data
    });
  }

  openAddAdultColumn = () => {
    let { pricingDetails } = this.state?.data;
    const firstField = pricingDetails[0]["field"];
    const adultTitle = [
      "Single",
      "Double",
      "Triple",
      "Quad",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12"
    ];
    const filterTitleAdult = adultTitle.filter(
      (item) => !firstField.some((field) => item === field.columnName)
    );

    var minId = 100 + firstField.length;
    filterTitleAdult.length > 0 &&
      pricingDetails?.map((pricingDetail) =>
        pricingDetail["field"].push({
          id: (-1) * minId,
          columnName: filterTitleAdult[0],
          value: "0",
          ebbDate: null
        })
      );

    let { data } = this.state;
    this.setState({
      data,
      showRemoveAdultButton:true
    });
  };
  removeAdultColumn = () => {
    let { pricingDetails } = this.state?.data;
    pricingDetails.forEach(detail => {
      let minIdIndex = -1;
      let minId = Number.MAX_SAFE_INTEGER;
  
      detail.field.forEach((item, index) => {
          if (item.id < minId && !item.columnName.includes("Child Age Grp")) {
              minId = item.id;
              minIdIndex = index;
          }
      });
  
      if (minIdIndex !== -1) {
          detail.field.splice(minIdIndex, 1);
      }
    });
    var negativeIdCount = 0;
    pricingDetails.forEach(detail => {
      detail.field.forEach(item => {
          if (item.id < 0 && !item.columnName.includes("Child Age Grp")) {
              negativeIdCount++;
          }
      });
    });
    let { data } = this.state;
    this.setState({
      data,
      showRemoveAdultButton: negativeIdCount > 0 ? true : false,
    });
  };
  openAddChildColumn = () => {
    let { pricingDetails } = this.state?.data;

    const firstField = pricingDetails[0]["field"];
    var minId = 200 + firstField.length;
    const ChildList = firstField.filter((item) =>
      item.columnName.includes("Child")
    );

    ChildList.length < 6 &&
      pricingDetails?.map((pricingDetail) =>
        pricingDetail["field"].push({
          id: (-1) * minId,
          columnName: "1 Child Age Grp 2-17",
          value: "0",
          ebbDate: null
        })
      );

      this.setState({ pricingDetails, showRemoveChildButton:true });
  };
  removeChildColumn = () => {
    let { pricingDetails } = this.state?.data;
    pricingDetails.forEach(detail => {
      let minIdIndex = -1;
      let minId = Number.MAX_SAFE_INTEGER;
  
      detail.field.forEach((item, index) => {
          if (item.id < minId && item.columnName.includes("Child Age Grp")) {
              minId = item.id;
              minIdIndex = index;
          }
      });
  
      if (minIdIndex !== -1) {
          detail.field.splice(minIdIndex, 1);
      }
    });
    var negativeIdCount = 0;
    pricingDetails.forEach(detail => {
      detail.field.forEach(item => {
          if (item.id < 0 && item.columnName.includes("Child Age Grp")) {
              negativeIdCount++;
          }
      });
    });
    let { data } = this.state;
    this.setState({
      data,
      showRemoveChildButton: negativeIdCount > 0 ? true : false,
    });
      this.setState({ pricingDetails });
  };

  openPopup = (e, index) => {
    this.setState({ firstNumber: e.target.value.charAt(0) });
    const index1 = e.target.value.indexOf("Grp");
    const subValue = e.target.value.substring(index1 + 4);
    const index2 = subValue.indexOf("-");
    this.setState({ ageFrom: subValue.substring(0, index2) });
    this.setState({ ageTo: subValue.substring(index2 + 1) });
    this.setState({ childValue: "" });
    this.setState({ isPopupOpen: true });
    this.setState({ textBoxIndex: index });
  };

  closePopup = (firstNumber, ageFrom, ageTo) => {
    let { pricingDetails } = this.state?.data;
    pricingDetails?.map(
      (pricingDetail) =>
        (pricingDetail["field"][this.state.textBoxIndex][
          "columnName"
        ] = `${firstNumber} Child Age Grp ${ageFrom}-${ageTo}`)
    );
    this.setState({ pricingDetails });
    this.setState({ isPopupOpen: false });
  };

  isUpdated = () => {
    let { data, defaultData } = this.state;

    if (data !== null && defaultData && defaultData !== null) {
      if (JSON.stringify(data) !== JSON.stringify(defaultData)) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  saveItinerary() {
    this.setState( {
      saveButton: true
    })
    let payload = this.state?.data;
    this.props.dispatch(updateQuoteItinerary(payload)).then((response) => {
      this.getQuoteItinerary();
    });
  }

  markAsCompleteItinerary() {
    let payload = this.state?.data;
    const { leadNumber, quoteNumber } = this.props.params;
    if (!payload.isManualQuote) {
      payload.itineraryStatus = "Adjusted";
    } else {
      payload.itineraryStatus = "Manual";
    }
    this.props.dispatch(updateQuoteItinerary(payload)).then((response) => {
      this.getQuoteItinerary();
      this.props.navigate("/lead/" + leadNumber + "/quote/" + quoteNumber);
    });
  }

  onClose = () => {
    const { leadNumber, quoteNumber } = this.props.params;
    this.props.navigate("/lead/" + leadNumber + "/quote/" + quoteNumber);
  };

  onCancel() {
    this.getQuoteItinerary();
  }

  setMarkAsComplete = (value) => {
    this.setState({
      isShowDelete: value
    });
  };

  handleExpiryDate = (event) => {
    let { data } = this.state;
    data["optionExpiryDate"] = event;
    this.setState({ data });
  };

  getSelectedDate = (date) => {
    if (date?.toLowerCase()?.includes("z")) {
      return new Date(date);
    } else {
      return new Date(date + "Z");
    }
  };

  render() {
    let { quoteItinerary } = this.props;
    let { pricingDetails } = this.state?.data || [];
    let pricingItems = pricingDetails || [];
    let pricingKeys = [];
    let pricingColumnHeader = [];
    let saveButton=this.state?.saveButton;
    let showRemoveChildButton = this.state?.showRemoveChildButton;
    let showRemoveAdultButton = this.state?.showRemoveAdultButton;

    if (pricingItems?.length > 0) {
      pricingKeys = Object.keys(pricingItems);
      let keys = pricingKeys;
      keys?.map((key, index) => {
        if (pricingItems[key] === null) {
          pricingKeys.splice(index, 1);
        }
      });
      if (pricingKeys?.length > 0) {
        let columnItem = pricingItems[pricingKeys[0]]?.field;
        if (columnItem?.length > 0) {
          columnItem?.map((eachItem) => {
            pricingColumnHeader?.push(eachItem?.columnName);
          });
        }
      }
    }

    if (quoteItinerary?.data) {
      let {
        quoteNumber,
        createdDate,
        createdBy,
        historyLog,
        itineraryNotes,
        productType,
        noOfAdults,
        noOfChildren,
        destination,
        departure,
        isManualQuote,
        hotelName,
        roomName,
        itineraryNumber,
        itineraryStatus,
        optionExpiryDate,
        concessionDetails,
        policyDetails,
        commercialControls,
        hotelDetails,
        flightDetails,
        pricingDetails,
        marginOverride,
        hocat
      } = this.state?.data;
      let {
        inventory,
        minOccupantPerRoom,
        maxOccupantPerRoom,
        minAdultPerRoom,
        maxAdultPerRoom,
        minChildPerRoom,
        maxChildPerRoom,
        hotelStatus
      } = hotelDetails || {};
      let { sourceToDestination, destinationToSource } = flightDetails || {};
      let { adultMargin, childMargin, marginOverrideStaticText } = marginOverride || {};
      const date = new Date();
      date.setHours(0,0,0,0);
      const TODAY = date.toDateString();
  
      let { isShowDelete } = this.state;
      let selectedDate = optionExpiryDate
        ? new Date(optionExpiryDate).getTime() +
          new Date(optionExpiryDate)?.getTimezoneOffset() * 60000
        : null;

      return (
        <Fragment>
          <MarkAsComplete
            isShow={isShowDelete}
            data={{ quoteItinerary }}
            handleMarkAsComplete={() => this.markAsCompleteItinerary()}
            handleCancel={() => this.setMarkAsComplete(false)}
          />
          <div className="row margin-bottom-4x view-group-itinerary-container">
            {this.props?.quoteItinerary ? (
              <div className="row">
                <Section className="print-hide">
                  <div className="page-main-container margin-bottom-1x">
                    <PageHeader />
                  </div>
                </Section>

                <Section className="print-hide">
                  <div className="row">
                    <Button
                      onClick={() => this.onClose()}
                      data-testid="close-button"
                      variant="text"
                      className="pull-right"
                    >
                      <Icon
                        name="close"
                        className="cursor-pointer icon-16 margin-right"
                      />
                      <strong>{Localize("qma-close")}</strong>
                    </Button>
                  </div>
                </Section>

                <Section>
                  <div className="table itinerary-information-container">
                    <div className="table-body">
                      <div className="table-row first-row">
                        <div className="table-column table-column-min font-bold">
                          Option
                        </div>
                        <div className="table-column table-column-min">
                          {productType?.code}
                        </div>
                        <div className="table-column table-column-min">
                          &nbsp;
                        </div>
                        <div className="table-column table-column-min font-bold">
                          Quote number
                        </div>
                        <div className="table-column table-column-min">
                          {quoteNumber}
                        </div>
                      </div>

                      <div className="table-row first-row">
                        <div className="table-column table-column-min font-bold">
                          Itinerary number
                        </div>
                        <div className="table-column table-column-min">
                          {itineraryNumber}
                        </div>
                        <div className="table-column table-column-min">
                          &nbsp;
                        </div>
                        <div className="table-column table-column-min font-bold">
                          Quote date
                        </div>
                        <div className="table-column table-column-min">
                          {moment(createdDate).format(
                            CONFIGURATION.DATE_FORMAT
                          )}
                        </div>
                      </div>

                      <div className="table-row first-row">
                        <div className="table-column table-column-min font-bold">
                          Expiry date
                        </div>

                        {isManualQuote || itineraryStatus === "Pending" ? (
                          <div className="table-column table-column-min">
                            <DatePicker
                              showIcon
                              selected={selectedDate}
                              onChange={(date) => this.handleExpiryDate(date)}
                              dateFormat="dd MMM yyyy"
                              className="date-picker-itinerary"
                              disabled={itineraryStatus !== "Pending"}
                            />
                            <Icon
                              name="calendar"
                              className="itinerary-cal-icon"
                            />
                          </div>
                        ) : (
                          <div className="table-column table-column-min">
                            {optionExpiryDate !== "" &&
                            optionExpiryDate !== null ? (                                
                            <div className="validity-row">
                            <Icon
                              name="calendar"
                              className="icon-12 font-orange pull-left margin-top-1-4 margin-right"
                            />
                            {Localize("qma-expires-on") + " "}
                            {Moment(optionExpiryDate).format(
                              CONFIGURATION.DATE_FORMAT
                            )}
                          </div>
                            ) : (
                              <div className="validity-row">&nbsp;</div>
                            )}
                          </div>
                        )}

                        <div className="table-column table-column-min">
                          &nbsp;
                        </div>
                        <div className="table-column table-column-min font-bold">
                          Quote by
                        </div>
                        <div className="table-column table-column-min">
                          {createdBy?.firstName === null
                            ? ""
                            : createdBy?.firstName + " " + createdBy?.lastName}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="table itinerary-information-container">
                    <div className="table-body">
                      <div className="table-row">
                        <div className="table-column table-column-min font-bold">
                          <div className="pull-left font-orange margin-right">
                            <Icon name="room-guests" className="font-14" />
                          </div>
                          Travellers
                        </div>
                        {departure?.code === "" ? (
                          ""
                        ) : (
                          <div className="table-column table-column-min font-bold">
                            <div className="font-orange">
                              <ProductTypeIcon productType="flight" />
                            </div>
                            Departure City
                          </div>
                        )}
                        <div className="table-column table-column-min font-bold">
                          <div className="pull-left font-orange margin-right">
                            <Icon name="map" />
                          </div>
                          Destination
                        </div>
                        {hotelName === null ? (
                          ""
                        ) : (
                          <div className="table-column table-column-min font-bold">
                            <div className="font-orange">
                              <ProductTypeIcon productType="hotel" />
                            </div>
                            Hotel / Cruise
                          </div>
                        )}
                        {roomName === null ? (
                          ""
                        ) : (
                          <div className="table-column table-column-min font-bold">
                            <div className="pull-left font-orange margin-right">
                              <Icon name="accommodations" />
                            </div>
                            Room / Cabin
                          </div>
                        )}
                      </div>

                      <div className="table-row">
                        <div className="table-column table-column-min">
                          {noOfAdults + " Adults"}
                          <br />
                          {noOfChildren + " Children"}
                        </div>
                        {departure?.code === "" ? (
                          ""
                        ) : (
                          <div className="table-column table-column-min">
                            {"(" + departure?.code + ") " + departure?.name}
                          </div>
                        )}
                        <div className="table-column table-column-min">
                          {"(" +
                            destination?.providerCode +
                            ") " +
                            destination?.name}
                        </div>

                        {hotelName === null ? (
                          ""
                        ) : (
                          <div className="table-column table-column-min">
                            {hotelName}
                          </div>
                        )}
                        {roomName === null ? (
                          ""
                        ) : (
                          <div className="table-column table-column-min">
                            {roomName}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Section>

                <Section>
                  {itineraryStatus === "Pending" || itineraryNotes !== null ? (
                    <div>
                      <div className="row margin-top-3x margin-bottom-1x font-bold">
                        <div className="pull-left">Itinerary notes:</div>
                      </div>

                      <div className="itinerary-notes">
                        <OutlinedInput
                          multiline={true}
                          fullWidth={true}
                          rows={3}
                          notched={true}
                          disabled={itineraryStatus !== "Pending"}
                          inputProps={{ maxLength: 2500 }}
                          name="itineraryNotes"
                          value={itineraryNotes}
                          onChange={(event) => {
                            this.handleItinerary(event);
                          }}
                        />
                      </div>
                      <div className="row">
                        <div className="pull-right margin-top font-14 font-light-grey">
                          {this.state?.data?.itineraryNotes?.length ===
                          undefined
                            ? 0
                            : this.state?.data?.itineraryNotes?.length}{" "}
                          / 2500
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </Section>

                <Section className={!isManualQuote ? "show" : "hide"}>
                  <div className="row margin-top-3x margin-bottom-1x font-bold">
                    <div className="pull-left font-orange margin-right-1x">
                      <Icon name="price--n" />
                    </div>
                    {itineraryStatus !== "Pending" ? (
                      <div className="pull-left">Margin</div>
                    ) : (
                      <div className="pull-left">Margin override</div>
                    )}
                  </div>

                  <div className="table margin-override">
                    <div className="table-body">
                      <div className="table-row">
                        <div className="table-column table-column-large border-right">
                          {marginOverrideStaticText}
                        </div>
                        <div className="table-column table-column-min border-right">
                          <div className="row">
                            <div className="table-row-h-text">Adult margin</div>
                            <div className="table-row-h-input text-align-right container-bg">
                              <OutlinedInput
                                fullWidth
                                name="adultMargin"
                                id="outlined-based-on"
                                disabled={itineraryStatus !== "Pending"}
                                startAdornment={
                                  <InputAdornment>
                                    <IconButton>
                                      <Icon name="price--n" />
                                    </IconButton>
                                  </InputAdornment>
                                }
                                value={adultMargin}
                                aria-describedby="outlined-based-on"
                                onChange={(event) => {
                                  this.handleMarginOverride(
                                    event?.target?.value,
                                    "adultMargin"
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="table-column table-column-min border-right">
                          <div className="row">
                            <div className="table-row-h-text">Child margin</div>
                            <div className="table-row-h-input text-align-right container-bg">
                              <OutlinedInput
                                fullWidth
                                name="childMargin"
                                id="outlined-based-on"
                                disabled={itineraryStatus !== "Pending"}
                                startAdornment={
                                  <InputAdornment>
                                    <IconButton>
                                      <Icon name="price--n" />
                                    </IconButton>
                                  </InputAdornment>
                                }
                                value={childMargin}
                                aria-describedby="outlined-based-on"
                                onChange={(event) => {
                                  this.handleMarginOverride(
                                    event?.target?.value,
                                    "childMargin"
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Section>

                {productType?.code?.toLowerCase() === "package" ||
                productType?.code?.toLowerCase() === "flight" ||
                productType?.code?.toLowerCase() === "cruise" ? (
                  <Section>
                    <div className="row margin-top-3x margin-bottom-1x font-bold">
                      <div className="pull-left font-orange">
                        <ProductTypeIcon productType="flight" />
                      </div>
                      <div className="pull-left">Flight Details</div>
                    </div>

                    <div className="table">
                      <div className="dark-bg table-head">
                        <div className="table-row">
                          <div className="table-column table-column-small border-right text-transparent">
                            <div className="pull-left margin-top">Outbound</div>
                          </div>
                          <div className="table-column table-column-small border-right">
                            Departure
                          </div>
                          <div className="table-column table-column-small border-right">
                            Destination
                          </div>
                          <div className="table-column table-column-small border-right">
                            Departure Date
                          </div>
                          <div className="table-column table-column-small border-right">
                            Flight #
                          </div>
                          <div className="table-column table-column-small border-right">
                            Via
                          </div>
                          <div className="table-column table-column-small border-right">
                            Departure Time
                          </div>
                          <div className="table-column table-column-small border-right">
                            Arrival Time
                          </div>
                          <div className="table-column table-column-small border-right">
                            Cost-Adult
                          </div>
                          <div className="table-column table-column-small border-right">
                            Cost-Child
                          </div>
                        </div>
                      </div>
                      <div className="table-body">
                        <div className="table-row">
                          <div className="table-column table-column-small border-right font-bold">
                            <div className="pull-left margin-top">Outbound</div>
                          </div>
                          <div className="table-column table-column-small border-right">
                            <div className="pull-left margin-top">
                              {sourceToDestination?.departure?.code}
                            </div>
                          </div>
                          <div className="table-column table-column-small border-right">
                            <div className="pull-left margin-top">
                              {sourceToDestination?.arrival?.code}
                            </div>
                          </div>
                          <div className="table-column table-column-small border-right">
                            <div className="pull-left margin-top">
                              {Moment(
                                sourceToDestination?.departure?.date
                              ).format(CONFIGURATION.DATE_FORMAT)}
                            </div>
                          </div>
                          <div className="table-column table-column-small border-right">
                            <OutlinedInput
                              fullWidth
                              id="outlined-number-of-free"
                              className="container-bg"
                              disabled={
                                !isManualQuote || itineraryStatus !== "Pending"
                              }
                              value={sourceToDestination?.flightNo}
                              inputProps={{ maxLength: 6 }}
                              aria-describedby="outlined-number-of-free"
                              onChange={(event) => {
                                const regex = /^[0-9a-zA-Z]+$/;
                                if (
                                  event?.target?.value === "" ||
                                  regex.test(event.target.value)
                                ) {
                                  this.handleChangeFlight(
                                    event?.target?.value.toUpperCase(),
                                    "sourceToDestination",
                                    "flightNo"
                                  );
                                }
                              }}
                            />
                          </div>
                          <div className="table-column table-column-small border-right">
                            <OutlinedInput
                              fullWidth
                              name="numberOfFree"
                              disabled={
                                !isManualQuote || itineraryStatus !== "Pending"
                              }
                              onChange={(event) => {
                                const regMatch = /^[a-zA-Z]*$/;
                                if (
                                  event?.target?.value === "" ||
                                  regMatch.test(event.target.value)
                                ) {
                                  this.handleChangeFlight(
                                    event?.target?.value,
                                    "sourceToDestination",
                                    "via"
                                  );
                                }
                              }}
                              id="outlined-number-of-free"
                              className="container-bg"
                              value={sourceToDestination?.via}
                              inputProps={{ maxLength: 4 }}
                              aria-describedby="outlined-number-of-free"
                            />
                          </div>
                          <div className="table-column table-column-small border-right">
                            <OutlinedInput
                              fullWidth
                              name="numberOfFree"
                              id="outlined-number-of-free"
                              className="container-bg"
                              disabled={
                                !isManualQuote || itineraryStatus !== "Pending"
                              }
                              onChange={(event) => {
                                this.handleChangeFlightTime(
                                  event?.target?.value,
                                  "sourceToDestination",
                                  "departure",
                                  "time"
                                );
                              }}
                              value={
                                !isManualQuote
                                  ? moment(
                                      sourceToDestination?.departure?.time,
                                      "hh:mm a"
                                    ).format("hh:mm a")
                                  : sourceToDestination?.departure?.time ===
                                    null
                                  ? null
                                  : sourceToDestination?.departure?.time
                              }
                              aria-describedby="outlined-number-of-free"
                            />
                          </div>
                          <div className="table-column table-column-small border-right">
                            <OutlinedInput
                              fullWidth
                              name="numberOfFree"
                              id="outlined-number-of-free"
                              className="container-bg"
                              disabled={
                                !isManualQuote || itineraryStatus !== "Pending"
                              }
                              onChange={(event) => {
                                this.handleChangeFlightTime(
                                  event?.target?.value,
                                  "sourceToDestination",
                                  "arrival",
                                  "time"
                                );
                              }}
                              value={
                                !isManualQuote
                                  ? moment(
                                      sourceToDestination?.arrival?.time,
                                      "hh:mm a"
                                    ).format("hh:mm a")
                                  : sourceToDestination?.arrival?.time === null
                                  ? null
                                  : sourceToDestination?.arrival?.time
                              }
                              aria-describedby="outlined-number-of-free"
                            />
                          </div>
                          <div className="table-column table-column-small border-right text-align-right">
                            <OutlinedInput
                              fullWidth
                              name="numberOfFree"
                              disabled={
                                !isManualQuote || itineraryStatus !== "Pending"
                              }
                              startAdornment={
                                <InputAdornment>
                                  <IconButton>
                                    <Icon name="price--n" />
                                  </IconButton>
                                </InputAdornment>
                              }
                              id="outlined-number-of-free"
                              onChange={(event) => {
                                const regex = /^[0-9\b]+$/;
                                if (
                                  event?.target?.value === "" ||
                                  regex.test(event.target.value)
                                ) {
                                  let value = event.target.value;
                                  this.handleChangeFlight(
                                    value,
                                    "sourceToDestination",
                                    "costAdult"
                                  );
                                }
                              }}
                              className="container-bg"
                              value={sourceToDestination?.costAdult}
                              aria-describedby="outlined-number-of-free"
                            />
                          </div>
                          <div className="table-column table-column-small border-right text-align-right">
                            <OutlinedInput
                              fullWidth
                              name="numberOfFree"
                              disabled={
                                !isManualQuote || itineraryStatus !== "Pending"
                              }
                              startAdornment={
                                <InputAdornment>
                                  <IconButton>
                                    <Icon name="price--n" />
                                  </IconButton>
                                </InputAdornment>
                              }
                              id="outlined-number-of-free"
                              onChange={(event) => {
                                const regex = /^[0-9\b]+$/;
                                if (
                                  event?.target?.value === "" ||
                                  regex.test(event.target.value)
                                ) {
                                  let value = event.target.value;
                                  this.handleChangeFlight(
                                    value,
                                    "sourceToDestination",
                                    "costChild"
                                  );
                                }
                              }}
                              value={sourceToDestination?.costChild}
                              className="container-bg"
                              aria-describedby="outlined-number-of-free"
                            />
                          </div>
                        </div>
                        <div className="table-row">
                          <div className="table-column table-column-small border-right font-bold">
                            <div className="pull-left margin-top">Inbound</div>
                          </div>
                          <div className="table-column table-column-small border-right">
                            <div className="pull-left margin-top">
                              {destinationToSource?.departure?.code}
                            </div>
                          </div>
                          <div className="table-column table-column-small border-right">
                            <div className="pull-left margin-top">
                              {destinationToSource?.arrival?.code}
                            </div>
                          </div>
                          <div className="table-column table-column-small border-right">
                            <div className="pull-left margin-top">
                              {Moment(
                                destinationToSource?.departure?.date
                              ).format(CONFIGURATION.DATE_FORMAT)}
                            </div>
                          </div>
                          <div className="table-column table-column-small border-right">
                            <OutlinedInput
                              fullWidth
                              name="numberOfFree"
                              id="outlined-number-of-free"
                              className="container-bg"
                              disabled={
                                !isManualQuote || itineraryStatus !== "Pending"
                              }
                              onChange={(event) => {
                                const regex = /^[0-9a-zA-Z]+$/;
                                if (
                                  event?.target?.value === "" ||
                                  regex.test(event.target.value)
                                ) {
                                  this.handleChangeFlight(
                                    event?.target?.value.toUpperCase(),
                                    "destinationToSource",
                                    "flightNo"
                                  );
                                }
                              }}
                              value={destinationToSource?.flightNo}
                              inputProps={{ maxLength: 6 }}
                              aria-describedby="outlined-number-of-free"
                            />
                          </div>
                          <div className="table-column table-column-small border-right">
                            <OutlinedInput
                              fullWidth
                              name="numberOfFree"
                              id="outlined-number-of-free"
                              disabled={
                                !isManualQuote || itineraryStatus !== "Pending"
                              }
                              onChange={(event) => {
                                const regMatch = /^[a-zA-Z]*$/;
                                if (
                                  event?.target?.value === "" ||
                                  regMatch.test(event.target.value)
                                ) {
                                  this.handleChangeFlight(
                                    event?.target?.value,
                                    "destinationToSource",
                                    "via"
                                  );
                                }
                              }}
                              value={destinationToSource?.via}
                              inputProps={{ maxLength: 4 }}
                              className="container-bg"
                              aria-describedby="outlined-number-of-free"
                            />
                          </div>
                          <div className="table-column table-column-small border-right">
                            <OutlinedInput
                              fullWidth
                              name="numberOfFree"
                              id="outlined-number-of-free"
                              disabled={
                                !isManualQuote || itineraryStatus !== "Pending"
                              }
                              onChange={(event) => {
                                this.handleChangeFlightTime(
                                  event?.target?.value,
                                  "destinationToSource",
                                  "departure",
                                  "time"
                                );
                              }}
                              className="container-bg"
                              value={
                                !isManualQuote
                                  ? moment(
                                      destinationToSource?.departure?.time,
                                      "hh:mm a"
                                    ).format("hh:mm a")
                                  : destinationToSource?.departure?.time ===
                                    null
                                  ? null
                                  : destinationToSource?.departure?.time
                              }
                              aria-describedby="outlined-number-of-free"
                            />
                          </div>
                          <div className="table-column table-column-small border-right">
                            <OutlinedInput
                              fullWidth
                              name="numberOfFree"
                              id="outlined-number-of-free"
                              disabled={
                                !isManualQuote || itineraryStatus !== "Pending"
                              }
                              onChange={(event) => {
                                this.handleChangeFlightTime(
                                  event?.target?.value,
                                  "destinationToSource",
                                  "arrival",
                                  "time"
                                );
                              }}
                              className="container-bg"
                              value={
                                !isManualQuote
                                  ? moment(
                                      destinationToSource?.arrival?.time,
                                      "hh:mm a"
                                    ).format("hh:mm a")
                                  : destinationToSource?.arrival?.time === null
                                  ? null
                                  : destinationToSource?.arrival?.time
                              }
                              aria-describedby="outlined-number-of-free"
                            />
                          </div>
                          <div className="table-column table-column-small border-right text-align-right">
                            <OutlinedInput
                              fullWidth
                              name="numberOfFree"
                              startAdornment={
                                <InputAdornment>
                                  <IconButton>
                                    <Icon name="price--n" />
                                  </IconButton>
                                </InputAdornment>
                              }
                              id="outlined-number-of-free"
                              disabled={
                                !isManualQuote || itineraryStatus !== "Pending"
                              }
                              onChange={(event) => {
                                const regex = /^[0-9\b]+$/;
                                if (
                                  event?.target?.value === "" ||
                                  regex.test(event.target.value)
                                ) {
                                  let value = event.target.value;
                                  this.handleChangeFlight(
                                    value,
                                    "destinationToSource",
                                    "costAdult"
                                  );
                                }
                              }}
                              className="container-bg"
                              value={destinationToSource?.costAdult}
                              aria-describedby="outlined-number-of-free"
                            />
                          </div>
                          <div className="table-column table-column-small border-right text-align-right">
                            <OutlinedInput
                              fullWidth
                              name="numberOfFree"
                              startAdornment={
                                <InputAdornment>
                                  <IconButton>
                                    <Icon name="price--n" />
                                  </IconButton>
                                </InputAdornment>
                              }
                              id="outlined-number-of-free"
                              disabled={
                                !isManualQuote || itineraryStatus !== "Pending"
                              }
                              onChange={(event) => {
                                const regex = /^[0-9\b]+$/;
                                if (
                                  event?.target?.value === "" ||
                                  regex.test(event.target.value)
                                ) {
                                  let value = event.target.value;
                                  this.handleChangeFlight(
                                    value,
                                    "destinationToSource",
                                    "costChild"
                                  );
                                }
                              }}
                              className="container-bg"
                              value={destinationToSource?.costChild}
                              aria-describedby="outlined-number-of-free"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Section>
                ) : (
                  ""
                )}

                {productType?.code?.trim().toLowerCase() === "package" ||
                productType?.code?.trim().toLowerCase() === "hotel" ||
                productType?.code?.trim().toLowerCase() === "cruise" ? (
                  <Section>
                    <div className="row margin-top-3x margin-bottom-1x font-bold">
                      <div className="pull-left font-orange">
                        <ProductTypeIcon productType="hotel" />
                      </div>
                      <div className="pull-left">Hotel Details</div>
                    </div>

                    <div className="table">
                      <div className="dark-bg table-head">
                        <div className="table-row">
                          <div className="table-column table-column-large border-right">
                            Hotel / <br />
                            Room
                          </div>
                          <div className="table-column table-column-min border-right">
                            Hotel <br />
                            Inventory
                          </div>
                          <div className="table-column table-column-min border-right">
                            Hotel <br />
                            Status
                          </div>
                          <div className="table-column table-column-min border-right">
                            Room <br />
                            Occupancy
                          </div>
                          <div className="table-column table-column-min border-right">
                            Adult <br />
                            Occupancy
                          </div>
                          <div className="table-column table-column-min">
                            Child <br />
                            Occupancy
                          </div>
                        </div>
                      </div>
                      <div className="table-body">
                        <div className="table-row">
                          <div className="table-column table-column-large border-right">
                            {hotelName + " " + hocat}
                            <br />
                            {roomName + " " + hocat}
                          </div>
                          <div className="table-column table-column-min border-right">
                            <OutlinedInput
                              fullWidth
                              name="inventory"
                              id="outlined-free-item"
                              inputProps={{ maxLength: 3 }}
                              className="container-bg"
                              value={inventory}
                              disabled={
                                !isManualQuote || itineraryStatus !== "Pending"
                              }
                              aria-describedby="outlined-free-item"
                              onChange={(event) => {
                                const regex = /^[0-9a-zA-Z]+$/;
                                if (
                                  event.target.value === "" ||
                                  regex.test(event.target.value)
                                ) {
                                  let value = event.target.value;
                                  this.handleHotelDetails(value, "inventory");
                                }
                              }}
                            />
                          </div>
                          <div className="table-column table-column-min border-right">
                            <OutlinedInput
                              fullWidth
                              name="hotelStatus"
                              id="outlined-book-and-pay-for"
                              inputProps={{ maxLength: 2 }}
                              className="container-bg"
                              value={hotelStatus}
                              disabled={
                                !isManualQuote || itineraryStatus !== "Pending"
                              }
                              aria-describedby="outlined-book-and-pay-for"
                              onChange={(event) => {
                                const regMatch = /^[a-zA-Z]*$/.test(
                                  event.target.value
                                );
                                if (regMatch) {
                                  this.handleHotelDetails(
                                    event.target.value,
                                    "hotelStatus"
                                  );
                                }
                              }}
                            />
                          </div>
                          <div className="table-column table-column-min border-right">
                            <div className="row">
                              <div className="table-row-h-text">Min.</div>
                              <div className="table-row-h-input">
                                <OutlinedInput
                                  fullWidth
                                  name="minOccupantPerRoom"
                                  id="outlined-based-on"
                                  inputProps={{ maxLength: 2 }}
                                  disabled={
                                    !isManualQuote ||
                                    itineraryStatus !== "Pending"
                                  }
                                  className="container-bg"
                                  value={minOccupantPerRoom}
                                  aria-describedby="outlined-based-on"
                                  onChange={(event) => {
                                    const regex = /^[0-9\b]+$/;
                                    if (
                                      event.target.value === "" ||
                                      regex.test(event.target.value)
                                    ) {
                                      let value = event.target.value;
                                      this.handleHotelDetails(
                                        value,
                                        "minOccupantPerRoom"
                                      );
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="row margin-top">
                              <div className="table-row-h-text">Max.</div>
                              <div className="table-row-h-input">
                                <OutlinedInput
                                  fullWidth
                                  name="maxOccupantPerRoom"
                                  id="outlined-based-on"
                                  disabled={
                                    !isManualQuote ||
                                    itineraryStatus !== "Pending"
                                  }
                                  inputProps={{ maxLength: 2 }}
                                  className="container-bg"
                                  value={maxOccupantPerRoom}
                                  aria-describedby="outlined-based-on"
                                  onChange={(event) => {
                                    const regex = /^[0-9\b]+$/;
                                    if (
                                      event.target.value === "" ||
                                      regex.test(event.target.value)
                                    ) {
                                      let value = event.target.value;
                                      this.handleHotelDetails(
                                        value,
                                        "maxOccupantPerRoom"
                                      );
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="table-column table-column-min border-right">
                            <div className="row">
                              <div className="table-row-h-text">Min.</div>
                              <div className="table-row-h-input">
                                <OutlinedInput
                                  fullWidth
                                  name="minAdultPerRoom"
                                  id="outlined-based-on"
                                  disabled={
                                    !isManualQuote ||
                                    itineraryStatus !== "Pending"
                                  }
                                  inputProps={{ maxLength: 2 }}
                                  className="container-bg"
                                  value={minAdultPerRoom}
                                  aria-describedby="outlined-based-on"
                                  onChange={(event) => {
                                    const regex = /^[0-9\b]+$/;
                                    if (
                                      event.target.value === "" ||
                                      regex.test(event.target.value)
                                    ) {
                                      let value = event.target.value;
                                      this.handleHotelDetails(
                                        value,
                                        "minAdultPerRoom"
                                      );
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="row margin-top">
                              <div className="table-row-h-text">Max.</div>
                              <div className="table-row-h-input">
                                <OutlinedInput
                                  fullWidth
                                  name="maxAdultPerRoom"
                                  id="outlined-based-on"
                                  disabled={
                                    !isManualQuote ||
                                    itineraryStatus !== "Pending"
                                  }
                                  inputProps={{ maxLength: 2 }}
                                  className="container-bg"
                                  value={maxAdultPerRoom}
                                  aria-describedby="outlined-based-on"
                                  onChange={(event) => {
                                    const regex = /^[0-9\b]+$/;
                                    if (
                                      event.target.value === "" ||
                                      regex.test(event.target.value)
                                    ) {
                                      let value = event.target.value;
                                      this.handleHotelDetails(
                                        value,
                                        "maxAdultPerRoom"
                                      );
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="table-column table-column-min">
                            <div className="row">
                              <div className="table-row-h-text">Min.</div>
                              <div className="table-row-h-input">
                                <OutlinedInput
                                  fullWidth
                                  name="minChildPerRoom"
                                  id="outlined-based-on"
                                  disabled={
                                    !isManualQuote ||
                                    itineraryStatus !== "Pending"
                                  }
                                  inputProps={{ maxLength: 2 }}
                                  className="container-bg"
                                  value={minChildPerRoom}
                                  aria-describedby="outlined-based-on"
                                  onChange={(event) => {
                                    const regex = /^[0-9\b]+$/;
                                    if (
                                      event.target.value === "" ||
                                      regex.test(event.target.value)
                                    ) {
                                      let value = event.target.value;
                                      this.handleHotelDetails(
                                        value,
                                        "minChildPerRoom"
                                      );
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="row margin-top">
                              <div className="table-row-h-text">Max.</div>
                              <div className="table-row-h-input">
                                <OutlinedInput
                                  fullWidth
                                  name="maxChildPerRoom"
                                  id="outlined-based-on"
                                  disabled={
                                    !isManualQuote ||
                                    itineraryStatus !== "Pending"
                                  }
                                  inputProps={{ maxLength: 2 }}
                                  className="container-bg"
                                  value={maxChildPerRoom}
                                  aria-describedby="outlined-based-on"
                                  onChange={(event) => {
                                    const regex = /^[0-9\b]+$/;
                                    if (
                                      event.target.value === "" ||
                                      regex.test(event.target.value)
                                    ) {
                                      let value = event.target.value;
                                      this.handleHotelDetails(
                                        value,
                                        "maxChildPerRoom"
                                      );
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Section>
                ) : null}

                <Section className="pricing-details-container">
                  <div className="row margin-top-3x margin-bottom-1x font-bold">
                    <div className="pull-left font-orange">
                      <ProductTypeIcon productType="flight" />
                    </div>
                    <div className="pull-left">Pricing Details</div>
                      {isManualQuote && productType?.code?.trim().toLowerCase() === "package" ||
                       isManualQuote && productType?.code?.trim().toLowerCase() === "hotel" ||
                       isManualQuote && productType?.code?.trim().toLowerCase() === "cruise" ? ( 
                      <div className="pull-left">                     
                        <Button onClick={() => this.openAddAdultColumn()}>
                          Add Adult
                        </Button>
                        {showRemoveAdultButton && <Button onClick={() => this.removeAdultColumn()}>Remove Adult</Button>}
                        <Button onClick={() => this.openAddChildColumn()}>
                          Add Child
                        </Button>
                        {showRemoveChildButton && <Button onClick={() => this.removeChildColumn()}>Remove Child</Button>}
                      </div>)
                      : null}
                  </div>

                  <div className="container-border">
                    <div className="freeze-column">
                      <div className="dark-bg table-head">
                        <div className="table-row">
                          <div className="table-column table-pricing-col-text border-right text-transparent">
                            &nbsp;
                          </div>
                        </div>
                      </div>
                      <div className="table-body">
                        {pricingDetails?.map((pricing, index) => {
                          return (
                            <div className="table-row">
                              <div className="table-column table-pricing-col-text border-right">
                                <div className="pull-left margin-top">
                                  {pricing?.rowName}
                                </div>
                                {pricing?.rowName === "Ebb Value" &&
                                isManualQuote ? (
                                  <div className="table-column table-column-min">
                                    <DatePicker
                                      showIcon
                                      selected={
                                        pricing?.ebbDate === null
                                          ? ""
                                          : this.getSelectedDate(
                                              pricing?.ebbDate
                                            )
                                      }
                                      onChange={(date) => {
                                        this.handleDueDate(
                                          new Date(date)?.toISOString(),
                                          index,
                                          "ebbDate"
                                        );
                                      }}
                                      dateFormat="dd MMM yyyy"
                                      className="date-picker-itinerary"
                                      disabled={
                                        !isManualQuote ||
                                        itineraryStatus !== "Pending"
                                      }
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    {" "}
                                    {pricing?.rowName === "Ebb Value" &&
                                    (pricing?.ebbDate !== null ||
                                      isManualQuote) ? (
                                      <div className="table-column table-column-min">
                                        <OutlinedInput
                                          fullWidth
                                          className="container-bg"
                                          name="ebbDate"
                                          id="outlined-book-and-pay-for"
                                          disabled={
                                            !isManualQuote ||
                                            itineraryStatus !== "Pending"
                                          }
                                          onChange={(event) => {
                                            this.handleDueDate(
                                              event?.target?.value,
                                              index,
                                              "ebbDate"
                                            );
                                          }}
                                          value={
                                            itineraryStatus === "Pending"
                                              ? pricing?.ebbDate === null
                                                ? null
                                                : "Due " +
                                                  Moment(
                                                    pricing?.ebbDate
                                                  ).format(
                                                    CONFIGURATION.DUE_DATE_FORMAT
                                                  )
                                              : "Due " +
                                                Moment(pricing?.ebbDate).format(
                                                  CONFIGURATION.DUE_DATE_FORMAT
                                                )
                                          }
                                          aria-describedby="outlined-book-and-pay-for"
                                        />
                                      </div>
                                    ) : null}
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="scroll-container">
                      <div className="scroll-columns">
                        <div className="dark-bg table-head">
                          <div className="table-row">
                            {pricingColumnHeader?.map((columnHeader, index) => (
                              <div className="table-column table-pricing-col-input border-right text-ellipsis">
                                {isManualQuote && columnHeader.includes("-") ? (
                                  <OutlinedInput
                                    onClick={(e) => this.openPopup(e, index)}
                                    fullWidth
                                    name="basedOn"
                                    id="outlined-based-on"
                                    className="container-bg"
                                    value={columnHeader}
                                    aria-describedby="outlined-based-on"
                                  />
                                ) : (
                                  <div>{columnHeader}</div>
                                )}
                              </div>
                            ))}
                          </div>
                          {this.state.isPopupOpen && (
                            <div className="titlePopup">
                              <Popup
                                passedChild={this.state.firstNumber}
                                PassedAgeFrom={this.state.ageFrom}
                                PassedAgeTo={this.state.ageTo}
                                closePopup={this.closePopup}
                              ></Popup>
                            </div>
                          )}
                        </div>
                        <div className="table-body">
                          {pricingKeys?.map((eachPricingRow, rowIndex) => (
                            <div className="table-row">
                              {pricingItems[eachPricingRow]?.field?.map(
                                (eachPricingColumn, index) => (
                                  <div className="table-column table-pricing-col-input text-align-right border-right">
                                    <OutlinedInput
                                      onChange={(e) =>
                                        this.setPricingChange(
                                          e.target.value,
                                          rowIndex,
                                          index
                                        )
                                      }
                                      fullWidth
                                      name="basedOn"
                                      id="outlined-based-on"
                                      className="container-bg"
                                      disabled={
                                        isManualQuote &&
                                        itineraryStatus === "Pending"
                                          ? false
                                          : itineraryStatus === "Pending"
                                          ? !pricingItems[eachPricingRow]
                                              ?.isEditable
                                          : true
                                      }
                                      startAdornment={
                                        <InputAdornment>
                                          <IconButton>
                                            <Icon name="price--n" />
                                          </IconButton>
                                        </InputAdornment>
                                      }
                                      value={eachPricingColumn?.value}
                                      aria-describedby="outlined-based-on"
                                    />
                                  </div>
                                )
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </Section>
                { (productType?.code?.trim().toLowerCase() === "package" || productType?.code?.trim().toLowerCase() === "cruise") &&
                  policyDetails?.length >= 1 ? (
                    <Section>
                      <div className="row margin-top-3x margin-bottom-1x font-bold">
                        <div className="pull-left font-orange margin-right-1x">
                          <Icon name="price--n" />
                        </div>
                        <div className="pull-left concession-detail-heading">
                          Policy Details
                        </div>
                      </div>
                      <div className="table">
                        <div className="dark-bg table-head">
                          <div className="table-row">
                            <div className="table-column view-quote-itinerary-concession-col-min-plus border-right">
                              Contract Number
                            </div>
                            <div className="table-column view-quote-itinerary-concession-col-min-plus border-right">
                              Record ID
                            </div>
                            <div className="table-column view-quote-itinerary-concession-col-min-plus border-right">
                              Status
                            </div>
                            <div className="table-column view-quote-itinerary-concession-col-min-plus border-right">
                              Quote Via
                            </div>
                            <div className="table-column view-quote-itinerary-concession-col-min-plus border-right">
                              Days Valid
                            </div>
                            <div className="table-column view-quote-itinerary-concession-col-min-plus">
                              Max Rooms
                            </div>
                          </div>
                        </div>
                        <div className="table-body">
                          {policyDetails?.map(
                            (policyDetail, policyDetailIndex) => {
                              return (
                                <div className="table-row">
                                  <div className="table-column view-quote-itinerary-concession-col-min-plus border-right">
                                    {policyDetail.contractNumber??"-"}
                                  </div>
                                  <div className="table-column view-quote-itinerary-concession-col-min-plus border-right">
                                    {policyDetail.recordId??"-"}
                                  </div>
                                  <div className="table-column view-quote-itinerary-concession-col-min-plus border-right">
                                    {policyDetail.contractStatus??"-"}
                                  </div>
                                  <div className="table-column view-quote-itinerary-concession-col-min-plus border-right">
                                    {policyDetail.quoteVia??"-"}
                                  </div>
                                  <div className="table-column view-quote-itinerary-concession-col-min-plus border-right">
                                    {policyDetail.daysValid && policyDetail.daysValid !== "0" ? policyDetail.daysValid : "-"}
                                  </div>
                                  <div className="table-column view-quote-itinerary-concession-col-min-plus">
                                    {policyDetail.maxRooms && policyDetail.maxRooms !== "0" ? policyDetail.maxRooms : "-"}
                                  </div>
                                </div>
                              );
                            })
                          }
                        </div>
                      </div>
                    </Section>
                 ) : null 
                }
                {productType?.code?.trim().toLowerCase() === "package" &&
                concessionDetails?.length >= 1 ? (
                  <Section>
                    <div className="row margin-top-3x margin-bottom-1x font-bold">
                      <div className="pull-left font-orange margin-right-1x">
                        <Icon name="price--n" />
                      </div>
                      <div className="pull-left concession-detail-heading">
                        Concession Details{" "}
                        {itineraryStatus.toLowerCase() === "pending" ? (
                          <Switch
                            className="margin-right"
                            checked={this.state.hideRoomConcession === false}
                            onChange={() => this.handleHideRoomConcession()}
                            name="hideRoomConcession"
                          />
                        ) : null}{" "}
                      </div>
                    </div>
                    <div className="container-border scroll-container">
                      <div className="scroll-columns">
                        <div className="dark-bg table-head">
                            <div className="table-column view-quote-itinerary-concession-col-text-min border-right">
                              <div className="pull-left"># of Free</div>
                            </div>
                            <div className="table-column view-quote-itinerary-concession-col-text-min border-right">
                              Free Item
                            </div>
                            <div className="table-column view-quote-itinerary-concession-col-text-mid border-right">
                              Book & Pay For
                            </div>
                            <div className="table-column view-quote-itinerary-concession-col-text-min border-right">
                              Based On
                            </div>
                            <div className="table-column view-quote-itinerary-concession-col-text-min border-right">
                              Occupancy
                            </div>
                            <div className="table-column view-quote-itinerary-concession-col-text-min border-right">
                              Max Free
                            </div>
                            <div className="table-column view-quote-itinerary-concession-col-text-min border-right">
                              Days out
                            </div>
                            <div className="table-column view-quote-itinerary-concession-col-text-mid border-right">
                              Min Group Size
                            </div>
                            <div className="table-column view-quote-itinerary-concession-col-text-mid border-right">
                              Max Group Size
                            </div>
                            <div className="table-column view-quote-itinerary-concession-col-text-plus border-right">
                              Min/Max Based on
                            </div>
                            <div className="table-column view-quote-itinerary-concession-col-text-mid border-right">
                              Min Night Stay
                            </div>
                            <div className="table-column view-quote-itinerary-concession-col-text-plus">
                              Combine With Ebb
                            </div>
                        </div>
                        <div className="table-body">
                          {concessionDetails?.map(
                            (concessionDetail, concessionDetailIndex) => {
                              return (
                                <div className="table-row">
                                  <div className="table-column view-quote-itinerary-concession-col-text-min border-right">
                                    <OutlinedInput
                                      fullWidth
                                      name="contractEarnQty"
                                      className="container-bg"
                                      id="outlined-number-of-free"
                                      inputProps={{ maxLength: 1 }}
                                      disabled={itineraryStatus !== "Pending"}
                                      onChange={(event) => {
                                        const regex = /^[0-9\b]+$/;
                                        if (
                                          event.target.value === "" ||
                                          regex.test(event.target.value)
                                        ) {
                                          this.handleChangeConcession(
                                            event?.target?.value,
                                            concessionDetailIndex,
                                            "contractEarnQty"
                                          );
                                        }
                                      }}
                                      value={concessionDetail?.contractEarnQty}
                                      aria-describedby="outlined-number-of-free"
                                    />
                                  </div>
                                  <div className="table-column view-quote-itinerary-concession-col-text-min border-right">
                                    <OutlinedInput
                                      fullWidth
                                      name="contractRoPa"
                                      className="container-bg"
                                      id="outlined-free-item"
                                      inputProps={{ maxLength: 5 }}
                                      disabled={itineraryStatus !== "Pending"}
                                      value={concessionDetail?.contractRoPa}
                                      onChange={(event) => {
                                        this.handleChangeConcession(
                                          event?.target?.value,
                                          concessionDetailIndex,
                                          "contractRoPa"
                                        );
                                      }}
                                      aria-describedby="outlined-free-item"
                                    />
                                  </div>
                                  <div className="table-column view-quote-itinerary-concession-col-text-mid border-right">
                                    <OutlinedInput
                                      fullWidth
                                      name="contractBook"
                                      id="outlined-book-and-pay-for"
                                      className="container-bg"
                                      inputProps={{ maxLength: 3 }}
                                      disabled={itineraryStatus !== "Pending"}
                                      value={concessionDetail?.contractBook}
                                      onChange={(event) => {
                                        const regex = /^[0-9\b]+$/;
                                        if (
                                          event.target.value === "" ||
                                          regex.test(event.target.value)
                                        ) {
                                          this.handleChangeConcession(
                                            event?.target?.value,
                                            concessionDetailIndex,
                                            "contractBook"
                                          );
                                        }
                                      }}
                                      aria-describedby="outlined-book-and-pay-for"
                                    />
                                  </div>
                                  <div className="table-column view-quote-itinerary-concession-col-text-min border-right">
                                    <OutlinedInput
                                      fullWidth
                                      name="contractEarnRoPa"
                                      id="outlined-based-on"
                                      className="container-bg"
                                      inputProps={{ maxLength: 5 }}
                                      disabled={itineraryStatus !== "Pending"}
                                      value={concessionDetail?.contractEarnRoPa}
                                      onChange={(event) => {
                                        this.handleChangeConcession(
                                          event?.target?.value,
                                          concessionDetailIndex,
                                          "contractEarnRoPa"
                                        );
                                      }}
                                      aria-describedby="outlined-based-on"
                                    />
                                  </div>
                                  <div className="table-column view-quote-itinerary-concession-col-text-min border-right">
                                    <OutlinedInput
                                      fullWidth
                                      name="contractOccupancy"
                                      id="outlined-occupancy"
                                      className="container-bg"
                                      inputProps={{ maxLength: 6 }}
                                      disabled={itineraryStatus !== "Pending"}
                                      value={concessionDetail?.contractOccupancy}
                                      onChange={(event) => {
                                        this.handleChangeConcession(
                                          event?.target?.value,
                                          concessionDetailIndex,
                                          "contractOccupancy"
                                        );
                                      }}
                                      aria-describedby="outlined-occupancy"
                                    />
                                  </div>
                                  <div className="table-column view-quote-itinerary-concession-col-text-min border-right">
                                    <OutlinedInput
                                      fullWidth
                                      name="contractMaxFree"
                                      id="outlined-max-free"
                                      className="container-bg"
                                      inputProps={{ maxLength: 2 }}
                                      disabled={itineraryStatus !== "Pending"}
                                      value={concessionDetail?.contractMaxFree}
                                      onChange={(event) => {
                                        this.handleChangeConcession(
                                          event?.target?.value,
                                          concessionDetailIndex,
                                          "contractMaxFree"
                                        );
                                      }}
                                      aria-describedby="outlined-max-free"
                                    />
                                  </div>
                                  <div className="table-column view-quote-itinerary-concession-col-text-min border-right">
                                    <OutlinedInput
                                      fullWidth
                                      name="daysOut"
                                      id="outlined-book-and-pay-for"
                                      inputProps={{ maxLength: 2 }}
                                      disabled={itineraryStatus !== "Pending"}
                                      className="container-bg"
                                      value={concessionDetail?.daysOut}
                                      onChange={(event) => {
                                        const regex = /^[0-9\b]+$/;
                                        if (
                                          event.target.value === "" ||
                                          regex.test(event.target.value)
                                          ) {
                                        this.handleChangeConcession(
                                          event?.target?.value,
                                          concessionDetailIndex,
                                          "daysOut"
                                        );
                                      }
                                      }}
                                      aria-describedby="outlined-book-and-pay-for"
                                    />
                                  </div>
                                  <div className="table-column view-quote-itinerary-concession-col-text-mid border-right">
                                    <OutlinedInput
                                      fullWidth
                                      name="minGroupSize"
                                      className="container-bg"
                                      id="outlined-number-of-free"
                                      inputProps={{ maxLength: 2 }}
                                      disabled={itineraryStatus !== "Pending"}
                                      value={concessionDetail?.minGroupSize}
                                      onChange={(event) => {
                                        const regex = /^[0-9\b]+$/;
                                        if (
                                          event.target.value === "" ||
                                          regex.test(event.target.value)
                                          ) {
                                        this.handleChangeConcession(
                                          event?.target?.value,
                                          concessionDetailIndex,
                                          "minGroupSize"
                                        );
                                      }
                                      }}
                                      aria-describedby="outlined-number-of-free"
                                    />
                                  </div>
                                  <div className="table-column view-quote-itinerary-concession-col-text-mid border-right">
                                    <OutlinedInput
                                      fullWidth
                                      name="maxGroupSize"
                                      className="container-bg"
                                      id="outlined-free-item"
                                      inputProps={{ maxLength: 3 }}
                                      disabled={itineraryStatus !== "Pending"}
                                      value={concessionDetail?.maxGroupSize}
                                      onChange={(event) => {
                                        const regex = /^[0-9\b]+$/;
                                        if (
                                          event.target.value === "" ||
                                          regex.test(event.target.value)
                                          ) {
                                        this.handleChangeConcession(
                                          event?.target?.value,
                                          concessionDetailIndex,
                                          "maxGroupSize"
                                        );
                                      }
                                      }}
                                      aria-describedby="outlined-free-item"
                                    />
                                  </div>
                                  <div className="table-column view-quote-itinerary-concession-col-text-plus border-right">
                                    <OutlinedInput
                                      fullWidth
                                      name="groupSize"
                                      className="container-bg"
                                      id="outlined-free-item"
                                      inputProps={{ maxLength: 5 }}
                                      disabled={itineraryStatus !== "Pending"}
                                      value={concessionDetail?.groupSize}
                                      onChange={(event) => {
                                        this.handleChangeConcession(
                                          event?.target?.value,
                                          concessionDetailIndex,
                                          "groupSize"
                                        );
                                      }}
                                      aria-describedby="outlined-free-item"
                                    />
                                  </div>
                                  <div className="table-column view-quote-itinerary-concession-col-text-mid border-right">
                                    <OutlinedInput
                                      fullWidth
                                      name="minNights"
                                      id="outlined-book-and-pay-for"
                                      inputProps={{ maxLength: 2 }}
                                      disabled={itineraryStatus !== "Pending"}
                                      className="container-bg"
                                      value={concessionDetail?.minNights}
                                      onChange={(event) => {
                                        const regex = /^[0-9\b]+$/;
                                        if (
                                          event.target.value === "" ||
                                          regex.test(event.target.value)
                                          ) {
                                        this.handleChangeConcession(
                                          event?.target?.value,
                                          concessionDetailIndex,
                                          "minNights"
                                        );
                                      }
                                      }}
                                      aria-describedby="outlined-book-and-pay-for"
                                    />
                                  </div>
                                  <div className="table-column view-quote-itinerary-concession-col-text-plus">
                                    <OutlinedInput
                                      fullWidth
                                      name="ebbInd"
                                      id="outlined-based-on"
                                      disabled={itineraryStatus !== "Pending"}
                                      className="container-bg"
                                      inputProps={{ maxLength: 3 }}
                                      value={concessionDetail?.ebbInd}
                                      onChange={(event) => {
                                        const regex = /^[YyNn][EeSsOo]*$/g;
                                        if (
                                          event?.target?.value === "" ||
                                          regex.test(event.target.value)
                                        ) {
                                          this.handleChangeConcession(
                                            event?.target?.value,
                                            concessionDetailIndex,
                                            "ebbInd"
                                          );
                                        }
                                      }}
                                      aria-describedby="outlined-based-on"
                                    />
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  </Section>
                ) : null}

                {productType?.code?.trim().toLowerCase() === "cruise" &&
                concessionDetails?.length >= 1 ? (
                  <Section>
                    <div className="row margin-top-3x margin-bottom-1x font-bold">
                      <div className="pull-left font-orange margin-right-1x">
                        <Icon name="price--n" />
                      </div>
                      <div className="pull-left concession-detail-heading">
                        Concession Details{" "}
                        {itineraryStatus.toLowerCase() === "pending" ? (
                          <Switch
                            className="margin-right"
                            checked={this.state.hideRoomConcession === false}
                            onChange={() => this.handleHideRoomConcession()}
                            name="hideRoomConcession"
                          />
                        ) : null}{" "}
                      </div>
                    </div>
                    <div className="container-border scroll-container">
                        <div className="scroll-columns">
                          <div className="dark-bg table-head">
                            <div className="table-row">
                              <div className="table-column view-quote-itinerary-concession-col-text-min border-right">
                                <div className="pull-left"># of Free</div>
                              </div>
                              <div className="table-column view-quote-itinerary-concession-col-text-min border-right">
                                Free Item
                              </div>
                              <div className="table-column view-quote-itinerary-concession-col-text-mid border-right">
                                Book & Pay For
                              </div>
                              <div className="table-column view-quote-itinerary-concession-col-text-min border-right">
                                Based On
                              </div>
                              <div className="table-column view-quote-itinerary-concession-col-text-min border-right">
                                Occupancy
                              </div>
                              <div className="table-column view-quote-itinerary-concession-col-text-min border-right">
                                Max Free
                              </div>
                              <div className="table-column view-quote-itinerary-concession-col-text-min border-right">
                                Days out
                              </div>
                              <div className="table-column view-quote-itinerary-concession-col-text-mid border-right">
                                Min Group Size
                              </div>
                              <div className="table-column view-quote-itinerary-concession-col-text-mid border-right">
                                Max Group Size
                              </div>
                              <div className="table-column view-quote-itinerary-concession-col-text-plus border-right">
                                Min/Max Based On
                              </div>
                              <div className="table-column view-quote-itinerary-concession-col-text-mid border-right">
                                Min Night Stay
                              </div>
                              <div className="table-column view-quote-itinerary-concession-col-text-plus">
                                Combine With Ebb
                              </div>
                            </div>
                          </div>
                          <div className="table-body">
                            {concessionDetails?.map(
                              (concessionDetail, concessionDetailIndex) => {
                                return (
                                  <div className="table-row">
                                    <div className="table-column view-quote-itinerary-concession-col-text-min border-right">
                                      <OutlinedInput
                                        fullWidth
                                        name="marketingEarnQty"
                                        className="container-bg"
                                        id="outlined-number-of-free"
                                        inputProps={{ maxLength: 1 }}
                                        disabled={itineraryStatus !== "Pending"}
                                        value={concessionDetail?.marketingEarnQty}
                                        onChange={(event) => {
                                          const regex = /^[0-9\b]+$/;
                                          if (
                                            event.target.value === "" ||
                                            regex.test(event.target.value)
                                          ) {
                                            this.handleChangeConcession(
                                              event?.target?.value,
                                              concessionDetailIndex,
                                              "marketingEarnQty"
                                            );
                                          }
                                        }}
                                        aria-describedby="outlined-number-of-free"
                                      />
                                    </div>
                                    <div className="table-column view-quote-itinerary-concession-col-text-min border-right">
                                      <OutlinedInput
                                        fullWidth
                                        name="marketingRoPa"
                                        className="container-bg"
                                        id="outlined-free-item"
                                        inputProps={{ maxLength: 5 }}
                                        disabled={itineraryStatus !== "Pending"}
                                        value={concessionDetail?.marketingRoPa}
                                        onChange={(event) => {
                                          this.handleChangeConcession(
                                            event?.target?.value,
                                            concessionDetailIndex,
                                            "marketingRoPa"
                                          );
                                        }}
                                        aria-describedby="outlined-free-item"
                                      />
                                    </div>
                                    <div className="table-column view-quote-itinerary-concession-col-text-mid border-right">
                                      <OutlinedInput
                                        fullWidth
                                        name="marketingBook"
                                        id="outlined-book-and-pay-for"
                                        inputProps={{ maxLength: 3 }}
                                        disabled={itineraryStatus !== "Pending"}
                                        className="container-bg"
                                        value={concessionDetail?.marketingBook}
                                        onChange={(event) => {
                                          this.handleChangeConcession(
                                            event?.target?.value,
                                            concessionDetailIndex,
                                            "marketingBook"
                                          );
                                        }}
                                        aria-describedby="outlined-book-and-pay-for"
                                      />
                                    </div>
                                    <div className="table-column view-quote-itinerary-concession-col-text-min border-right">
                                      <OutlinedInput
                                        fullWidth
                                        name="marketingEarnRoPa"
                                        id="outlined-based-on"
                                        inputProps={{ maxLength: 5 }}
                                        disabled={itineraryStatus !== "Pending"}
                                        className="container-bg"
                                        value={concessionDetail?.marketingEarnRoPa}
                                        onChange={(event) => {
                                          this.handleChangeConcession(
                                            event?.target?.value,
                                            concessionDetailIndex,
                                            "marketingEarnRoPa"
                                          );
                                        }}
                                        aria-describedby="outlined-based-on"
                                      />
                                    </div>
                                    <div className="table-column view-quote-itinerary-concession-col-text-min border-right">
                                      <OutlinedInput
                                        fullWidth
                                        name="marketingOccupancy"
                                        id="outlined-occupancy"
                                        inputProps={{ maxLength: 6 }}
                                        disabled={itineraryStatus !== "Pending"}
                                        className="container-bg"
                                        value={concessionDetail?.marketingOccupancy}
                                        onChange={(event) => {
                                          this.handleChangeConcession(
                                            event?.target?.value,
                                            concessionDetailIndex,
                                            "marketingOccupancy"
                                          );
                                        }}
                                        aria-describedby="outlined-occupancy"
                                      />
                                    </div>
                                    <div className="table-column view-quote-itinerary-concession-col-text-min border-right">
                                      <OutlinedInput
                                        fullWidth
                                        name="marketingMaxFree"
                                        id="outlined-max-free"
                                        inputProps={{ maxLength: 1 }}
                                        disabled={itineraryStatus !== "Pending"}
                                        className="container-bg"
                                        value={concessionDetail?.marketingMaxFree}
                                        onChange={(event) => {
                                          this.handleChangeConcession(
                                            event?.target?.value,
                                            concessionDetailIndex,
                                            "marketingMaxFree"
                                          );
                                        }}
                                        aria-describedby="outlined-max-free"
                                      />
                                    </div>
                                    <div className="table-column view-quote-itinerary-concession-col-text-min border-right">
                                      <OutlinedInput
                                        fullWidth
                                        name="daysOut"
                                        className="container-bg"
                                        id="outlined-number-of-free"
                                        inputProps={{ maxLength: 2 }}
                                        disabled={itineraryStatus !== "Pending"}
                                        value={concessionDetail?.daysOut}
                                        onChange={(event) => {
                                          this.handleChangeConcession(
                                            event?.target?.value,
                                            concessionDetailIndex,
                                            "daysOut"
                                          );
                                        }}
                                        aria-describedby="outlined-number-of-free"
                                      />
                                    </div>
                                    <div className="table-column view-quote-itinerary-concession-col-text-mid border-right">
                                      <OutlinedInput
                                          fullWidth
                                          name="minGroupSize"
                                          className="container-bg"
                                          id="outlined-number-of-free"
                                          inputProps={{ maxLength: 2 }}
                                          disabled={itineraryStatus !== "Pending"}
                                          value={concessionDetail?.minGroupSize}
                                          onChange={(event) => {
                                            this.handleChangeConcession(
                                              event?.target?.value,
                                              concessionDetailIndex,
                                              "minGroupSize"
                                            );
                                          }}
                                          aria-describedby="outlined-number-of-free"
                                        />
                                    </div>
                                    <div className="table-column view-quote-itinerary-concession-col-text-mid border-right">
                                      <OutlinedInput
                                          fullWidth
                                          name="maxGroupSize"
                                          className="container-bg"
                                          id="outlined-free-item"
                                          inputProps={{ maxLength: 2 }}
                                          disabled={itineraryStatus !== "Pending"}
                                          value={concessionDetail?.maxGroupSize}
                                          onChange={(event) => {
                                            this.handleChangeConcession(
                                              event?.target?.value,
                                              concessionDetailIndex,
                                              "maxGroupSize"
                                            );
                                          }}
                                          aria-describedby="outlined-free-item"
                                        />
                                    </div>
                                    <div className="table-column view-quote-itinerary-concession-col-text-plus border-right">
                                      <OutlinedInput
                                          fullWidth
                                          name="marketingEarnRoPa"
                                          className="container-bg"
                                          id="outlined-free-item"
                                          inputProps={{ maxLength: 3 }}
                                          disabled={itineraryStatus !== "Pending"}
                                          value={concessionDetail?.marketingEarnRoPa}
                                          onChange={(event) => {
                                            this.handleChangeConcession(
                                              event?.target?.value,
                                              concessionDetailIndex,
                                              "marketingEarnRoPa"
                                            );
                                          }}
                                          aria-describedby="outlined-free-item"
                                        />
                                    </div>
                                    <div className="table-column view-quote-itinerary-concession-col-text-mid border-right">
                                      <OutlinedInput
                                        fullWidth
                                        name="minNights"
                                        id="outlined-book-and-pay-for"
                                        inputProps={{ maxLength: 5 }}
                                        disabled={itineraryStatus !== "Pending"}
                                        className="container-bg"
                                        value={concessionDetail?.minNights}
                                        onChange={(event) => {
                                          this.handleChangeConcession(
                                            event?.target?.value,
                                            concessionDetailIndex,
                                            "minNights"
                                          );
                                        }}
                                        aria-describedby="outlined-book-and-pay-for"
                                      />
                                    </div>
                                    <div className="table-column view-quote-itinerary-concession-col-text-plus">
                                      <OutlinedInput
                                        fullWidth
                                        name="ebbInd"
                                        id="outlined-based-on"
                                        inputProps={{ maxLength: 3 }}
                                        disabled={itineraryStatus !== "Pending"}
                                        className="container-bg"
                                        value={concessionDetail?.ebbInd}
                                        onChange={(event) => {
                                          const regex = /^[YyNn][EeSsOo]*$/g;
                                          if (
                                            event?.target?.value === "" ||
                                            regex.test(event.target.value)
                                          ) {
                                            this.handleChangeConcession(
                                              event?.target?.value,
                                              concessionDetailIndex,
                                              "ebbInd"
                                            );
                                          }
                                        }}
                                        aria-describedby="outlined-based-on"
                                      />
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                    </div>
                  </Section>
                ) : null}

                {commercialControls?.length < 1 ? null : (
                  <Section>
                    <div className="row margin-top-3x margin-bottom-1x font-bold">
                      <div className="pull-left font-orange">
                        <ProductTypeIcon productType="flight" />
                      </div>
                      <div className="pull-left">Commercial Controls</div>
                    </div>

                    <div className="table">
                      <div className="table-head">
                        <div className="table-row font-bold">
                          <div className="table-column view-quote-itinerary-updated-by">
                            Record number
                          </div>
                          <div className="table-column view-quote-itinerary-description">
                            Description
                          </div>
                        </div>
                      </div>
                      <div className="table-body">
                        {commercialControls?.map(
                          (commercialControl, commercialControlIndex) => {
                            return (
                              <div
                                key={commercialControlIndex}
                                className="table-row"
                              >
                                <div className="table-column view-quote-itinerary-updated-by">
                                  <OutlinedInput
                                    fullWidth
                                    name="recordNumber"
                                    id={commercialControlIndex}
                                    className="container-bg"
                                    inputProps={{ maxLength: 4 }}
                                    disabled={
                                      !isManualQuote ||
                                      itineraryStatus !== "Pending"
                                    }
                                    value={commercialControl?.recordNumber}
                                    aria-describedby="outlined-recordNumber-number"
                                    onChange={(event) => {
                                      const regex = /^[0-9a-zA-Z]+$/;
                                      if (
                                        event?.target?.value === "" ||
                                        regex.test(event?.target?.value)
                                      ) {
                                        this.handleCommercialControl(
                                          event?.target?.value,
                                          commercialControlIndex,
                                          "recordNumber"
                                        );
                                      }
                                    }}
                                  />
                                </div>
                                <div className="table-column view-quote-itinerary-description">
                                  <OutlinedInput
                                    fullWidth
                                    name="description"
                                    id={commercialControlIndex}
                                    className="container-bg"
                                    inputProps={{ maxLength: 70 }}
                                    disabled={
                                      !isManualQuote ||
                                      itineraryStatus !== "Pending"
                                    }
                                    value={commercialControl?.description}
                                    aria-describedby="outlined-description"
                                    onChange={(event) => {
                                      this.handleCommercialControl(
                                        event?.target?.value,
                                        commercialControlIndex,
                                        "description"
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                    <div className="deviation-action-col margin-top-1x">
                      {isManualQuote && itineraryStatus !== "Manual" ? (
                        <Button
                          onClick={() => this.addButton()}
                          className="add-button"
                          variant="outlined"
                        >
                          <Icon name="plus--n" />
                        </Button>
                      ) : (
                        ""
                      )}
                    </div>
                  </Section>
                )}

                <Section>
                  {itineraryStatus === "Pending" ? (
                    <div className="row margin-top-3x margin-bottom-1x">
                      <div className="pull-right">
                        <Button
                          className="btn-green"
                          variant="contained"
                          disabled={false}
                          onClick={() => this.setMarkAsComplete(true)}
                        >
                          Mark as complete
                        </Button>
                      </div>

                      <div className="pull-right margin-right-1x">
                        <Button
                          variant="contained"
                          disabled={!this.isUpdated() || saveButton}
                          onClick={() => this.saveItinerary()}
                        >
                          Save
                        </Button>
                      </div>

                      {this.isUpdated() ? (
                        <div className="pull-right margin-right-1x">
                          <Button
                            variant="outlined"
                            onClick={() => this.onCancel()}
                          >
                            Cancel
                          </Button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </Section>

                {this.state?.data?.historyLog?.length > 0 ? (
                  <Section>
                    <div className="row margin-top-2x margin-bottom-1x">
                      <h3 className="font-bold">History Log</h3>
                    </div>

                    <div className="table">
                      <div className="table-head">
                        <div className="table-row font-bold">
                          <div className="table-column view-quote-itinerary-updated-by">
                            Updated by
                          </div>
                          <div className="table-column view-quote-itinerary-updated-on">
                            Updated on
                          </div>
                          <div className="table-column view-quote-itinerary-log">
                            Log
                          </div>
                        </div>
                      </div>
                      <div className="table-body">
                        {historyLog?.map((history, historyIndex) => (
                          <div key={historyIndex} className="table-row">
                            <div className="table-column view-quote-itinerary-updated-by">
                              {history.updatedBy}
                            </div>
                            <div className="table-column view-quote-itinerary-updated-on">
                              {moment(history.updatedOn).format(
                                CONFIGURATION.DATE_FORMAT_TIME
                              )}
                            </div>
                            <div className="table-column view-quote-itinerary-log">
                              {history?.log}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Section>
                ) : (
                  ""
                )}
              </div>
            ) : null}
          </div>
        </Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  quoteItinerary: state.quoteItinerary
});

export default connect(mapStateToProps)(withRouter(ViewQuoteItinerary));
