import React, { Component } from "react";
import Button from "@mui/material/Button";
import ReactHtmlParser from "react-html-parser";
import { Icon } from "@ICON_REFERENCE";
import { withRouter } from "utilities/withRouter";
import Promotion from "components/common/promotions/promotions";

import "./promotions.css";
class Promotions extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isPromotion: false
        };
    }

    showOrHidePromotions = (flag) => {

        window.scrollTo(0, 0);
        document.body.style.overflow = !flag ? "visible" : "hidden";

        let { isPromotion } = this.state;
        isPromotion = flag;
        this.setState({ isPromotion });
    };

    render() {

        let { isPromotion } = this.state;
        const { promotions, isEdit, handleClose } = this.props;

        return (
            <div className="row promotion-wrapper">

                <div className="banner background-dark font-white">

                    <h1 className="font-white margin-bottom">
                        {this.props.localize("qma-banner")}
                    </h1>
                    <div className="font-24">
                        {this.props.localize("qma-banner-promotion")}
                    </div>

                    <div className="show-add-ons margin-top-1x">
                        <Button data-testid="show-all-btn" variant="outlined" onClick={() => this.showOrHidePromotions(true)}>
                            {this.props.localize("qma-show-all-promotions")}
                        </Button>
                    </div>

                    <div className={isPromotion ? "overlay show" : "hide"}>

                        <div className="close-btn-row">
                            <Button className="pull-right" data-testid="close-btn" variant="default" onClick={() => this.showOrHidePromotions(false)}>
                                <Icon name="close--n" className="icon font-grey" />
                            </Button>
                        </div>

                        <div className="banner background-dark font-white">
                            <div className="banner-img">

                            </div>
                            <h1 className="font-white">
                                {this.props.localize("qma-current-promotions")}
                            </h1>

                        </div>

                        <Promotion promotions={promotions} />
                    </div>

                </div>

                <div className="promotions-container outer-block" data-testid="promotion-list">

                    <Promotion promotions={promotions} isEdit={isEdit} handleClose={handleClose}/>

                </div>

            </div>
        );

    }

}

export default withRouter(Promotions);