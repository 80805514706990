import React from "react";
import Button from "@mui/material/Button";

import { withRouter } from "utilities/withRouter";
import ProductType from "../productType/productType";
import DeviationType from "../productType/deviationType";
import "./index.scss";

function DeleteModal(props) {
    const { isShow, productType, handleDelete, optionType } = props;
    let deleteWrapper = document.getElementById("delete-modal");
    if(isShow && deleteWrapper){
        deleteWrapper?.scrollTo(0,0);
        deleteWrapper.style.overflow = "hidden";
    } else {
        if(deleteWrapper){
            deleteWrapper?.scrollTo(0,0);
            deleteWrapper.style.overflow = "auto";
        }
      
    }

    return (
        <div>
            {isShow ? (
                <div className={"show"}>

                    <div className="popup-container-bg delete-modal"></div>

                    <div className="popup-container">

                        <div className="popup-body">

                            <div className="popup-heading margin-bottom-1x">
                                {props.localize("qma-delete-option")}
                            </div>
                            {optionType === "PRODUCT" ? (
                                <ProductType productType={productType}></ProductType>
                            ) : (
                                <DeviationType productType={productType}></DeviationType>
                            )}

                            <div className="row margin-top-3x yes-no-option-btn">
                                <Button
                                    data-testid={"deletemodal-yes-button"}
                                    onClick={() => handleDelete(true)}
                                    className="margin-right-1x yes-btn"
                                    variant="contained"
                                >
                                    {props.localize("qma-yes")}
                                </Button>
                                <Button data-testid={"deletemodal-no-button"} className="no-btn" onClick={() => handleDelete(false)} variant="outlined">
                                    {props.localize("qma-no")}
                                </Button>
                            </div>

                        </div>

                    </div>

                </div>
            ) : null}
        </div>
    );
}

export default withRouter(DeleteModal);