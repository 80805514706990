import React from "react";
import { useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";

import { Localize } from "utilities/localize";
import commonUtilities from "utilities/common";

const PageHeader = () => {
    const { contentfulMasterData } = useSelector((state) => state);
    const contentfulData = contentfulMasterData?.data?.items;
    const user = commonUtilities.getUserType();
    const isIQMUser = commonUtilities.isIQMUser();

    const headerKey = isIQMUser ? "qma-group-quote-header" : (user === "CUSTOMER") ? "qma-my-group-information-header" : "qma-instant-group-quote-header" ;
    const pageInformation = commonUtilities?.getContentfulDataById(
        contentfulData,
        "qma-my-group-vacation-page-information"
    );

    return (
        <div className="page-header-container">
            <div className="section-header">
                <h1>
                    {Localize(headerKey)}
                </h1>
            </div>
            <div className="row margin-bottom-1x">
                {ReactHtmlParser(pageInformation)}
            </div>
        </div>
    );
};
export default PageHeader;
