import React from "react";
import Button from "@mui/material/Button";

import { Localize } from "utilities/localize";
import { withRouter } from "utilities/withRouter";
import "./index.scss";

function InvoiceError(props) {
    const { isShow, handleClose, apiErrorMessage, data } = props;
   

    return (
        <div className={isShow ? "show" : "hide"}>

            <div className="popup-container-bg delete-modal"></div>

            <div className="popup-container">

                <div className="popup-body">

                    <div className="popup-heading margin-bottom-1x">
                        Group Invoice Error
                    </div>

                    <div className="popup margin-bottom-1x">
                        {apiErrorMessage}
                    </div>

                    <div className="row margin-top-3x yes-no-option-btn">
                        <Button data-testid={"deletemodal-no-button"} className="no-btn" onClick={() => handleClose()} variant="outlined">
                            {props.localize("qma-close")}
                        </Button>
                    </div>

                </div>

            </div>

        </div>
    );
}

export default withRouter(InvoiceError);