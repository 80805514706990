import React, { Component } from "react";
import { connect } from "react-redux";
import { Autocomplete, Button, Checkbox, FormLabel, MenuItem, TextField } from "@mui/material";

import getDeviationDestinations from "services/destination/destinationDeviation.service";
import getDeviationDeparture from "services/destination/departureDeviation.service";
import commonUtilities from "utilities/common";
import { Localize } from "utilities/localize";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Style } from "./departureCityMultiSelect.scss";

class MultiSelectDeparture extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuOpened: false,
            departures: [],
            selectedValues: []
        };
    }

    componentDidMount() {
        this.getDepartures();
        const { deviationTypes, data, index } = this.props;
        const { deviationOptionIndex } = data || {};
        if (deviationTypes[deviationOptionIndex]?.departures?.length > 0) {
            const initialSelectedValues = [];
            deviationTypes[deviationOptionIndex]?.departures?.map((e, index) => {
                initialSelectedValues.push(e);
            });
            this.setState({
                selectedValues: initialSelectedValues
            });
            
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.departureCities?.data === null) {
            return ({
                menuOpened: false,
                departures: [],
                selectedValues: []
            });
        }

        if (nextProps.departureCities?.data !== prevState.departures) {
            if (prevState.departures && (nextProps.departureCities?.data)) {
                if (nextProps.departureCities?.data?.length > 0){
                  
                    prevState.departures?.map( country => {
                        country?.cities?.map(city => {
                            delete city.isChecked;
                        });
                    });
                    nextProps.departureCities?.data?.map( country => {
                        country?.cities?.map(city => {
                            delete city.isChecked;
                        });
                    });
                      
                    if((prevState.departures?.length > 0) && 
                    (JSON.stringify(prevState.departures) !== JSON.stringify(nextProps.departureCities?.data))){
                        prevState.selectedValues = [];
                    }
                   
                    nextProps.departureCities?.data?.map(country => {
                        country?.cities?.map(city => {
                            let matched = [];
                            if(prevState?.selectedValues?.length > 0){
                                matched = prevState?.selectedValues?.filter(e => e.code === city.code);
                            }
                            if(matched?.length !== 0){
                                city["isChecked"] = true;
                            } else {
                                city["isChecked"] = false;
                            }
                            
                            
                        });
                    });
                    
                }
                return ({ departures: nextProps.departureCities?.data,
                    selectedValues: prevState?.selectedValues });
            }
            return ({ departures: nextProps.departureCities?.data });
        }
        return null;
    }


    getDepartures() {
        let { departureCities } = this.props;
        let { departures } = this.state;
        if (departureCities?.data && departureCities?.data?.length > 0) {
            departures = departureCities?.data;
            this.setState({ departures });
        }
    }
    saveDestinations = () => {
        const { deviationTypes, handleStep2DeviationData, data } = this.props;
        const { deviationOptionIndex } = data || {};

        const { selectedValues } = this.state;

        let selectedCities = structuredClone(selectedValues);
        let deviationTypesClone = structuredClone(deviationTypes);

        deviationTypesClone[deviationOptionIndex].departures = selectedCities;

        handleStep2DeviationData(deviationTypesClone);
        this.setState({ menuOpened: false });
    };
    clearSelections = () => {
        let { departures, selectedValues } = this.state;
        departures.map(country => {
            country?.cities?.map(city => {
                city["isChecked"] = false;
            });
        });
        selectedValues = [];
        this.setState({ departures, selectedValues, menuOpened: false });

    };

    // handleData = (event, city) => {
    //     let { handleStep2Data, onDestinationChange } = this.props;

    //     let productType = commonUtilities.getProductType(this.props);
    //     console.log("producttype", productType);

    //     const { destination } = productType;

    //     destination.code = city ? city.code : "";
    //     destination.name = city ? city.name : "";
    //     destination.providerCode = city ? city.providerCode : "";

    //     handleStep2Data({ ...productType });
    //     onDestinationChange();
    // };
    updateSelectedValues(value, city, countryIndex, cityIndex) {
        let { selectedValues, departures } = this.state;
        if (value) {
            selectedValues.push({
                code: city?.code,
                name: city?.name,
                providerCode: city?.providerCode
            });
            departures[countryIndex].cities[cityIndex].isChecked = true;
        } else {
            let foundIndex = selectedValues?.findIndex(e => e.code === city.code);
            selectedValues?.splice(foundIndex, 1);
            departures[countryIndex].cities[cityIndex].isChecked = false;
        }
        this.setState({ selectedValues });
    }

    getRenderText(cities) {
        let text = [];
        if (cities?.length > 1) {
            cities.map(city => {
                text.push(`${"[" + city?.code + "] " + city?.name}`);
            });
            return text.join(", ");
        } else {
            return `${"[" + cities[0]?.code + "] " + cities[0]?.name}`;
        }
    }

    render() {
        let { citiesMasterData, error } = this.props;
        let { menuOpened, selectedValues, departures } = this.state;
        let productType = commonUtilities.getProductType(this.props);
        if (!productType) return;

        let { destination } = productType;
        let cities = citiesMasterData?.data?.cities
            ? citiesMasterData?.data?.cities || []
            : [];
        let selectPlaceHolder = Localize("qma-select-label");

        return (
            <div >
                <div data-testid="label-destination">
                    Departure City
                </div>

                <input type="text"
                    className={this.state.menuOpened ? "departure-city-input menu-open" : "departure-city-input"}
                    onClick={() => this.setState({ menuOpened: true })}
                    value={selectedValues?.length > 0 ? this.getRenderText(selectedValues) : selectPlaceHolder}
                    readOnly="true"
                    disabled={departures?.length === 0}
                />
                <span className={this.state.menuOpened ? "select-caret invert" : "select-caret"}><ArrowDropDownIcon /></span>
                {menuOpened ?
                    <div className="departure-city-wrapper ">
                        <div className="departure-city-popup">
                            <div className="select-info">Select up to 3 departure cities</div>
                            <div className="list-container">
                                <div className="countries-container">
                                    <ul>
                                        {departures?.map((country, countryIndex) => (
                                            <li className="countries" keys={countryIndex}>
                                                <div className="country-label">{country?.countryName}</div>
                                                <div className="cities-container">
                                                    {country?.cities?.map((city, cityIndex) => (
                                                        <div className="cities">
                                                            <Checkbox
                                                                id={`${"chekbox-" + countryIndex + "-" + cityIndex}`}
                                                                disabled={selectedValues?.length > 2 && !city.isChecked}
                                                                checked={city.isChecked}
                                                                onChange={(e) => this.updateSelectedValues(e?.target?.checked, city, countryIndex, cityIndex)} />
                                                            <div className="cities-label">{city?.name}</div>
                                                        </div>
                                                    ))}

                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                        </div>
                        <div className="bottom-section">
                            <Button variant="outlined" size="small" className="ft-right" onClick={() => this.saveDestinations()}> Done</Button>
                            <Button variant="text" size="small" className="ft-right" onClick={() => this.clearSelections()}> Clear all</Button>
                        </div>

                    </div> : null

                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    departureCities: state.departures
});

export default connect(mapStateToProps)(MultiSelectDeparture);