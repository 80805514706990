import React, { useState } from "react";
import "./Popup.css";

const Popup = ({ passedChild, PassedAgeFrom, PassedAgeTo, closePopup }) => {
  const [isPopupOpen, setPopupOpen] = useState(true);
  const [firstNumber, setFirstNumber] = useState(passedChild);
  const [ageFrom, setAgeFrom] = useState(PassedAgeFrom);
  const [ageTo, setAgeTo] = useState(PassedAgeTo);
  const [error, setError] = useState(false);
  const handleNumberChange = (e) => {
    setFirstNumber(e.target.value);
  };
  const handleAgeFromChange = (e) => {
    setAgeFrom(e.target.value);
  };
  const handleAgeToChange = (e) => {
    setAgeTo(e.target.value);
  };

  const verifyValue = (e) => {
    if (parseInt(ageTo) >= parseInt(ageFrom)) {
      setError(false);
      setPopupOpen(false);
      closePopup(firstNumber, ageFrom, ageTo);
    } else {
      setError(true);
    }
  };
  const CancelUpdate = (e) => {
    setPopupOpen(false);
    closePopup(passedChild, PassedAgeFrom, PassedAgeTo);
  };

  return isPopupOpen ? (
    <div className="popup">
      <div className="popup-content">
        <div className="table-body popup-border">
          <div className="table-row popup-border">
            <div className="table-column table-pricing-col-input text-align-right">
              # of child:
              <select
                value={firstNumber}
                onChange={(e) => handleNumberChange(e)}
              >
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
              </select>
            </div>
          </div>
          <div className="table-row popup-border">
            <div className="table-column table-pricing-col-input text-align-right">
              Age from:
              <select value={ageFrom} onChange={(e) => handleAgeFromChange(e)}>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
                <option>11</option>
                <option>12</option>
                <option>13</option>
                <option>14</option>
                <option>15</option>
                <option>16</option>
                <option>17</option>
              </select>
            </div>
          </div>
          <div className="table-row popup-border">
            <div className="table-column table-pricing-col-input text-align-right">
              Age to:
              <select value={ageTo} onChange={(e) => handleAgeToChange(e)}>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
                <option>11</option>
                <option>12</option>
                <option>13</option>
                <option>14</option>
                <option>15</option>
                <option>16</option>
                <option>17</option>
              </select>
            </div>
          </div>
          <div className="table-row popup-border">
            <div className="table-column text-align-right">
              <button onClick={verifyValue}>&nbsp;Confirm Changes</button>
            </div>
            <div className="table-column text-align-right">
              <button onClick={CancelUpdate}>&nbsp;Cancel</button>
            </div>
          </div>
          <div className="table-row">
            <div className="table-column text-align-right">
              <label className="popup-myLabel">
                {error && "Age to should be greater or equal age from!"}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default Popup;
