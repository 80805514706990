import { CONFIGURATION } from "utilities/configuration";
import { roomTypeModel } from "./roomType";

function getRoomTypes() {
    let roomTypes = [];

    [...Array(CONFIGURATION.NUMBER_OF_ROOM_TYPES)].forEach(() =>
        roomTypes.push(roomTypeModel)
    );

    return roomTypes;
}

export const roomTypesModel = getRoomTypes();
