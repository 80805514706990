import React, { useState } from "react";
import moment from "moment";
import { CONFIGURATION } from "utilities/configuration";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import DateRangeIcon from "@mui/icons-material/DateRange";
import commonUtilities from "utilities/common";
import "./date-picker.scss";
function DateRangeSelector(props) {

    const [date, setDate] = useState([moment().format(), moment().add(CONFIGURATION.MINIMUM_VACATION_DAYS, "d").format()]);

    const [startDate, setStart] = useState(moment().format());
    const [endDate, setEnd] = useState(moment().add(CONFIGURATION.MINIMUM_VACATION_DAYS, "d").format());
    function setSelectedDates(dateRange) {
        let startDate = dateRange[0];
        let endDate = dateRange[1];
        setDate(dateRange);
        setStart(startDate);
        setEnd(endDate);
        handleData(startDate, endDate);

    }
    function handleData(startDate, endDate) {
        let { handleStep2Data, data } = props;
        let { maximumDate } = data;
        let productType = commonUtilities.getProductType(props);
        if (!endDate && startDate.isAfter(moment(maximumDate).subtract(CONFIGURATION.MINIMUM_VACATION_DAYS, "d"))) {
            startDate = moment(maximumDate).subtract(CONFIGURATION.MINIMUM_VACATION_DAYS - 1, "d");
            endDate = moment(data.maximumDate);
        }

        productType.departureDate = startDate;
        productType.returnDate = !endDate ? moment(startDate).add(CONFIGURATION.MINIMUM_VACATION_DAYS - 1, "d").format() : endDate;
        productType.duration = endDate ? moment(endDate).diff(moment(startDate), CONFIGURATION.DAYS) : CONFIGURATION.MINIMUM_VACATION_DAYS - 1;
        handleStep2Data(productType);
    };

    let { data } = props;
    let { minimumDate, maximumDate } = data;
    let minDate = new Date(minimumDate);
    let maxDate = new Date(maximumDate);

    return (
        <div className="date-range-picker">
            <DateRangePicker
                calendarClassName="date-range-selector"
                clearIcon={null}
                calendarIcon={<DateRangeIcon fontSize="small" color="disabled" />}
                onChange={setSelectedDates}
                value={date}
                minDate={minDate}
                maxDate={maxDate}
                format={"dd MMM y"}
            />

        </div>
    );
}

export default DateRangeSelector;
