import * as actions from "./actions";

export default function quoteLetterReducer(state = {}, action) {
    switch (action.type) {
    
    case actions.GET_QUOTE_LETTER_UPDATE_SUCCESS:
    case actions.GET_QUOTE_LETTER_UPDATE_FAIL:
    {
        return action.payload;
    }
    default:
        return state;

    }
}
