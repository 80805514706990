import React, { Component } from "react";
import { Autocomplete, FormHelperText, MenuItem, TextField } from "@mui/material";

import RoomCategory from "../roomCategory/roomCategory";
import commonUtilities from "utilities/common";
import { Localize } from "utilities/localize";
import getRoomTypes from "services/roomType/roomType.service";
import moment from "moment";
import { connect } from "react-redux";

class DeviationHotel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            deviationRooms: [],
            deviationCabins: []
        };
    }

    handleData = (event, hotel) => {
        let { data, title, index, events, deviationProductType, deviationTypes, deviationIndex, handleStep2DeviationData, deviationCruises } = this.props;
        const { quote, deviationOptionIndex } = data || {};
        const deviationDepartureDate = quote?.deviationTypes?.[deviationOptionIndex]?.departureDate;

        let product = {};

        product.code = (hotel) ? hotel.code : "";
        product.name = (hotel) ? hotel.name : "";
        product.url = (hotel) ? hotel.url : "";
        product.city = (hotel) ? hotel.city : "";
        product.country = (hotel) ? hotel.country : "";
        product.location = (hotel) ? hotel.location : "";
        product.roomTypes = [];
        product.productType = title;

        let deviationTypesClone = structuredClone(deviationTypes);

        deviationTypesClone[deviationOptionIndex].deviation[deviationIndex].products[index] = product;

        handleStep2DeviationData(deviationTypesClone);

        if(hotel) {
            if (deviationProductType !== "Cruise") {
                this.props
                    .dispatch(
                        getRoomTypes(deviationTypes[deviationOptionIndex]?.deviation[deviationIndex].destination.providerCode, deviationDepartureDate, product.code)
                    )
                    .then((response) => {
                        this.setState({ deviationRooms: response?.payload?.data});
                    });
            } else {
                this.setState({ deviationRooms: hotel?.cabins});
            }
        }
    };
    handleRoomData = (event, roomType) => {
        let { index, deviationProductType, deviationTypes, deviationIndex, handleStep2DeviationData, deviationCruises, data } = this.props;
        const { deviationOptionIndex } = data || {};
        let product = {};

        product.code = (roomType) ? roomType.code : "";
        product.name = (roomType) ? roomType.name : "";
        product.url = (roomType) ? roomType.url : "";
        product.roomType = this.props.roomType;


        let deviationTypesClone = structuredClone(deviationTypes);

        deviationTypesClone[deviationOptionIndex].deviation[deviationIndex].products[index].roomTypes[0] = product;

        handleStep2DeviationData(deviationTypesClone);
    };
    filterDeviationRooms(deviationDetails){
        let deviations = deviationDetails?.deviation;
        if(deviations?.length > 0 ){
            let rooms = [];
            deviations?.map((deviation) => {
                deviation.products?.map(product => {
                    product?.roomTypes?.map(room => {
                        rooms.push(product?.code + "-" + room?.code);
                    });
                });
            });
            return rooms;
        } else {
            return [];
        }
    }


    componentDidMount() {
        let { data, index, deviationProductType, deviationTypes, deviationIndex, deviationCruises } = this.props;
        const { quote, deviationOptionIndex } = data || {};
        const deviationDepartureDate = quote?.deviationTypes?.[deviationOptionIndex]?.departureDate;

        if(deviationDepartureDate) {
            if (deviationProductType !== "Cruise") {
                this.props
                    .dispatch(
                        getRoomTypes(deviationTypes[deviationOptionIndex]?.deviation[deviationIndex].destination.providerCode, deviationDepartureDate, deviationTypes[deviationOptionIndex]?.deviation[deviationIndex]?.products[index]?.code)
                    )
                    .then((response) => {
                        this.setState({ deviationRooms: response?.payload?.data});
                    });
            } 
        }
    }

    render() {
        let { data, events, index, errors, error, title, deviationHotels, deviationCruises, deviationProductType, deviationTypes, deviationIndex, roomType } = this.props;
        let { masterHotels, quote, optionIndex, deviationOptionIndex } = data;
        let { productTypes } = quote;
        let productType = productTypes[optionIndex];
        let { products } = productType;
        let product = products[index];
        let { roomTypes } = product;
        let indexText = commonUtilities.getNumberPostFixText(index);
        let selectPlaceHolder = Localize("qma-select-label");
        let hotelPlaceHolder = Localize((productType?.productType?.code.toLowerCase() === "cruise") ? "qma-cruise" : "qma-hotel");
        let choicePlaceHolder = Localize("qma-choice");
        let selectedRooms = this.filterDeviationRooms(deviationTypes[deviationOptionIndex]);
        let selectedDeviation = deviationTypes[deviationOptionIndex]?.deviation[deviationIndex];

        let cabins = [];
        if(roomType.toLowerCase() === "cabin") {
            let productCode = selectedDeviation.products[index]?.code;
            cabins = deviationCruises.find(cruise => cruise.code === productCode)?.cabins ?? [];
        }
        
        return (
            <div className="row hotel-wrapper">
                <div className="row" data-testid="hotel-label">
                    {title + " - " + (index + 1) + indexText + " " + choicePlaceHolder}
                    <Autocomplete
                        autoHighlight
                        data-testid="autocomplete-id"
                        value={selectedDeviation?.products[index] || ""}
                        isOptionEqualToValue={(option) =>
                            option.name === product?.name
                        }
                        onChange={(event, product) => this.handleData(event, product)}
                        options={deviationProductType === "Cruise" ? deviationCruises: deviationHotels}
                        getOptionLabel={(product) => (product ? title?.toLowerCase() === "cruise" ? `${product.name} \\ ${product?.location}`: product.name : "")}
                        renderOption={(props, hotel, index) => (
                            <li {...props} keys={index}>
                                {title?.toLowerCase() === "cruise" ? `${hotel.name} \\ ${hotel?.location}`: hotel.name}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField
                                placeholder={selectPlaceHolder}
                                // className="formControl"
                                className={(!error) ? "formControl" : "errorControl formControl"}

                                {...params}
                            />
                        )}
                    />
                    {errors?.deviationError?.hotel ? (
                        <FormHelperText error={errors?.deviationError?.hotel}>
                            {errors?.deviationError?.hotel?.trim().length > 0 && errors?.deviationError?.hotel}
                        </FormHelperText>
                    ) : null}
                </div>

                <div
                    className={
                        "show row margin-top"
                    }
                >
                    <div className="row row-height room-category-wrapper">
                        {`Select ${roomType.toLowerCase()} type`}
                        <Autocomplete
                            autoHighlight
                            value={selectedDeviation?.products[index]?.roomTypes?.[0] || ""}
                            isOptionEqualToValue={(option) =>
                                option.name === roomType?.name
                            }
                            onChange={(event, roomType) => this.handleRoomData(event, roomType)}
                            getOptionLabel={(roomType) => (roomType ? roomType.name : "")}
                            // TODO:: Move this logic outside 
                            options={roomType.toLowerCase() !== "cabin" ? this.state?.deviationRooms && this.state?.deviationRooms?.length > 0 ? 
                                this.state?.deviationRooms?.filter(
                                    (room) =>
                                        !selectedRooms?.map((selectedRoom) => selectedRoom).includes(selectedDeviation.products[index]?.code + "-" + room?.code)
                                ) : this.state?.deviationRooms :  cabins}
                            renderOption={(props, roomCategory, index) => (
                                <li {...props} keys={index}>
                                    {roomCategory.name}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    placeholder={selectPlaceHolder}
                                    className={(!error) ? "formControl" : "errorControl formControl"}

                                    {...params}
                                />
                            )}
                        />
                        {errors?.deviationError?.room ? (
                            <FormHelperText error={errors?.deviationError?.room}>
                                {errors?.deviationError?.room?.trim().length > 0 && errors?.deviationError?.room}
                            </FormHelperText>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    citiesMasterData: state.citiesMasterData,
    cruiseData: state.cruise
});

export default connect(mapStateToProps)(DeviationHotel);

