import { Localize } from "utilities/localize";
import { useLocation } from "wouter";

export const withRouter = (Component) => {
    const Wrapper = (props) => {
        const setLocation = useLocation();

        return (
            <Component
                navigate={setLocation[1]}
                currentRoute={setLocation[0]}
                localize={(value) => (<div className="localize-text">{Localize(value)}</div>)}
                {...props}
            />
        );
    };

    return Wrapper;
};