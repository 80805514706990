import React, { Component } from "react";
import { connect } from "react-redux";
import { Autocomplete, FormLabel, MenuItem, TextField } from "@mui/material";

import getDestinations from "services/destination/destination.service";
import { CONFIGURATION } from "utilities/configuration";
import commonUtilities from "utilities/common";
import { Localize } from "utilities/localize";

import "reactjs-popup/dist/index.css";

class Destination extends Component {
    componentDidMount() {
        this.getDestinations();
    }

    getDestinations() {
        let productType = commonUtilities.getProductType(this.props);
        if (productType?.code) {
            this.props.dispatch(
                getDestinations(productType?.code)
            );
        }
    }

    handleData = (event, city) => {
        let { handleStep2Data, onDestinationChange } = this.props;

        let productType = commonUtilities.getProductType(this.props);

        const { destination } = productType;

        destination.code = city ? city.code : "";
        destination.name = city ? city.name : "";
        destination.providerCode = city ? city.providerCode : "";

        handleStep2Data({ ...productType });
        onDestinationChange();
    };

    render() {
        let { citiesMasterData, error } = this.props;
        let productType = commonUtilities.getProductType(this.props);

        if (!productType) return;

        let { destination } = productType;
        let cities = citiesMasterData?.data?.cities
            ? citiesMasterData?.data?.cities || []
            : [];
        let selectPlaceHolder = Localize("qma-select-label");
        let product = productType?.productType?.code;

        return (
            <div className="row">
                <FormLabel data-testid="label-destination">
                    {Localize("qma-destination")}
                </FormLabel>

                <Autocomplete
                    data-testid="autocomplete-destination"
                    disabled={!(cities?.length > 0)}
                    autoHighlight
                    value={destination?.code ? destination : ""}
                    isOptionEqualToValue={(option) =>
                        option.name === destination.name
                    }
                    noOptionsText="Select"
                    onChange={(event, city) => this.handleData(event, city)}
                    options={cities}
                    getOptionLabel={(city) =>
                        city ? `${city.name} ${ (product.toLowerCase()==="cruise") ? "" : "(" + city.providerCode + ")" }` : ""
                    }
                    renderOption={(props, city, index) => (
                        <MenuItem {...props} keys={index}>
                            {city.name} {product.toLowerCase()==="cruise" ? "" : "(" + city.providerCode + ")" }
                        </MenuItem>
                    )}
                    renderInput={(params) => (
                        <TextField
                            placeholder={selectPlaceHolder}
                            className={(error?.trim().length === 0) ? "formControl" : "errorControl formControl"}
                            {...params}
                        />
                    )}

                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    citiesMasterData: state.citiesMasterData
});

export default connect(mapStateToProps)(Destination);