import * as actions from "./actions";

export default function quotesReducer(state = [], action) {
    switch (action.type) {

    case actions.GET_QUOTES_SUCCESS:
    case actions.GET_QUOTES_FAIL:
    {
        return action.payload;
    }
    default:
        return state;

    }
}
