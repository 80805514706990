


const GetApplicationMessagePayload = (userLanguage) => {

    return {
        "query": "query($preview:Boolean, $locale:String, $allowedLocale: [String], $appCode:String){applicationsCollection (preview:$preview, locale: $locale, limit:1,where: {application3charCode:$appCode}){ items { linkedFrom (allowedLocales: $allowedLocale) { applicationResourcesCollection (limit:7000, locale: $locale) { items{ key value parseAsMarkdown } } } } }}",
        "variables": {
            "preview": false,
            "locale": (userLanguage === "en") ? "en-US" : "fr",
            "allowedLocale": [
                "en-US",
                "fr"
            ],
            "appCode": "QMA"
        }
    };

};
const GetProductTypesPayload = (userLanguage) => {

    return {
        "query": "query($preview:Boolean, $locale:String, $brand: String) {tourOperatorCollection( preview: $preview locale: $locale limit: 2 where: { tourOperatorCode: $brand } ) {items {tourOperatorCode tourOperatorName linkedFrom(allowedLocales: [\"en-CA\", \"fr\"]) {qmaProductTypeCollection(preview:$preview,locale: $locale){items{productKey, productTypeName,productTypeInternalOnly}}}}}}",
        "variables": {
            "preview": false,
            "locale": (userLanguage === "en") ? "en-CA" : "fr",
            "brand": process.env.REACT_APP_BRAND.toUpperCase()
        }
    };

};
const GetGroupTypesPayload = (userLanguage) => {
  
    return {
        "query": "query($preview:Boolean, $locale:String) { qmaEventTypeCollection(preview:$preview,locale: $locale){items{eventKey, eventTypeName}} }",
        "variables": {
            "preview": false,
            "locale": (userLanguage === "en") ? "en-CA" : "fr"
        }
    };

};

export const API_PARAMS = {
    GetGroupTypesPayload,
    GetProductTypesPayload,
    GetApplicationMessagePayload
};
