import React, { Component } from "react";
import GroupDetails from "./groupDetails";

class ViewQuote extends Component {

    render() {
        let { quote, quoteNumber } = this.props;

        return (
            <div className="row view-quote">
                <GroupDetails group={quote?.groupInformation} quoteNumber={quoteNumber} />
            </div>
        );
    }

}

export default ViewQuote;