import { CONFIGURATION }  from "utilities/configuration";

export const searchModel = {
    quoteNumber: "",
    groupName: "",
    createdOn: null,
    destination: "",
    departure: "",
    isQuoteExpiredOrConfirmed: false,
    pageIndex: CONFIGURATION.PAGE_INDEX,
    pageSize: CONFIGURATION.PAGE_RECORD_SIZE,
    brand: process.env.REACT_APP_BRAND.toUpperCase()
};
