import messages_fr from "translations/fr.json";
import messages_en from "translations/en.json";

const messages = { "fr": messages_fr, "en": messages_en };

export const Localize = (id) => {
    const localize = localStorage.getItem("userLanguage");
    return messages?.[localize]?.[id];
};

export const GetLanguageCode = () => {
    const localize = localStorage.getItem("userLanguage");
    return localize;
};