import * as actions from "./actions";

export default function deviationDepartureCitiesReducer(state = [], action) {

    switch (action.type) {

    case actions.GET_DEVIATION_DEPARTURE_CITIES_SUCCESS:
    case actions.GET_DEVIATION_DEPARTURE_CITIES_FAIL: {
        return action.payload;
    }
    
    default:
        return state;
    }

}