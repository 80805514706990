import * as actions from "./actions";
export default function sfdcReducer(state = {}, action) {
    switch (action.type) {
    case actions.GET_SFDC_SUCCESS:
    case actions.GET_SFDC_FAIL:
    {
        return action.payload;
    }
    default:
        return state;
    }
}

