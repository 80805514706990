import * as actions from "./actions";

export default function emailsReducer(state = [], action) {
    switch (action.type) {

    case actions.GET_EMAIL_LIST_SUCCESS:
    case actions.GET_EMAIL_LIST_FAIL:
    case actions.GET_EMAIL_SUCCESS:
    case actions.GET_EMAIL_FAIL:
    {
        return action.payload;
    }
    default:
        return state;

    }
}
