import React, { Component } from "react";
import { Icon } from "@ICON_REFERENCE";

export default class ProductTypeIcon extends Component {

    render() {
        const { productType } = this.props;
        return (
            <div className="product-type-icon">
                <Icon data-testid="package" name="packages" className={productType === "package" ? "show margin-right" : "hide"} />
                <Icon data-testid="flight" name="flight" className={productType === "flight" ? "show margin-right" : "hide"} />
                <Icon data-testid="hotel" name="hotel" className={productType === "hotel" ? "show margin-right" : "hide"} />
                <Icon data-testid="cruise" name="cruise" className={productType === "cruise" ? "show margin-right" : "hide"} />
                <Icon data-testid="excursions" name="excursions" className={productType === "excursions" ? "show margin-right" : "hide"} />
            </div>
        );

    }
}