import * as actions from "./actions";

export default function hotelsReducer(state = [], action) {
    switch (action.type) {

    case actions.GET_HOTELS_SUCCESS:
    case actions.GET_HOTELS_FAIL:
    {
        return action.payload;
    }
    default:
        return state;

    }
}
