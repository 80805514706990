import React from "react";
import Button from "@mui/material/Button";
import { Localize } from "utilities/localize";
import { withRouter } from "utilities/withRouter";
import { CONSTANTS } from "utilities/constants";
import { useDispatch } from "react-redux";
import "./reQuote.scss";

function ReQuoteModel(props) {
    const { isShow, handleReQuoteModal, reQuoteIndex } = props;

    

    return (
        <div className={ isShow ? CONSTANTS.SHOW : CONSTANTS.HIDE }>

            <div className="popup-container-bg"></div>

            <div className="popup-container cancel-modal-container">

                <div className="popup-body">

                    <div className="popup-heading margin-bottom-1x">
                        Requote
                    </div>

                    <div className="popup margin-bottom-1x">
                        Simple, easy to understand copy like, this feature will get you the new pricing for 
                        <br></br>the same options. However you can edit or add options if you like.                    
                    </div>

                    <div className="row margin-top-3x continue-btn">
                        <Button
                            data-testid={"cancelmodal-yes-button"}
                            className="margin-right-1x"
                            variant="contained"
                            onClick={() => handleReQuoteModal(true, reQuoteIndex)}
                        >
                            {Localize("qma-continue")}
                        </Button>
                        <Button data-testid={"cancelmodal-no-button"} onClick={() => handleReQuoteModal(false, reQuoteIndex)} variant="outlined">
                            {Localize("qma-cancel")}
                        </Button>
                    </div>

                </div>

            </div>

        </div>
    );
}

export default withRouter(ReQuoteModel);