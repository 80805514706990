import { client } from "api/client";
import * as actions from "modules/common/store/actions";
import * as utility from "utilities/utils";

export default function getFlightsDates(params) {
    return async (dispatch) => {

        const url = process.env.REACT_APP_FLIGHTS_DATES;
        dispatch({
            type: actions.GET_FLIGHTS_DATES_RESET
        });

        try {
            const departureResponse = await client.get(`${url}/${params.lang}?dep=${params.departure}&arr=${params.arrival}&dur=${params.duration}`);

            const returnResponse = await client.get(`${url}/${params.lang}?dep=${params.arrival}&arr=${params.departure}&dur=${params.duration}`);

            dispatch({
                type: actions.GET_FLIGHTS_DATES_SUCCESS,
                payload: {
                    data: {
                        departureDate: departureResponse,
                        returnDate: returnResponse
                    }
                }
            });
        } catch (error) {
            dispatch({
                type: actions.GET_FLIGHTS_DATES_FAIL,
                payload: utility.getErrorResponse(error)
            });
        }
    };
}
