import React, { Component } from "react";
import { Autocomplete, MenuItem, TextField } from "@mui/material";

import commonUtilities from "utilities/common";
import { Localize } from "utilities/localize";

class RoomCategory extends Component {

    handleData = (event, room) => {
        let { productIndex, roomTypeIndex, productType, events, errors } = this.props;
        let { handleStep2Data, onRoomTypeChange } = events;
        let { products } = productType;
        let product = products[productIndex];
        let { roomTypes } = product;
        let roomType = roomTypes[roomTypeIndex];

        roomType.code = (room) ? room.code : "";
        roomType.name = (room) ? room.name : "";
        roomType.url = (room) ? room.url : "";

        let index = (roomTypeIndex + 1);

        if (!room || room?.code === "") {
            if (index < roomTypes.length) {

                for (let rIndex = index; rIndex < roomTypes.length; rIndex++) {
                    roomTypes[rIndex].code = "";
                    roomTypes[rIndex].name = "";
                    roomTypes[rIndex].url = "";
                    roomTypes[rIndex].isDisabled = true;
                }
            }
        } else {
            errors.roomTypes[productIndex] = "";

            if (index < roomTypes.length) {
                roomTypes[index].isDisabled = false;
            }
        }

        onRoomTypeChange(errors);
        handleStep2Data({ ...productType });
    };

    filterMasterRooms(products){

        let selectedRooms = [];
        products?.map(product => {
            product?.roomTypes?.map(room => {
                
                if(room?.code !== ""){
                    selectedRooms.push(product?.code +"-"+ room?.code);
                }
            });
        });
        return selectedRooms;
    }

    

    render() {

        let { productType, productIndex, roomTypeIndex, roomType, disabled, error } = this.props;
        let { products } = productType;
        let product = products[productIndex];
       

        let selectPlaceHolder = Localize("qma-select-label");
        let roomPlaceHolder = Localize((productType?.productType?.code.toLowerCase() === "cruise") ? "qma-cabin" : "qma-room-type");
        let choicePlaceHolder = Localize("qma-choice");

        let { masterRoomTypes, roomTypes } = product;
        let indexText = commonUtilities.getNumberByLanguage(roomTypeIndex);
        
        let selectedRooms = this.filterMasterRooms(products);

        return (
            <div className="row row-height room-category-wrapper">
                {roomPlaceHolder + " - " + (roomTypeIndex + 1) + indexText + " " + choicePlaceHolder}
                <Autocomplete
                    disabled={disabled}
                    autoHighlight
                    value={roomType}
                    isOptionEqualToValue={(option) =>
                        option.name === roomType?.name
                    }
                    onChange={(event, roomType) => this.handleData(event, roomType)}
                    getOptionLabel={(roomType) => (roomType ? roomType.name : "")}
                    options={masterRoomTypes?.length > 0 ? masterRoomTypes?.filter(
                        (room) =>
                            !selectedRooms?.map((selectedRoom) => selectedRoom).includes(product?.code+"-"+room.code)
                    ): []}
                    renderOption={(props, roomCategory, index) => (
                        <li {...props} keys={index}>
                            {roomCategory.name}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField
                            placeholder={selectPlaceHolder}
                            className={(!error) ? "formControl" : "errorControl formControl"}
                                
                            {...params}
                        />
                    )}
                />
            </div>
        );
    }
}

export default RoomCategory;