import React, { Component } from "react";
import { Autocomplete, FormHelperText, MenuItem, TextField } from "@mui/material";

import RoomCategory from "../roomCategory/roomCategory";
import commonUtilities from "utilities/common";
import { Localize } from "utilities/localize";

class Hotel extends Component {

    handleData = (event, hotel) => {
        let { index, productType, events } = this.props;
        let { handleStep2Data, onProductChange } = events;
        let { products } = productType;
        let product = products[index];

        product.code = (hotel) ? hotel.code : "";
        product.name = (hotel) ? hotel.name : "";
        product.url = (hotel) ? hotel.url : "";
        product.city = (hotel) ? hotel.city : "";
        product.country = (hotel) ? hotel.country : "";
        product.location = (hotel) ? hotel.location : "";

        let productIndex = (index + 1);

        if (!hotel || hotel?.code === "") {
            if (productIndex < products.length) {

                for (let pIndex = productIndex; pIndex < products.length; pIndex++) {
                    products[pIndex].code = "";
                    products[pIndex].name = "";
                    products[pIndex].url = "";
                    products[pIndex].isDisabled = true;

                    products[pIndex].roomTypes?.forEach(roomType => {
                        roomType.code = "";
                        roomType.name = "";
                        roomType.url = "";
                        roomType.isDisabled = true;
                    });
                }
            }
        } else {
            if ((index + 1) < products.length) {
                products[productIndex].isDisabled = false;
            }
        }

        handleStep2Data({ ...productType });
        onProductChange(index, false);
    };

    render() {
        let { data, events, index, errors, error } = this.props;
        let { masterHotels, quote, optionIndex } = data;
        let { productTypes } = quote;
        let productType = productTypes[optionIndex];
        let { products } = productType;
        let product = products[index];
        let { roomTypes } = product;
        let indexText = commonUtilities.getNumberByLanguage(index);
        let selectPlaceHolder = Localize("qma-select-label");
        let hotelPlaceHolder = Localize((productType?.productType?.code.toLowerCase() === "cruise") ? "qma-cruise" : "qma-hotel");
        let choicePlaceHolder = Localize("qma-choice");
        return (
            <div className="row hotel-wrapper">
                <div className="row" data-testid="hotel-label">
                    {hotelPlaceHolder + " - " + (index + 1) + indexText + " " + choicePlaceHolder}
                    <Autocomplete
                        disabled={product.isDisabled}
                        autoHighlight
                        data-testid="autocomplete-id"
                        value={product}
                        isOptionEqualToValue={(option) =>
                            option.name === product?.name
                        }
                        onChange={(event, product) => this.handleData(event, product)}
                        options={masterHotels}
                        getOptionLabel={(product) => (product?.name ? `${productType?.productType?.code.toLowerCase() === "cruise" ? product.name + " \\ " + product.location : product.name}` : "")}
                        renderOption={(props, hotel, index) => (
                            <li {...props} keys={index}>
                                {productType?.productType?.code.toLowerCase() === "cruise" ? hotel.name + " \\ " + hotel.location : hotel.name}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField
                                placeholder={selectPlaceHolder}
                                // className="formControl"
                                className={(!error) ? "formControl" : "errorControl formControl"}
                                 
                                {...params}
                            />
                        )}
                    />

                </div>

                <div
                    className={
                        product?.name?.length > 0 ? "show row margin-top" : "hide"
                    }
                >
                    {roomTypes?.map((roomType, roomTypeIndex) => (
                        <div key={roomTypeIndex} className="row margin-top-1x">
                            <RoomCategory
                                productIndex={index}
                                roomTypeIndex={roomTypeIndex}
                                data={data}
                                error={errors && errors?.roomTypes[index] && roomTypeIndex === 0}
                                errors={errors}
                                events={events}
                                productType={productType}
                                roomType={roomType}
                                disabled={roomType.isDisabled}
                            />
                            {
                                errors?.roomTypes[index] && roomTypeIndex === 0
                                    ? <FormHelperText error={errors?.roomTypes[index] && roomTypeIndex === 0}>
                                        {errors?.roomTypes[index]?.trim().length > 0 &&
                      errors?.roomTypes[index]}
                                    </FormHelperText>
                                    : ""
                            }
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default Hotel;
