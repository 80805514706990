import React, { Component } from "react";
import { connect } from "react-redux";
import { Autocomplete, FormHelperText, MenuItem, TextField } from "@mui/material";

import { withRouter } from "utilities/withRouter";
import { GetLanguageCode, Localize } from "utilities/localize";
import getGroupEventTypes from "services/groupType/groupType.service";
import validateQuote from "modules/createQuote/validateQuote";

import "./groupType.css";

class GroupType extends Component {
 
    componentDidMount() {
        this.props.dispatch(getGroupEventTypes(GetLanguageCode()));
    }

    handleData = (event, type) => {
        let { quote, errors, handleStep1Data } = this.props;

        const data = {
            target: {
                value: type ? type.code : "",
                name: "groupType"
            }
        };
        handleStep1Data(data);
        validateQuote.isGroupTypeValid(quote, errors);
    };

    render() {

        const { groupTypes, groupType, error } = this.props;
        let selectPlaceHolder = Localize("qma-select-label");

        if (groupTypes?.data?.length > 0) {

            return (

                <div className="row group-type">
                    <div className="row">
                        {Localize("qma-group-type")}
                    </div>
                    <Autocomplete data-testid="select-group-type"
                        disabled={(groupTypes?.length > 0)}
                        autoHighlight
                        value={groupTypes?.data?.filter(type => type.code === groupType)?.length > 0 ? groupTypes?.data?.filter(type => type.code === groupType)?.[0] : ""}
                        onChange={(event, type) => this.handleData(event, type)}
                        options={groupTypes?.data}
                        isOptionEqualToValue={(type) =>
                            type.name === groupType
                        }
                        getOptionLabel={(type) =>
                            type ? `${type.name}` : ""
                        }
                        renderOption={(props, type, index) => (
                            <MenuItem {...props} keys={index}>
                                {type.name}
                            </MenuItem>
                        )}
                        renderInput={(params) => (
                            <TextField
                                placeholder={selectPlaceHolder}
                                className={(error?.trim().length === 0) ? "formControl" : "errorControl formControl"}
                                {...params}
                            />
                        )}
                    />

                    <FormHelperText error={error?.trim().length > 0}>
                        {error?.trim().length > 0 && (
                            error
                        )}
                    </FormHelperText>

                </div>

            );
        }

    }
}

const mapStateToProps = (state) => ({
    groupTypes: state.groupTypes
});

export default connect(mapStateToProps)(withRouter(GroupType));