import React, { Component } from "react";
import { FormHelperText, OutlinedInput } from "@mui/material";

import { Localize } from "utilities/localize";
import { withRouter } from "utilities/withRouter";
import validateQuote from "modules/createQuote/validateQuote";
import "./groupName.css";

class GroupName extends Component {
    render() {
        const { quote, errors, groupName, error, handleStep1Data } = this.props;

        return (
            <div className="row group-name">
                <div className="row">
                    {Localize("qma-group-name")}
                </div>
                <OutlinedInput
                    fullWidth
                    inputProps={{ maxLength: 255 }}
                    name="groupName"
                    id="outlined-group-name"
                    value={groupName}
                    onChange={(event) => {
                        handleStep1Data(event);
                        validateQuote.isGroupNameValid(quote, errors);
                    }
                    }
                    aria-describedby="outlined-group-name-helper-text"
                    error={error?.trim().length > 0}
                />
                <FormHelperText error={error?.trim().length > 0}>
                    {error?.trim().length > 0 && (
                        error
                    )}
                </FormHelperText>
            </div>
        );
    }
}

export default withRouter(GroupName);
