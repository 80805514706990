import * as actions from "./actions";

export default function loaderReducer(state = false, action) {
    switch (action.type) {

    case actions.SET_LOADER_START:
    case actions.SET_LOADER_STOP:
    {
        return action.payload;
    }
    default:
        return state;

    }
}
