import React, { Component } from "react";
import { connect } from "react-redux";
import { Section } from "@sunwing/components";

import quote from "services/quote/quote.service";
import { withRouter } from "utilities/withRouter";
import ViewGroupQuote from "components/quote/view/group/index";
import PageHeader from "components/common/pageHeader/pageHeader";

import "./groupQuoteView.css";

class GroupQuoteView extends Component {

    componentDidMount() {
        this.getQuote();
    }

    getQuote() {
        let { quoteNumber } = this.props.params;
        if(quoteNumber){
            this.props.dispatch(quote.get(quoteNumber));
        }
        
    }

    render() {

        let { quote, params } = this.props;

        return (
            <div className="row margin-bottom-4x review-group-responsive">

                <div className="row">
                    <Section className="print-hide">
                        <div className="page-main-container margin-bottom-1x">
                            <PageHeader />
                        </div>
                    </Section>

                </div>
        
                <ViewGroupQuote quote={quote} params={params} />

            </div>
        );

    }
}

const mapStateToProps = (state) => ({
    quote: state.quote
});

export default connect(mapStateToProps)(withRouter(GroupQuoteView));