import moment from "moment";
import { client } from "api/client";
import * as actions from "modules/common/store/actions";
import * as utility from "utilities/utils";

export default function getCruise(providerCode, languageCode, departureDate= new Date().toISOString(), isManualQuote) {

    const START_DATE = isManualQuote ? moment().add(1, "days").format("YYYY-MM-DD")  :  moment(departureDate).format("YYYY-MM-DD");
    let param = {
        "query":"query ($preview: Boolean, $locale: String, $regionCode: String, $fromDate: DateTime) { itineraryCollection( limit: 20 locale: $locale preview: $preview where: {regionCode: $regionCode departureDates: {dateTime_gte: $fromDate} } ) { total items { sys { id } displayTitle code routeMap regionCode departureDatesCollection(limit: 20) { items { dateTime } } scheduleByDayCollection(limit: 20) { items { dayOfTheWeek port arrivalTime departureTime } } linkedFrom (allowedLocales: [\"en-CA\", \"fr-CA\"]) { shipLineCollection(limit: 20, locale: $locale, preview: $preview) { items { sys { id } title subTitle logo overviewImage stateroomsCollection(limit: 10) { items { title shipRoomCode displayTitle roomImage } } } } } } } }",
        "variables": {
            "preview": false,
            "locale": (languageCode === "en") ? "en-CA" : "fr-CA",
            "regionCode": providerCode,
            "fromDate": new Date(START_DATE).toISOString() 
        }
    };

    return async (dispatch) => {

        return await client.post(process.env.REACT_APP_CRUISE, param).then((response) => {
            let collection = [];
            response?.data?.itineraryCollection?.items?.map((cruise) => {
                cruise?.linkedFrom?.shipLineCollection?.items.map((title) => {
                    title?.overviewImage.map((url) => {
                        let payload =
                        {
                            code: cruise?.code,
                            name: title?.title,
                            url: url?.url,
                            location: cruise?.displayTitle,
                            itineraryCollection: cruise,
                            cabins: title?.stateroomsCollection?.items?.map((cabin) => ({
                                code: cabin?.shipRoomCode,
                                name: cabin?.title,
                                url: cabin?.roomImage !== null ? cabin?.roomImage[0]?.url : ""
                            }))

                        };
                        collection.push(payload);
                    });
                });
            });
            collection.forEach(item => {
                item?.cabins.sort((a, b) => a.name.localeCompare(b.name));
            });
            return dispatch({
                type: actions.GET_CRUISE_SUCCESS,
                payload: utility.getResponse(collection)
            });

        })
            .catch((error) => {

                return dispatch({
                    type: actions.GET_CRUISE_FAIL,
                    payload: utility.getErrorResponse(error)
                });

            });

    };

}
