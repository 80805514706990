import { client } from "api/client";
import * as actions from "modules/common/store/actions";
import * as utility from "utilities/utils";

export default function getCloneDetails(quoteNumber, optionID) {

    return async (dispatch) => {

        return await client.get(process.env.REACT_APP_CLONE_GET + "/" + quoteNumber + "/" + optionID).then((response) => {

            return dispatch({
                type: actions.GET_CLONE_SUCCESS,
                payload: utility.getResponse(response)
            });

        })
            .catch((error) => {

                return dispatch({
                    type: actions.GET_CLONE_FAIL,
                    payload: utility.getErrorResponse(error)
                });

            });


    };

}
