import React, { Component } from "react";
import ViewQuote from "./viewQuote";
import { withRouter } from "utilities/withRouter";
import data from "./data.json";

class GenerateQuote extends Component {

    render() {
        let { quoteNumber } = this.props.params;
        return (
            <div className="row">
                <ViewQuote quote={data} quoteNumber={quoteNumber} />
            </div>
        );
    }
}

export default withRouter(GenerateQuote);
export { GenerateQuote };


