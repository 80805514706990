import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import rootReducer from "./reducer";

const composedEnhancer = composeWithDevTools(
    applyMiddleware(thunkMiddleware)
);
const store = createStore(rootReducer, composedEnhancer);

export default store;
