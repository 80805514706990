import * as actions from "./actions";

export default function groupProductTypesReducer(state = [], action) {
    switch (action.type) {
    case actions.GET_PRODUCT_TYPES_SUCCESS:
    case actions.GET_PRODUCT_TYPES_FAIL: {
        return action.payload;
    }
    default:
        return state;
    }
}
