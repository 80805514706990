import React, { Component, Fragment } from "react";
import ReactHtmlParser from "react-html-parser";
import { Button } from "@mui/material";
import { Section } from "@sunwing/components";
import { Icon } from "@ICON_REFERENCE";
import { Localize } from "utilities/localize";
import RichTextEditor from "react-rte";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "utilities/withRouter";
import updateGroupContract from "services/groupContractUpdate/groupContractUpdate.service";
import getGroupContract from "services/groupContract/groupContract.service";
import getGroupContractEmail from "services/groupContractEmail/groupContractEmail.service";
import CancelModel from "components/common/cancelModel";
import "./groupContract.scss";

class GroupContract extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShow: true,
            isShowDelete: false,
            isShowPolicy: true,
            isShowFees: true,
            isShowPayment: true,
            paymentTerms: RichTextEditor.createEmptyValue(),
            termsAndConditions: [],
            termsAndConditionString: "",
            additionalPolicies: RichTextEditor.createEmptyValue(),
            cancelledFees: RichTextEditor.createEmptyValue(),
            isEditFees: false,
            isEditTerms: false,
            isEditPaymentTerms: false,
            isEditPolicies: false,
            isEmailSend: false,
            isCancelEmail: false,
            isContractLoading: false
        };
    }



    savePayment() {
        let { paymentTerms, isContractLoading } = this.state;
        let payload =
        {
            id: this.props?.groupContract?.id,
            contractDescription: paymentTerms?.toString("html"),
            type: 1
        };
        isContractLoading = true;
        this.setState({ isContractLoading });
        this.props
            .dispatch(updateGroupContract(payload))
            .then((response) => {
                isContractLoading = false;
                this.setState({ isContractLoading });
                this.props.dispatch(getGroupContract());
                this.handleCancelPayment();
            });
    }

    onSaveTerms(id) {
        let { termsAndConditions, termsAndConditionString, isContractLoading } = this.state;

        let updatedTermsAndCondition = termsAndConditions[id];
        termsAndConditions?.map((term) => {
            if (term.id == id) { 
                term["description"] = updatedTermsAndCondition?.descriptionForEdit.toString("html");
                term.isEditTerms = false;
            }
        });

        this.setState({
            termsAndConditions,
        });    
    }

    savePolicies() {
        let { additionalPolicies, isContractLoading } = this.state;
        let payload =
        {
            id: this.props?.groupContract?.id,
            contractDescription: additionalPolicies?.toString("html"),
            type: 3
        };
        isContractLoading = true;
        this.setState({ isContractLoading });
        this.props
            .dispatch(updateGroupContract(payload))
            .then((response) => {
                isContractLoading = false;
                this.setState({ isContractLoading });
                this.props.dispatch(getGroupContract());
                this.handleCancelPolicies();
            });
    }

    saveFees() {
        let { cancelledFees, isContractLoading } = this.state;
        let payload =
        {
            id: this.props?.groupContract?.id,
            contractDescription: cancelledFees?.toString("html"),
            type: 4
        };
        isContractLoading = true;
        this.setState({ isContractLoading });
        this.props
            .dispatch(updateGroupContract(payload))
            .then((response) => {
                isContractLoading = false;
                this.setState({ isContractLoading });
                this.props.dispatch(getGroupContract());
                this.handleCancelFees();
            });


    }

    handleFees = (data) => {
        let { isShowFees, cancelledFees, isEditFees } = this.state;
        isShowFees = false;
        cancelledFees = RichTextEditor.createValueFromString(data, "html");
        isEditFees = true;
        this.setState({
            isShowFees,
            cancelledFees,
            isEditFees
        });
    };

    handlePayment = (data) => {
        let { isShowPayment, paymentTerms, isEditPaymentTerms } = this.state;
        isShowPayment = false;
        paymentTerms = RichTextEditor.createValueFromString(data, "html");
        isEditPaymentTerms = true;
        this.setState({
            isShowPayment,
            paymentTerms,
            isEditPaymentTerms
        });
    };

    handlePolicies = (data) => {
        let { isShowPolicy, additionalPolicies, isEditPolicies } = this.state;
        isShowPolicy = false;
        additionalPolicies = RichTextEditor.createValueFromString(data, "html");
        isEditPolicies = true;
        this.setState({
            isShowPolicy,
            additionalPolicies,
            isEditPolicies
        });
    };

    handleTerms = (id) => {
        let { termsAndConditions } = this.state;
        termsAndConditions[id].isEditTerms = !termsAndConditions[id].isEditTerms;
        this.setState({
          termsAndConditions,
        });    
    };

    onChangeFees = (value) => {
        this.setState({
            cancelledFees: value
        });
    };

    onChangePayment = (value) => {
        this.setState({
            paymentTerms: value
        });
    };

    onChangePolicies = (value) => {
        this.setState({
            additionalPolicies: value
        });
    };
    
    onChangeTerms = (value, index) => {

        let { termsAndConditions } = this.state;
    
        let updatedTermsAndCondition = termsAndConditions[index];
        if(updatedTermsAndCondition) {
            termsAndConditions[index] = { ...updatedTermsAndCondition, descriptionForEdit: value};
            this.setState({ termsAndConditions });   
        }
    };

    handleCancelFees = () => {
        let { isShowFees, isEditFees } = this.state;
        isShowFees = true;
        isEditFees = false;
        this.setState({ isShowFees, isEditFees });
    };

    handleCancelPayment = () => {
        let { isShowPayment, isEditPaymentTerms } = this.state;
        isShowPayment = true;
        isEditPaymentTerms = false;
        this.setState({ isShowPayment, isEditPaymentTerms });
    };

    handleCancelPolicies = () => {
        let { isShowPolicy, isEditPolicies } = this.state;
        isShowPolicy = true;
        isEditPolicies = false;
        this.setState({ isShowPolicy, isEditPolicies });
    };

    handleCancelTerms = (id) => {
        let { termsAndConditions } = this.state;
        let term = termsAndConditions[id];
        console.log(term);
        term.isEditTerms = false;
        term.descriptionForEdit = RichTextEditor.createValueFromString(term.description, "html");

        this.setState({
          termsAndConditions
        });
    };

    handleDeleteOption = () => {
        this.setState({
            isShow: true,
            isShowDelete: false,
            isEditFees: false

        });
    };

    handleDeleteNo = (value) => {
        this.setState({
            isShow: false,
            isShowDelete: false,
            isEditFees: true
        });
    };

    setDeleteModal = () => {
        this.setState({
            isShowDelete: true

        });
    };

    sendEmail() {
        let { isEmailSend, termsAndConditionString, termsAndConditions } = this.state;
        isEmailSend = true;
        this.setState({ isEmailSend });

        setTimeout(() => {
            isEmailSend = false;
            this.setState({ isEmailSend });
           
        }, 5000);

        let payload = 
        {
            id: this.props?.groupContract?.id, 
            contractDescription: termsAndConditionString,
            termsAndConditions: termsAndConditions, 
            type: 2
        };

        this.props
            .dispatch(getGroupContractEmail(payload))
            .then((response) => {
                window.open("about:blank", "_self");
                window.close();
            });
    }

    cancelContract() {
        let { isCancelEmail } = this.state;

        isCancelEmail = true;
        this.setState({ isCancelEmail });

        setTimeout(() => {
            isCancelEmail = false;
            this.setState({ isCancelEmail });
            window.open("about:blank", "_self");
            window.close();
        }, 3000);



    }

    closeEmailPopup = () => {
        let { isEmailSend } = this.state;
        isEmailSend = false;
        this.setState({ isEmailSend });
    };


    render() {
        let { groupContract, groupContracts } = this.props || {};
        let groupInvoiceData = groupContract?.groupInvoiceSection?.toString()?.replace(/&nbsp;/g, " ");
        let groupTermsCondition = this.props?.groupContract?.groupTermsConditions;
        const { isShowFees, isShowPolicy, isEditFees, isEditPaymentTerms, isEditTerms, isEditPolicies, isShowPayment, isEmailSend, isCancelEmail, isContractLoading } = this.state;

        if(this.state.termsAndConditions.length === 0 && groupContract?.termsAndConditions != undefined && groupContract?.quoteNumber !== undefined) {
            let newTermAndConditions = [];
            if(groupContract?.termsAndConditions?.forEach(element => {
                let newDescription = RichTextEditor.createValueFromString(element.description, "html");
              newTermAndConditions.push({...element, isEditTerms: false, descriptionForEdit: newDescription});      
            }));
       
            if (newTermAndConditions.length > 0) {
                this.setState({
                    termsAndConditions:newTermAndConditions,
                    termsAndConditionString:groupTermsCondition
                  });
            }
        }
        let {termsAndConditions} = this.state;

        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS", "LINK_BUTTONS", "BLOCK_TYPE_DROPDOWN"],
            INLINE_STYLE_BUTTONS: [
                { label: "Bold", style: "BOLD", className: "custom-css-class" },
                //   {label: 'Italic', style: 'ITALIC'},
                { label: "Underline", style: "UNDERLINE" }
            ],
            BLOCK_TYPE_DROPDOWN: [
                { label: "Normal", style: "unstyled" },
                { label: "Heading Large", style: "header-one" },
                { label: "Heading Medium", style: "header-two" },
                { label: "Heading Small", style: "header-three" }
            ],
            BLOCK_TYPE_BUTTONS: [
                { label: "UL", style: "unordered-list-item" },
                { label: "OL", style: "ordered-list-item" }
            ]
        };

        const selectedLang = localStorage.getItem("userLanguage");

        const groupTypes = [
            {
              "eventKey": "Sports & Wellness",
              "eventTypeNameEn": "Sports & Wellness",
              "eventTypeNameFr": "Sports et bien-être"
            },
            {
              "eventKey": "Student",
              "eventTypeNameEn": "Student",
              "eventTypeNameFr": "Étudiant"
            },
            {
              "eventKey": "Leisure",
              "eventTypeNameEn": "Leisure",
              "eventTypeNameFr": "Loisir"
            },
            {
              "eventKey": "Wedding",
              "eventTypeNameEn": "Wedding",
              "eventTypeNameFr": "Mariage"
            },
            {
              "eventKey": "Corporate & Specialty",
              "eventTypeNameEn": "Corporate & Specialty",
              "eventTypeNameFr": "Enterprise et Spécialité"
            }
          ];


          const getEventTypeName = (key) => {
            return groupTypes?.map(group => {
                if(group?.eventKey === key){
                    return (selectedLang === "en") ? group?.eventTypeNameEn : group?.eventTypeNameFr;
                }
            });
          };

        return (
            <Fragment>
                <div className="group-contract-container">
                    <div className="group-contract-contents margin-bottom-2x">

                        <div className="group-contract-header">
                            <h1>
                                {Localize("qma-group-contract-header") + " - " + groupContract?.quoteNumber}
                            </h1>
                        </div>

                        <div className="quote-details-container">
                            <div className="group-contract-quote-number-header margin-top-2x">
                                <div className="pull-left">
                                    <svg width="800px" height="800px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" fill="#FFFFFF" stroke="#000000"><polyline points="44 8 44 16 52 16" /><polygon points="52 16 44 8 12 8 12 56 52 56 52 16" /><line x1="20" y1="20" x2="28" y2="20" /><line x1="20" y1="28" x2="44" y2="28" /><line x1="36" y1="36" x2="20" y2="36" /><line x1="20" y1="44" x2="44" y2="44" /></svg>
                                </div>
                                <div className="pull-left">
                                    {Localize("qma-quote-number")}  {groupContract?.quoteNumber}
                                </div>
                            </div>

                            <div className="group-contract-created-date-header margin-top-2x margin-bottom-2x">
                            {Localize("qma-view-date") + ": " + moment(groupContract?.createdDate).format("MMM DD[,] YYYY hh:mm [ET]")}
                            </div>

                            <div className="group-contract-row">
                                <div className="col-left-25-percent">
                                    <div className="group-contract-row font-bold margin-bottom-1x">
                                        {Localize("qma-agreement-between")}:
                                    </div>
                                    {ReactHtmlParser(groupContract?.sunwingOfficeAddress?.replace(/\n/g, '<br />'))}
                                </div>
                                <div className="col-left-25-percent">
                                    <div className="group-contract-row font-bold margin-bottom-1x">
                                        {Localize("qma-and")}:
                                    </div>
                                    <div className="group-contract-row">
                                        {ReactHtmlParser(groupContract?.agencyDetails?.name)}
                                    </div>
                                    <div className="group-contract-row">
                                        {ReactHtmlParser(groupContract?.agencyDetails?.address)}
                                    </div>
                                    <div className="group-contract-row">
                                        <div className="col-left">
                                        {Localize("agency-number")}:
                                        </div>
                                        <div className="col-left">
                                        {ReactHtmlParser(groupContract?.agencyDetails?.agencyNumber)}
                                        </div>
                                    </div>
                                    <div className="group-contract-row">
                                        <div className="col-left">
                                        </div>
                                        <div className="col-left">
                                        </div>
                                    </div>

                                </div>
                                <div className="col-left-50-percent">
                                    <div className="group-contract-row font-bold margin-bottom-1x">
                                         {Localize("qma-group-sales-only")}:
                                    </div>
                                    <div className="group-contract-row">
                                        <div className="col-left">
                                             {Localize("qma-name")}:
                                        </div>
                                        <div className="col-left break-word">
                                            {groupContract?.groupSalesRepresentative?.name}
                                        </div>
                                    </div>
                                    <div className="group-contract-row">
                                        <div className="col-left">
                                             {Localize("qma-email")}:
                                        </div>
                                        <div className="col-left break-word">
                                            {groupContract?.groupSalesRepresentative?.email}
                                        </div>
                                    </div>
                                    <div className="group-contract-row">
                                        <div className="col-left">
                                             {Localize("qma-booking")} #:
                                        </div>
                                        <div className="col-left break-word">
                                            {groupContract?.groupSalesRepresentative?.bookingNumber}
                                        </div>
                                    </div>
                                    <div className="group-contract-row">
                                        <div className="col-left">
                                             {Localize("qma-group-name-type")}:
                                        </div>
                                        <div className="col-left break-word">
                                        {groupContract?.groupSalesRepresentative?.groupName + " / " }{ getEventTypeName(groupContract?.groupSalesRepresentative?.type)}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="group-contract-row group-contract-terms-and-conditions margin-top-2x">
                                {ReactHtmlParser(groupContract?.headerTermsConditions)}
                                <div class="add-initials">
                                    [[i | 1        ]] 
                                </div>
                            </div>
                        </div>

                        <div className="html-row margin-top-4x">
                            {ReactHtmlParser(groupInvoiceData)}
                        </div>

                        <div className="group-information-container">
                            <div className="group-contract-group-information-header margin-top-4x margin-bottom-2x">
                            {Localize("qma-group-information-header")}:
                            </div>

                            <div className="group-contract-row">
                                {ReactHtmlParser(groupContract?.groupInformation?.replace(/\n/g, '<br />'))}
                            </div>
                        </div>


                        <div className="terms-and-conditions-container">
                            {
                                termsAndConditions.length > 0 && termsAndConditions?.map((term, index) => (
                                    !term.isEditTerms ? (
                                        <>
                                        <div className="group-contract-group-terms-and-conditions-header margin-top-4x" key={index}>
                                            <div className="pull-left margin-top-1-4">{ReactHtmlParser(term.title)}</div>
                                            <div className="pull-right print-hide">
                                            <Button 
                                                data-testid={"edit-btn"} 
                                                variant="outlined" 
                                                className="btn-white" 
                                                onClick={() => this.handleTerms(index)}>
                                                <Icon name="edit--n" className="margin-right" />
                                                {Localize("qma-edit")}
                                            </Button>
                                            </div>
                                        </div>
                                        <div className="group-contract-row margin-top-2x">
                                            {ReactHtmlParser(term.description)}
                                        </div>
                                        </>
                                    ) : (
                                        <>
                                        <div className="group-contract-group-terms-and-conditions-header margin-top-4x" key={index}>
                                            <div className="pull-left margin-top-1-4">{ReactHtmlParser(term.title)}</div>
                                            <div className="pull-right print-hide">
                                            <div className="row">
                                                <Button
                                                    data-testid={"cancel-btn"}
                                                    variant="outlined"
                                                    className="btn-white margin-right-1x cancel-button"
                                                    onClick={() => this.handleCancelTerms(index)}
                                                >
                                                    {Localize("qma-cancel")}
                                                </Button>
                                                { }
                                                <Button
                                                    data-testid={"save-btn"}
                                                    variant="outlined"
                                                    className="btn-white"
                                                    onClick={() => this.onSaveTerms(index)}
                                                >
                                                    <Icon name={"checked--n"} className="margin-right" />
                                                    {Localize("qma-save")}
                                                </Button></div>
                                            </div>
                                        </div>

                                        <RichTextEditor
                                            className="richTextEditor"
                                            toolbarConfig={toolbarConfig}
                                            value={term.descriptionForEdit} 
                                            onChange={(value) =>
                                                this.onChangeTerms(value, index)}
                                        />                                      
                                        </>
                                    )
                                ))
                            }
                        </div>


                    </div>

                    <div className="group-contract-footer">

                        <div className="group-contract-footer-row border-top">
                            <div className="group-contract-row">
                                {Localize("qma-contract-declaration-parties")}
                            </div>

                            <div className="group-contract-row margin-top-2x">
                                <div className="col-left">
                                    <div className="group-contract-row">
                                       
                                    </div>
                                    <div className="signature-box">[[s|0       ]]</div>

                                    <div className="group-contract-row margin-top-10x">
                                        {groupContract?.groupSalesRepresentative?.name}
                                        <br />
                                        {Localize("qma-group-sales-only")}
                                    </div>
                                </div>
                                <div className="col-left">
                                    <div className="group-contract-row">
                                        {"(Booking # " + groupContract?.groupSalesRepresentative?.bookingNumber + ")"}
                                    </div>
                                    <div className="signature-box">[[s|1   ]]</div>
                                    <div className="group-contract-row margin-top-10x">
                                        {groupContract?.agencyDetails?.name}
                                        <br />
                                        
                                    </div>
                                </div>
                            </div>

                            <div className="group-contract-row margin-top-6x">
                                Date: <div className="date-box"> [[d|1  ]]</div>
                            </div>

                            <div className="group-contract-row signature-footer font-bold margin-top-1x">
                                {Localize("qma-contract-retain-copy")}
                            </div>

                            <div className="group-contract-row footer-statement">
                                <p>{groupContract?.footer}</p>
                            </div>

                            <div className="group-contract-row margin-top-6x">
                                <div className="group-contract-button">
                                    <Button
                                        data-testid={"cancel-email-contract"}
                                        variant="outlined"
                                        className="btn-red margin-right-1x"
                                        onClick={() => this.cancelContract()}>
                                        <Icon name={"close--n"} className="margin-right" />
                                        Cancel Contract
                                    </Button>

                                    <Button
                                        data-testid={"save-email-group-contract"}
                                        variant="outlined"
                                        className="btn-blue"
                                        onClick={() => this.sendEmail()}
                                    >
                                        <Icon name={"email"} className="margin-right" />
                                        Email Group Contract e-Signature Request
                                    </Button>
                                </div>
                            </div>

                        </div>

                      

                    </div>
                </div>

                {isEmailSend ? <div>
                    <div className="popup-container-bg delete-modal"></div>

                    <div className="popup-container">

                        <div className="popup-body">
                            <div className="close-popup-button" onClick={() => this.closeEmailPopup()}>
                                <span className="swg-icon swg-icon-close--n icon"></span>
                            </div>

                            <div className="popup-heading margin-bottom-1x email-popup-container">
                                       
                                <div className="email-logo-cover"></div>
                                <div className="email-declaration-text">
                                    {Localize("qma-email-request")} 
                                </div>
                            </div>
                        </div>

                    </div>

                </div> : ""}

                {isCancelEmail ? <div>
                    <div className="popup-container-bg delete-modal"></div>

                    <div className="popup-container">

                        <div className="popup-body">

                            <div className="popup-heading margin-bottom-1x">
                                No Group Contract has been generated.
                            </div>
                        </div>

                    </div>

                </div> : ""}
                
                {(isContractLoading || !groupContracts?.status) ?  <div className="lds-container">
                    <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div> : null}

               


            </Fragment>
        );
    }

}

const mapStateToProps = (state) => ({
    groupContracts: state.groupContract
});

export default connect(mapStateToProps)(withRouter(GroupContract));