import React, { Component } from "react";

import { Localize } from "utilities/localize";
import { withRouter } from "utilities/withRouter";
import "./groupDetails.css";

class GroupDetails extends Component {
    

    render() {

        const { group } = this.props;
        const selectedLang = localStorage.getItem("userLanguage");

        const groupTypes = [
            {
              "eventKey": "Sports & Wellness",
              "eventTypeNameEn": "Sports & Wellness",
              "eventTypeNameFr": "Sports et bien-être"
            },
            {
              "eventKey": "Student",
              "eventTypeNameEn": "Student",
              "eventTypeNameFr": "Étudiant"
            },
            {
              "eventKey": "Leisure",
              "eventTypeNameEn": "Leisure",
              "eventTypeNameFr": "Loisir"
            },
            {
              "eventKey": "Wedding",
              "eventTypeNameEn": "Wedding",
              "eventTypeNameFr": "Mariage"
            },
            {
              "eventKey": "Corporate & Specialty",
              "eventTypeNameEn": "Corporate & Specialty",
              "eventTypeNameFr": "Enterprise et Spécialité"
            }
          ];


          const getEventTypeName = (key) => {
            let selectedGroup = "";
            return groupTypes?.map(group => {
                if(group?.eventKey === key){
                    return (selectedLang === "en") ? group?.eventTypeNameEn : group?.eventTypeNameFr;
                }
            });
          };


        if (group) {

            const { groupName, groupType, noOfTravellers  } = group;
            return (
                <div className="group-information-container">
                    <div className="row margin-bottom">
                        <strong>
                            {Localize("qma-group-details")}
                        </strong>
                    </div>
                    <div className="row">
                        <div className="heading">
                            {Localize("qma-group-name")}
                        </div>
                        <div className="value text-ellipsis">
                            {groupName}
                        </div>
                    </div>
                    <div className="row">
                        <div className="heading">
                            {Localize("qma-group-type")}
                        </div>
                        <div className="value">
                            {getEventTypeName(groupType)}
                        </div>
                    </div>
                    <div className="row">
                        <div className="heading">
                            {Localize("qma-travellers")}
                        </div>
                        <div className="value">
                            {noOfTravellers}
                        </div>
                    </div>
                </div>
            );

        }

    }
}

export default withRouter(GroupDetails);