import * as actions from "./actions";

export default function deviationDestinationCitiesReducer(state = [], action) {

    switch (action.type) {

    case actions.GET_DEVIATION_DESTINATION_CITIES_SUCCESS:
    case actions.GET_DEVIATION_DESTINATION_CITIES_FAIL: {
        return action.payload;
    }
    
    default:
        return state;
    }

}