import * as actions from "./actions";

export default function invoiceReducer(state = [], action) {
    switch (action.type) {

    case actions.GET_INVOICE_SUCCESS:
    case actions.GET_INVOICE_FAIL:
    case actions.GET_INVOICE_EMAIL_SUCCESS:
    case actions.GET_INVOICE_EMAIL_FAIL:
    {
        return action.payload;
    }
    default:
        return state;

    }
}
