import { client } from "api/client";
import * as actions from "modules/common/store/actions";
import * as utility from "utilities/utils";

export default function getGroupContractEmail(params) {

    // return async (dispatch) => {

    //     return await client.post(process.env.REACT_APP_GROUP_CONTRACT_EMAIL).then((response) => {

    //         return dispatch({
    //             type: actions.GET_GROUP_CONTRACT_EMAIL_SUCCESS,
    //             payload: utility.getResponse(response)
    //         });

    //     })
    //         .catch((error) => {

    //             return dispatch({
    //                 type: actions.GET_GROUP_CONTRACT_EMAIL_FAIL,
    //                 payload: utility.getErrorResponse(error)
    //             });

    //         });
    // };

    return async (dispatch) => {
        const url = process.env.REACT_APP_GROUP_CONTRACT_EMAIL;

        return await client.post(url, params).then((response) => {

            return dispatch({
                type: actions.GET_GROUP_CONTRACT_EMAIL_SUCCESS,
                payload: utility.getResponse(response)
            });

        })
            .catch((error) => {

                return dispatch({
                    type: actions.GET_GROUP_CONTRACT_EMAIL_FAIL,
                    payload: utility.getErrorResponse(error)
                });

            });
    };
}
