import React from "react";
import Button from "@mui/material/Button";
import { Localize } from "utilities/localize";
import { withRouter } from "utilities/withRouter";
import { CONSTANTS } from "utilities/constants";

function CancelModel(props) {
    const { isShow, handleDelete, handleDeleteNo } = props;

    return (
        <div className={ isShow ? CONSTANTS.SHOW : CONSTANTS.HIDE }>

            <div className="popup-container-bg"></div>

            <div className="popup-container cancel-modal-container">

                <div className="popup-body">

                    <div className="popup-heading margin-bottom-1x">
                        {Localize("qma-cancel-button")}
                    </div>


                    <div className="row margin-top-3x">
                        <Button
                            data-testid={"cancelmodal-yes-button"}
                            onClick={() => handleDelete(true)}
                            className="margin-right-1x"
                            variant="contained"
                        >
                            {Localize("qma-yes")}
                        </Button>
                        <Button data-testid={"cancelmodal-no-button"} onClick={() => handleDeleteNo(true)} variant="outlined">
                            {Localize("qma-no")}
                        </Button>
                    </div>

                </div>

            </div>

        </div>
    );
}

export default withRouter(CancelModel);