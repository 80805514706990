import { client } from "api/client";
import * as actions from "modules/common/store/actions";
import * as utility from "utilities/utils";

function getInvoice(params) {

    return async (dispatch) => {

        return await client.post(process.env.REACT_APP_GET_INVOICE, params).then((response) => {


            return dispatch({
                type: actions.GET_INVOICE_SUCCESS,
                payload: utility.getResponse(response)
            });

        })
            .catch((error) => {

                return dispatch({
                    type: actions.GET_INVOICE_FAIL,
                    payload: utility.getErrorResponse(error)
                });

            });

    };

}

function getInvoiceEmail(params) {

    return async (dispatch) => {

        return await client.post(process.env.REACT_APP_GET_INVOICE_SEND, params).then((response) => {


            return dispatch({
                type: actions.GET_INVOICE_EMAIL_SUCCESS,
                payload: utility.getResponse(response)
            });

        })
            .catch((error) => {

                return dispatch({
                    type: actions.GET_INVOICE_EMAIL_FAIL,
                    payload: utility.getErrorResponse(error)
                });

            });

    };

}

const bookingInVoice = {
    getInvoice,
    getInvoiceEmail
};

export default bookingInVoice;