import React from "react";
import ProductTypes from "../../../../productTypes/productTypes";

export default function CreateQuoteStepThree(props) {
    const {
        data,
        events,
        handleAdditionalReq,
        submitGetQuote,
        handleQuoteOptionDelete,
        handleQuoteDeviationOptionDelete,
        setEditOption,
        setDeviationEditOption,
        setAddOption,
        reQuoteData,
        isReQuote
    } = props;
    const { currentStep } = data;

    if (currentStep !== 3) {
        return null;
    }

    // console.log("Step3", data);

    return (
        <div className="row summary">
            <ProductTypes
                data={data}
                events={events}
                handleAdditionalReq={handleAdditionalReq}
                submitGetQuote={submitGetQuote}
                handleQuoteOptionDelete={handleQuoteOptionDelete}
                handleQuoteDeviationOptionDelete={handleQuoteDeviationOptionDelete}
                setEditOption={setEditOption}
                setDeviationEditOption={setDeviationEditOption}
                setAddOption={setAddOption}
                reQuoteData={reQuoteData}
                isReQuote={isReQuote}
            />
        </div>
    );
}