import commonUtilities from "utilities/common";
import { CONFIGURATION } from "utilities/configuration";
import { productModel } from "./product";

function getProducts() {
    let products = [];
    // const user = commonUtilities.getUserType();
    const isIQMUser = commonUtilities.isIQMUser();

    [...Array(isIQMUser ? CONFIGURATION.NUMBER_OF_PRODUCTS_IQM : CONFIGURATION.NUMBER_OF_PRODUCTS)].forEach(() =>
        products.push(productModel)
    );

    return products;
}

export const productsModel = getProducts();
