import React, { Component } from "react";

import { withRouter } from "utilities/withRouter";
import "./representativeInformation.css";

class RepresentativeInformation extends Component {
    render() {
        const { salesRepresentative } = this.props;
        const { emailAddress, name, phoneNumber, booking, groupName } = salesRepresentative || {};

        return (
            <div className="representative-information-container">
                <div className="row margin-bottom">
                    <strong>{this.props.localize("qma-group-sales")}</strong>
                </div>
                {name && (
                    <div className="row">
                        <div className="heading">
                            {this.props.localize("qma-name")}
                        </div>
                        <div className="value">{name}</div>
                    </div>
                )}
                {phoneNumber && (
                    <div className="row">
                        <div className="heading">
                            {this.props.localize("qma-phoneNumber")}
                        </div>
                        <div className="value">{phoneNumber}</div>
                    </div>
                )}
                {emailAddress && (
                    <div className="row">
                        <div className="heading">
                            {this.props.localize("qma-email")}
                        </div>
                        <div className="value">
                            <a
                                className="link-button"
                                href={"mailTo:" + emailAddress}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {emailAddress}
                            </a>
                        </div>
                    </div>
                )}

                {booking && (
                    <div className="row">
                        <div className="heading">
                            {this.props.localize("qma-booking")}#
                        </div>
                        <div className="value">{booking}</div>
                    </div>
                )}
                {groupName && (
                    <div className="row">
                        <div className="heading">
                            {this.props.localize("qma-group-name-type")}
                        </div>
                        <div className="value">{groupName}</div>
                    </div>
                )}
            </div>
        );
    }
}

export default withRouter(RepresentativeInformation);
