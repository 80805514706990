import moment from "moment";
import React, { Component } from "react";
import { DateRangePicker } from "react-dates";
import { CONFIGURATION } from "utilities/configuration";
import commonUtilities from "utilities/common";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

class DateRange extends Component {
    constructor(props) {
        super(props);

        this.state = {
            startDate: moment().format(),
            endDate: moment().add(CONFIGURATION.MINIMUM_VACATION_DAYS, "d").format(),
            focusedInput: null,
            startDateHasError: false,
            endDateHasError: false
        };
    }

    handleData = ({ startDate, endDate }) => {
        let { handleStep2Data, data } = this.props;
        let { maximumDate } = data;
        let productType = commonUtilities.getProductType(this.props);
        if (!endDate && startDate.isAfter(moment(maximumDate).subtract(CONFIGURATION.MINIMUM_VACATION_DAYS, "d"))) {
            startDate = moment(maximumDate).subtract(CONFIGURATION.MINIMUM_VACATION_DAYS - 1, "d");
            endDate = moment(data.maximumDate);
        }

        productType.departureDate = startDate;
        productType.returnDate = !endDate ? moment(startDate).add(CONFIGURATION.MINIMUM_VACATION_DAYS - 1, "d").format() : endDate;
        productType.duration = endDate ? moment(endDate).diff(moment(startDate), CONFIGURATION.DAYS) : CONFIGURATION.MINIMUM_VACATION_DAYS - 1;
        handleStep2Data(productType);
    };

    render() {
        let { data } = this.props;
        let { minimumDate, maximumDate } = data;
        let productType = commonUtilities.getProductType(this.props);
        if (!productType) return;

        let { departureDate, returnDate } = productType;

        return (
            <div className="row">
                <div className="date-container">
                    <DateRangePicker
                        data-testid="quote_daterangepicker"
                        startDate={moment(departureDate)}
                        endDate={moment(returnDate)}
                        startDateId="s_id"
                        endDateId="e_id"
                        onDatesChange={({ startDate, endDate }) =>
                            this.handleData({ startDate, endDate })
                        }
                        focusedInput={this.state.focusedInput}
                        onFocusChange={(focusedInput) => this.setState({ focusedInput })}
                        displayFormat={CONFIGURATION.DATE_FORMAT}
                        minimumNights={CONFIGURATION.MINIMUM_VACATION_DAYS}
                        minDate={minimumDate}
                        maxDate={maximumDate}
                        isOutsideRange={(day) => day.isAfter(moment(maximumDate).add(1, "d")) || day.isBefore(moment(minimumDate))}
                        // readOnly
                        showDefaultInputIcon
                        inputIconPosition="after"
                        customArrowIcon="-"
                    />
                </div>
            </div>
        );
    }
}

export default DateRange;
