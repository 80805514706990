import { client } from "api/client";
import * as actions from "modules/common/store/actions";
import { CONFIGURATION } from "utilities/configuration";
import { GetLanguageCode } from "utilities/localize";
import * as utility from "utilities/utils";

export default function getDestinations(productType) {

    const LANGUAGE = GetLanguageCode();
    const PRODUCT_TYPE = productType?.toLowerCase();
    const SEARCH_ALIAS = CONFIGURATION.SEARCH_ALIAS;
    const DESTINATION_URL = process.env.REACT_APP_DESTINATION + "?lang=" + LANGUAGE + "&type=" + PRODUCT_TYPE + "&alias=" + SEARCH_ALIAS;

    return async (dispatch) => {

        return await client.get(DESTINATION_URL).then((response) => {

            let cities = [];
            let regions = response.Regions;
            if (PRODUCT_TYPE === "cruise") {
                let options = response.Options;
                let destination = {};

                regions.map(region => {
                    if (region?.SubRegions || region?.SubRegions?.length > 0){
                        let subRegions = region?.SubRegions || [];
                        subRegions?.map(subRegion => {
                            cities.push({ code: subRegion.Code, name: subRegion.Name, providerCode: subRegion.ProviderCode, displayText: subRegion.Name + " (" + subRegion.ProviderCode + ")" });
                        });
                    } else {
                        cities.push({ code: region.Code, name: region.Name, providerCode: region.ProviderCode, displayText: region.Name + " (" + region.ProviderCode + ")" });
                    }
                });
                
                const uniqueCities = [...new Map(cities.map(item => [item["providerCode"], item])).values()];
                destination.cities = regions?.length > 0 ? uniqueCities : response;
                destination.options = options;


                return dispatch({
                    type: actions.GET_CITIES_SUCCESS,
                    payload: utility.getResponse(destination)
                });
            } 
            else {
                let options = response.Options;
                let destination = {};

                regions.map(region => {

                    let destinationGroups = region.DestinationGroups;
                    destinationGroups.map(destinationGroup => {
                        let destinations = destinationGroup.Destinations;

                        destinations.map(destination => {
                            cities.push({ code: destination.Code, name: destination.Name, providerCode: destination.ProviderCode, displayText: destination.Name + " (" + destination.ProviderCode + ")" });
                        });
                    });

                });
                const uniqueCities = [...new Map(cities.map(item => [item["code"], item])).values()];
                destination.cities = regions?.length > 0 ? uniqueCities : response;
                destination.options = options;

                return dispatch({
                    type: actions.GET_CITIES_SUCCESS,
                    payload: utility.getResponse(destination)
                });

            }

        })
            .catch((error) => {
                return dispatch({
                    type: actions.GET_CITIES_FAIL,
                    payload: utility.getErrorResponse(error)
                });
            });
    };

}
