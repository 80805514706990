import { client } from "api/client";
import * as actions from "modules/common/store/actions";
import moment from "moment";
import { CONFIGURATION } from "utilities/configuration";
import { GetLanguageCode } from "utilities/localize";
import * as utility from "utilities/utils";

export default function getRoomTypes(providerCode, startDate, hotelCode, isManualQuote) {
    
    const LANGUAGE = GetLanguageCode();
    const SEARCH_ALIAS = CONFIGURATION.SEARCH_ALIAS;
    const DESTINATION_PROVIDER_CODE = providerCode.toLowerCase();
    const BRAND_CODE = CONFIGURATION.SEARCH_BRAND_CODE;
    const HOTEL_CODE = hotelCode;
    const START_DATE = isManualQuote ? utility.getManualQuoteStartDate() : moment(startDate).format("YYYY-MM-DD");
    const ROOM_TYPES_URL = process.env.REACT_APP_ROOM + "?lang=" + LANGUAGE + "&alias=" + SEARCH_ALIAS + "&dest=" + DESTINATION_PROVIDER_CODE + "&hotelcode=" + HOTEL_CODE + "&s_dt=" + START_DATE  + (BRAND_CODE === "" ? "" : ("&brand=" + BRAND_CODE));

    return async (dispatch) => {

        return await client.get(ROOM_TYPES_URL).then((response) => {

            let roomTypes = [];

            response?.map(roomType => {
                roomTypes.push({ 
                    code: roomType?.Code, 
                    name: roomType?.Name, 
                    url: roomType?.Images?.hasOwnProperty("Img4x3") ? roomType.Images["Img4x3"].length > 0 ? "https:" + roomType.Images["Img4x3"][0].Url : "" : "" });
            });
            roomTypes.sort((a, b) => a.name.localeCompare(b.name));
            return dispatch({
                type: actions.GET_ROOM_TYPES_SUCCESS,
                payload: utility.getResponse(roomTypes)
            });

        })
            .catch((error) => {

                return dispatch({
                    type: actions.GET_ROOM_TYPES_FAIL,
                    payload: utility.getErrorResponse(error)
                });

            });
    };

}
