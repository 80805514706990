import moment from "moment";
import commonUtilities from "utilities/common";
import { CONFIGURATION } from "utilities/configuration";

function clearDates(state) {
    let productType = commonUtilities.getProductTypeFromState(state);
    let { departureDate, returnDate } = productType;

    departureDate = null;
    returnDate = null;

    return { departureDate, returnDate };
}
function clearAdultsAndChildren(state) {
    let productType = commonUtilities.getProductTypeFromState(state);
    let { noOfAdults, noOfChildren } = productType;

    noOfAdults = "";
    noOfChildren = "";

    return { noOfAdults, noOfChildren };
}
function clearDestination(state) {
    let productType = commonUtilities.getProductTypeFromState(state);
    let { destination } = productType;

    destination.code = "";
    destination.name = "";
    destination.providerCode = "";

    return destination;
}

function clearDeparture(state) {
    let productType = commonUtilities.getProductTypeFromState(state);
    let { departure } = productType;

    departure.code = "";
    departure.name = "";
    departure.providerCode = "";

    return departure;
}

function clearProduct(product) {
    product.code = "";
    product.name = "";
    product.url = "";
    product.isDisabled = true;
}

function clearRoomType(roomType) {
    roomType.code = "";
    roomType.name = "";
    roomType.url = "";
    roomType.isDisabled = true;
}

const clearData = {
    clearDates,
    clearAdultsAndChildren,
    clearDestination,
    clearDeparture,
    clearProduct,
    clearRoomType
};

export default clearData;
