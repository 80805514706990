import React, { Component } from "react";
import GroupContract from "./groupContract";
import getGroupContract from "services/groupContract/groupContract.service";
import { connect } from "react-redux";
import { withRouter } from "utilities/withRouter";

class Container extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        this.props.dispatch(getGroupContract());
    }
    

    render() {
        let { groupContract } = this.props;
        return (
            <div className="row">
                <GroupContract groupContract={groupContract?.data?.data} />
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    groupContract: state.groupContract
});

export default connect(mapStateToProps)(withRouter(Container));
export { Container };

