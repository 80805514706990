import * as actions from "./actions";
import * as utility from "utilities/utils";

export default function flightsDatesReducer(state = {}, action) {
    switch (action.type) {
    case actions.GET_FLIGHTS_DATES_SUCCESS:
    case actions.GET_FLIGHTS_DATES_FAIL:
    {
        return action.payload;
    }
    case actions.GET_FLIGHTS_DATES_RESET:
    {
        return utility.getResponse([]);
    }
    default:
        return state;
    }
}

