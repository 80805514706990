import * as actions from "./actions";

export default function quoteReducer(state = {}, action) {
    switch (action.type) {

    case actions.GET_QUOTE_SUCCESS:
    case actions.GET_QUOTE_FAIL:
    {
        return action.payload;
    }
    case actions.GET_QUOTE_TIMED_OUT:
    {
        return {
            timedOut: true
        };
    }
    case actions.QUOTE_RESET:
    {
        return {};
    }
    default:
        return state;

    }
}
