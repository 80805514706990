import { client } from "api/client";
import * as actions from "modules/common/store/actions";
import { CONFIGURATION } from "utilities/configuration";
import { GetLanguageCode } from "utilities/localize";
import * as utility from "utilities/utils";

export default function getDeviationDeparture(departures) {
    let givenDestinations = departures.filter(function (param) { return param.length > 0; });

    const DESTINATION_URL = process.env.REACT_APP_DEVIATION_GATEWAY + "?alias=" + process.env.REACT_APP_SEARCH_ALIAS;

    return async (dispatch) => {

        return await client.post(DESTINATION_URL, givenDestinations).then((response) => {

            let destinations = response.data;

            return dispatch({
                type: actions.GET_DEVIATION_DEPARTURE_CITIES_SUCCESS,
                payload: utility.getResponse(destinations)
            });

        })
            .catch((error) => {
                return dispatch({
                    type: actions.GET_DEVIATION_DEPARTURE_CITIES_FAIL,
                    payload: utility.getErrorResponse(error)
                });
            });
    };

}
