import { client } from "api/client";
import * as actions from "modules/common/store/actions";
import * as endPoints from "utilities/endPoints";
import * as utility from "utilities/utils";

export default function getApplicationMessages(languageCode) {

    return async (dispatch) => {

        return await client.post(process.env.REACT_APP_APPLICATION_MESSAGES, endPoints.API_PARAMS.GetApplicationMessagePayload(languageCode)).then((response) => {

            const data = response?.data?.applicationsCollection?.items[0]?.linkedFrom?.applicationResourcesCollection;

            return dispatch({
                type: actions.GET_CONTENTFUL_SUCCESS,
                payload: utility.getResponse(data)
            });

        })
            .catch((error) => {

                return dispatch({
                    type: actions.GET_CONTENTFUL_FAIL,
                    payload: utility.getErrorResponse(error)
                });

            });

    };

}
