export const CONSTANTS = {
    MINIMUM_VACATION_DAYS: 8,
    NUMBER_OF_HOTELS: 3,
    NUMBER_OF_HOTEL_ROOMS: 3,
    MODAL: "MODAL",
    HOTEL: "hotel",
    CRUISE: "cruise",
    FLIGHT: "flight",
    DEVIATION: "deviation"
};
