import { client } from "api/client";
import * as actions from "modules/common/store/actions";
import * as utility from "utilities/utils";

export default function getSfdc(leadId) {
  return async (dispatch) => {
    const url = process.env.REACT_APP_SFDC;

    return await client
      .get(`${url}/${leadId}`)
      .then((response) => {
        if (response?.data?.language) {
          let profileLanguage =
            response?.data?.language === "French" ? "fr" : "en";
          localStorage.setItem("userLanguage", profileLanguage);
        }

        return dispatch({
          type: actions.GET_SFDC_SUCCESS,
          payload: utility.getResponse(response)
        });
      })
      .catch((error) => {
        return dispatch({
          type: actions.GET_SFDC_FAIL,
          payload: utility.getErrorResponse(error)
        });
      });
  };
}
