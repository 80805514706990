import * as actions from "./actions";

export default function departureCitiesReducer(state = [], action) {
    switch (action.type) {

    case actions.GET_DEPARTURE_CITIES_SUCCESS:
    case actions.GET_DEPARTURE_CITIES_FAIL:
    {
        return action.payload;
    }
    case actions.RESET_DEPARTURE:
    {
        return [];
    }
    default:
        return state;

    }
}
