import { client } from "api/client";
import * as actions from "modules/common/store/actions";
import { CONFIGURATION } from "utilities/configuration";
import { GetLanguageCode } from "utilities/localize";
import * as utility from "utilities/utils";

export default function getDeviationDestinations() {

    const DESTINATION_URL = process.env.REACT_APP_DEVIATION_DESTINATION_GET + "?alias=" + process.env.REACT_APP_SEARCH_ALIAS;

    return async (dispatch) => {

        return await client.get(DESTINATION_URL).then((response) => {

            let destinations = response.data;

            return dispatch({
                type: actions.GET_DEVIATION_DESTINATION_CITIES_SUCCESS,
                payload: utility.getResponse(destinations)
            });

        })
            .catch((error) => {
                return dispatch({
                    type: actions.GET_DEVIATION_DESTINATION_CITIES_FAIL,
                    payload: utility.getErrorResponse(error)
                });
            });
    };

}
