import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import clearData from "./clearData";
import validateQuote from "./validateQuote";
import { quoteModel } from "models/quote-request";
import { travelQuoteError } from "models/error";
import { deviationTypeModel, productTypeModel } from "models/productType";
import { withRouter } from "utilities/withRouter";
import { CONSTANTS } from "modules/common/constants";
import { CONFIGURATION } from "utilities/configuration";

import { productModel } from "../../models/product";
import { productsModel } from "../../models/products";
import { roomTypeModel } from "../../models/roomType";
import { roomTypesModel } from "../../models/roomTypes";

import { GetLanguageCode, Localize } from "utilities/localize";

import CreateQuoteStepOne from "components/quote/create/quoteSteps/stepOne/index";
import CreateQuoteStepTwo from "components/quote/create/quoteSteps/stepTwo/index";
import CreateQuoteStepThree from "components/quote/create/quoteSteps/stepThree/index";
import ProgressIndicator from "components/common/progressIndicator/progressIndicator";

import commonUtilities from "utilities/common";
import PreviousButton from "components/quote/create/previousButton";
import CancelButton from "components/quote/create/cancelButton";
import NextButton from "components/quote/create/nextButton";
import GetQuoteError from "components/common/popups/getQuoteError/getQuoteError";

import getHotels from "services/hotel/hotel.service";
import getRoomTypes from "services/roomType/roomType.service";
import getDepartureCities from "services/departure/departure.service";
import getDestinations from "services/destination/destination.service";
import quote from "services/quote/quote.service";
import getFlightsDates from "services/flightsDates/flightsDates.service";
import getCruise from "services/cruise/cruise.service";
import StayTuned from "components/common/stayTuned/stayTuned";

import "./createQuote.css";
import getDeviationDeparture from "services/destination/departureDeviation.service";
class CreateQuoteWizard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quote: JSON.parse(JSON.stringify(quoteModel)),
      travelQuote: {},
      errors: JSON.parse(JSON.stringify(travelQuoteError)),
      masterHotels: [],
      minimumDate: moment().subtract(1, "d").format(),
      maximumDate: moment().add(CONSTANTS.MINIMUM_VACATION_DAYS, "d").format(),
      currentStep: 1,
      getQuoteError: false,
      getQuoteLoading: false,
      isQuoteCompleted: false,
      isEdit: false,
      isAdd: false,
      optionIndex: 0,
      isErrorShow: false,
      isCloneEdited: false,
      deviationOptionIndex: 0,
      clearSelectedDate: false,
      controller: null,
      isRequestPending: false,
      deviationCruises: [],
      deviationHotels: []
    };
  }

  onGetQuoteErrorCancel = (value) => {
    let { getQuoteError } = this.state;
    getQuoteError = value;
    this.setState({ getQuoteError });
  };

  onQuoteSave = () => {
    const { quote, currentRoute } = this.props;
    if(quote && Object.keys(quote).length !== 0){
        if (quote.timedOut) {
            return;    
        }

        if(currentRoute?.includes("lead") ) {
            let lead = currentRoute?.split("/")[2];
            this.props.navigate(`/lead/${lead}/quote/${quote?.data?.quoteNumber}`);
            
        } else {
            this.props.navigate(`/quote/${quote?.data?.quoteNumber}`);
        }
    }
    
  };

  componentDidMount() {
    const { currentRoute } = this.props;
    let { quote, travelQuote, isCloneEdited, masterHotels} = this.state;
    let {
      quoteData,
      quoteNumber,
      reQuoteData,
      isReQuote,
      cloneIndex,
      isClone,
      cloneData,
      cloneType
    } = this.props;

    let productType = JSON.parse(JSON.stringify(productTypeModel));
    let deviationType = JSON.parse(JSON.stringify(deviationTypeModel));

    productType.language = GetLanguageCode();
    productType.productType.name = Localize("qma-holiday-package");
    // deviationType.language = GetLanguageCode();

    let productTypes = [];
    let deviationTypes = [];

    productTypes.push({ ...productType });
    // deviationTypes.push({ ...deviationType });

    productTypes?.forEach((productType) => {
      productType?.products?.forEach((product) => {
        product.isDisabled = true;

        product?.roomTypes?.forEach((roomType) => {
          roomType.isDisabled = true;
        });
      });
    });
    if (
      (quoteData?.data?.quoteNumber || quoteNumber) &&
      currentRoute !== "/home"
    ) {
      quote["quoteNumber"] = quoteData?.data?.quoteNumber || quoteNumber;
    }

    if (isReQuote) {
      let reQuoteOptionDetails = reQuoteData?.data?.data;
      quote["quoteNumber"] = null;
      quote.productTypes = reQuoteOptionDetails?.productTypes;
      quote.groupInformation = reQuoteOptionDetails?.groupInformation;
      quote.additionalRequirements =
        reQuoteOptionDetails?.additionalRequirements;
      travelQuote = JSON.parse(JSON.stringify(quote));
      this.setState({ travelQuote });
    } else if (isClone) {
      if (productTypes?.length > 0) {
        if (cloneType === "product") {
          quote.productTypes.push(cloneData);
        } else {
          productTypes[0].productType.code = "Deviation";
          productTypes[0].productType.name = "Deviation";
          quote.deviationTypes = [cloneData];
          quote.productTypes = productTypes;
        }
        travelQuote = JSON.parse(JSON.stringify(quote));
        // isCloneEdited = true;
        this.setState({ travelQuote });
      }
    } else {
      quote.productTypes = productTypes;
      // quote.deviationTypes = deviationTypes;
    }
    this.setState({ quote });
    const { createOptionSource } = this.props;

    if (
      createOptionSource &&
      createOptionSource === CONSTANTS.MODAL &&
      !isReQuote &&
      !isClone
    ) {
      this.setState({ currentStep: 2, isAdd: true });
    } else if (
      createOptionSource &&
      createOptionSource === CONSTANTS.MODAL &&
      (isReQuote || isClone)
    ) {
      this.setState({ currentStep: 3, isAdd: false });
    }
  }

  async HandleClone() {
    let { travelQuote, masterHotels, deviationHotels, minimumDate, optionIndex} = this.state;
    const newController = new AbortController();
    if (travelQuote?.productTypes[optionIndex]?.productType?.code === "Package" || travelQuote?.productTypes[optionIndex]?.productType?.code === "Hotel" || travelQuote?.productTypes[optionIndex]?.productType?.code === "Flight") {      
      let params = {
        lang: "en",
        departure: travelQuote?.productTypes[optionIndex]?.departure?.providerCode !== "" ? travelQuote?.deviationTypes[optionIndex]?.departure?.providerCode : travelQuote?.deviationTypes[optionIndex]?.departures[0]?.code,
        arrival: travelQuote?.productTypes[optionIndex]?.destination?.providerCode !== "" ? travelQuote?.productTypes[optionIndex]?.destination?.providerCode : travelQuote?.deviationTypes[optionIndex]?.deviation[0]?.destination?.providerCode,
        duration: 1825
      };     
      this.props.dispatch(getFlightsDates(params));
      
      this.setState(
        { controller: newController, isRequestPending: true },
        () => {
          this.props
            .dispatch(
              getHotels(
                travelQuote?.productTypes[optionIndex]?.destination?.providerCode,
                travelQuote?.productTypes[optionIndex]?.departureDate,
                newController,
                travelQuote?.productTypes[optionIndex]?.isManualQuote
              )
            )
            .then((response) => {
              masterHotels = response.payload.data;
              this.setState({ masterHotels, isRequestPending: false });

              this.handleStep2Data({ ...travelQuote?.productTypes[0] });
              travelQuote?.productTypes[optionIndex]?.products.forEach((city, index) =>{
                this.onProductChange(index, true);
              });
            });
          }
      );
    };
    //Cruise
    if (travelQuote?.productTypes[0]?.productType?.code === "Cruise") {
      let {cloneData} = this.props;
      let params = {
        lang: "en",
        departure: cloneData?.departure?.providerCode,
        arrival: cloneData?.destination?.providerCode,
        duration: 1825
      };     
      this.props.dispatch(getFlightsDates(params));
      
      this.props
        .dispatch(
          getCruise(
            cloneData?.destination?.providerCode,
            GetLanguageCode(),
            travelQuote?.productTypes[optionIndex]?.departureDate,
            travelQuote?.productTypes[optionIndex]?.isManualQuote
          )
        )
        .then((response) => {
          this.setState({ masterHotels: response.payload.data });
          this.handleStep2Data({ ...travelQuote?.productTypes[0] });
          travelQuote?.productTypes[optionIndex]?.products.forEach((city, index) =>{
            this.onProductChange(index, true);
          });
    });
    }
    //Deviation
    if (travelQuote?.productTypes[optionIndex]?.productType?.code === "Deviation") {
      const departureDate = travelQuote?.deviationTypes[optionIndex]?.departureDate;
      if (departureDate) {
        const destinations = travelQuote?.deviationTypes[optionIndex]?.deviation.map(deviation => deviation.destination);
        const { cruisesData, hotelsData } = await this.getCruisesHotelsData(
          destinations,
          departureDate
        );
        this.setState({
          deviationCruises: cruisesData,
          deviationHotels: hotelsData
        });
      }
    }
  }  

  static getDerivedStateFromProps(nextProps, prevState) {
    const productType = commonUtilities.getProductTypeFromState(prevState);

    if (
      nextProps.citiesMasterData?.data?.options !==
        prevState.citiesMasterData?.data?.options &&
      productType !== null &&
      productType?.productType?.code.toLowerCase() !== CONSTANTS.CRUISE
    ) {
      if (nextProps.citiesMasterData?.data?.options) {
        const { RolloverAvailableDays, RolloverMaxAvailableDays } =
          nextProps.citiesMasterData.data.options;
        return {
          citiesMasterData: nextProps.citiesMasterData,
          minimumDate: moment().add(RolloverAvailableDays, "d"),
          maximumDate: moment().add(RolloverMaxAvailableDays, "d")
        };
      }
      return { citiesMasterData: nextProps.citiesMasterData };
    } else if (
      nextProps.cruiseData?.data !== prevState.cruiseData?.data &&
      productType?.productType?.code.toLowerCase() === CONSTANTS.CRUISE
    ) {
      if (prevState.cruiseData?.data && nextProps.cruiseData?.data?.options) {
        return { cruiseData: nextProps.cruiseData };
      }
      return { cruiseData: nextProps.cruiseData };
    }
    return null;
  }

  clearErrors = () => {
    this.setState({
      errors: JSON.parse(JSON.stringify(travelQuoteError))
    });
  };

  handleAdditionalReq = (event) => {
    let { quote } = this.state;
    let value = event.target.value;
    let name = event.target.name;

    quote[name] = value;
    this.setState({ quote });
  };

  _next = () => {
    window.scrollTo(0, 0);
    let { currentStep, isEdit, isCloneEdited } = this.state;
    if (currentStep === 2) {
      let productType = commonUtilities.getProductTypeFromState(this.state);
      // if(productType?.productType?.code === 'Deviations'){
      //     let { quote } = this.state;
      //     quote.deviationTypes = deviationTypes;
      //     console.log("quote.deviationTypes ", quote.deviationTypes);
      //     this.setState({quote})
      // }

      if (isEdit) {
        this.setState({ isEdit: !isEdit });
      }

      this.updateTravelQuote();
      this.setState({ isAdd: false });
    }

    currentStep = currentStep >= 2 ? 3 : currentStep + 1;
    isCloneEdited = true;
    this.setState({ currentStep, isCloneEdited });
  };

  _prev = () => {
    window.scrollTo(0, 0);
    let currentStep = this.state.currentStep;

    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({ currentStep });
  };

  _cancelEdit = () => {
    window.scrollTo(0, 0);
    let { quote, travelQuote } = this.state;

    quote = JSON.parse(JSON.stringify(travelQuote));
    this.setState({ quote, currentStep: 3 });

    const { createOptionSource, handleModal } = this.props;

    if (createOptionSource && createOptionSource === CONSTANTS.MODAL) {
      handleModal(false);
    }

    this.clearErrors();
  };

  cleanObject = (object) => {
    Object.entries(object).forEach(([key, value]) => {
      if (value && typeof value === "object") this.cleanObject(value);
      if (value && typeof value === "object" && !Object.keys(value).length) {
        if (Array.isArray(object)) object.splice(key, 1);
      }
      if (value && typeof value === "object" && Object.keys(value).length) {
        if (Object.prototype.hasOwnProperty.call(value, "isDisabled")) {
          delete value["isDisabled"];
        }
        if (Object.prototype.hasOwnProperty.call(value, "masterRoomTypes")) {
          delete value["masterRoomTypes"];
        }
        if (key === "products" || key === "roomTypes") {
          object[key] = value.filter((element) => element && element.code !== "");
        }
      }
      if (
        (key === "noOfChildren" || key === "noOfTravellers") &&
        value === ""
      ) {
        object[key] = 0;
      }
    });
    return object;
  };

  submitGetQuote = async () => {
    window.scrollTo(0, 0);
    const {
      createOptionSource,
      handleModal,
      currentRoute,
      quoteData,
      quoteNumber,
      isReQuote
    } = this.props;

    if (quoteData?.data?.quoteNumber || quoteNumber) {
      quote["quoteNumber"] = quoteData?.data?.quoteNumber || quoteNumber;
    }
    this.setState({ quote });

    const initialCleanObjTravelQuote = structuredClone(
      this.cleanObject(this.state.travelQuote)
    );

    const updatedProductTypes = initialCleanObjTravelQuote.productTypes.filter(
      item => item.productType.code !== "Deviation"
    );

    const cleanObjTravelQuote = {
      ...initialCleanObjTravelQuote,
      productTypes: updatedProductTypes.length > 0 ? updatedProductTypes : []
    };

    cleanObjTravelQuote?.productTypes.forEach(
      (e, i) =>
        e.productType?.code === "Deviation" &&
        cleanObjTravelQuote?.productTypes?.splice(i, 1)
    );

    cleanObjTravelQuote?.deviationTypes.forEach((e, i) =>
      e.deviation?.forEach(
        (d, j) =>
          d.destination?.code === "" &&
          cleanObjTravelQuote?.deviationTypes[i]?.deviation?.splice(j, 1)
      )
    );

    // clean up the productTypes, if no product selected, remove it.
    let productTypes = [];
    if(cleanObjTravelQuote?.productTypes) {
      cleanObjTravelQuote?.productTypes.forEach(productType => {
        if(productType?.productType?.code === "Flight" || productType.products && productType.products.length > 0) {
          productTypes.push(productType);
        }
      });
    }
    cleanObjTravelQuote.productTypes = productTypes;

    const isIQMUser = commonUtilities.isIQMUser();

    if (
      createOptionSource &&
      createOptionSource === CONSTANTS.MODAL &&
      !isReQuote
    ) {
      this.props.dispatch({
        type: "SET_LOADER_START",
        payload: true
      });
      // handleModal(false);
      await this.props
        .dispatch(quote.save(cleanObjTravelQuote, isIQMUser))
        .then(() => {
          handleModal(false);
        })
        .catch((error) => {
          handleModal(false);
        });
      if (currentRoute !== "/review") {
        this.getQuote();
      }
    } else if (
      createOptionSource &&
      createOptionSource === CONSTANTS.MODAL &&
      isReQuote
    ) {
      this.props.dispatch({
        type: "SET_LOADER_START",
        payload: true
      });
      await this.props
        .dispatch(quote.save(cleanObjTravelQuote, isIQMUser))
        .then((response) => {
          let quoteNumber = response?.payload?.data?.quoteNumber;
          handleModal(false);
          this.getQuote();
          if (quoteNumber) {
            this.props.navigate(`/review/quote/${quoteNumber}`);
          }
        })
        .catch((error) => {
          handleModal(false);
        });
    } else {
      this.setState({ getQuoteLoading: true });
      await this.props.dispatch(quote.save(cleanObjTravelQuote, isIQMUser)).then(response =>{
        this.setState({ getQuoteLoading: false });
        if(response?.payload && response?.payload?.status == "PRECONDITION_FAILED"){
          this.setState({ getQuoteError: true });
        }
        else {
          this.onQuoteSave();
        }
      }).catch(error => {
        this.setState({ getQuoteLoading: false, getQuoteError: true });
      });
    }
  };

  getQuote() {
    window.scrollTo(0, 0);
    const { travelQuote } = this.state;
    let { quoteNumber } = this.props?.params || travelQuote;
    if (quoteNumber) {
      this.props.dispatch(quote.get(quoteNumber));
    }
  }

  handleQuoteOptionDelete = (index) => {
    let { quote, travelQuote } = this.state;

    quote = JSON.parse(JSON.stringify(travelQuote));

    quote.productTypes.splice(index, 1);
    travelQuote.productTypes.splice(index, 1);

    this.setState({ quote, travelQuote });
  };

  handleQuoteDeviationOptionDelete = (index) => {
    let { quote, travelQuote } = this.state;

    quote = JSON.parse(JSON.stringify(travelQuote));

    quote.deviationTypes.splice(index, 1);
    travelQuote.deviationTypes.splice(index, 1);

    this.setState({ quote, travelQuote });
  };

  setEditOption = (index) => {
    let { quote, travelQuote, isCloneEdited } = this.state;
    let { isClone, cloneType } = this.props;
    quote = JSON.parse(JSON.stringify(travelQuote));

    let { productTypes, deviationTypes } = quote;
    let productType = productTypes[index];

    // re-init products model and roomTypes model
    if(productType) {
      if(productType.products) {

        const isIQMUser = commonUtilities.isIQMUser();

        productType.products.forEach((product, index) => {
          if(product.roomTypes) {
            const countOfRoomtypes = product.roomTypes.length;
            [...Array(CONFIGURATION.NUMBER_OF_ROOM_TYPES - countOfRoomtypes)].forEach((i, index) => {
                let roomType = JSON.parse(JSON.stringify(roomTypeModel));
                roomType.isDisabled = (index !== 0);
                product.roomTypes.push(roomType)
              }
            );
          }
          else {
            let roomTypes = JSON.parse(JSON.stringify(roomTypesModel));
            product.roomTypes = roomTypes;
          }
        });
        const countOfProducts = productType?.products?.length;
        [...Array(isIQMUser ? CONFIGURATION.NUMBER_OF_PRODUCTS_IQM - countOfProducts: CONFIGURATION.NUMBER_OF_PRODUCTS - countOfProducts)].forEach((i, index) => {
            let product = JSON.parse(JSON.stringify(productModel));
            product.isDisabled = (index !== 0);
            productType.products.push(product)
          }
        );
      }
      else {
        let products = JSON.parse(JSON.stringify(productsModel));
        productType.products = products;
      }
    }

    productType?.products?.forEach((product, index) => {
      if (product.name.trim().length > 0) {
        const { products } = productType;
        products[index].isDisabled = false;
        if (products?.length > index + 1) {
          products[index + 1].isDisabled = false;
        }
      }
    });

    productType?.products?.forEach((product) => {
      const { roomTypes } = product;
      if(!product.isDisabled && !product.masterRoomTypes) {
        this.attachMasterRoomTypes(product, productType);
      }
      roomTypes?.forEach((roomType, index) => {
        if (roomType?.name?.trim()?.length > 0) {
          product.roomTypes[index].isDisabled = false;
          if (roomTypes?.length > index + 1) {
            roomTypes[index + 1].isDisabled = false;
          }
        }
      });
    });
    if (
      productType?.productType?.code?.toLowerCase() !== "hotel" &&
      productType?.productType?.code?.toLowerCase() !== "deviation"
    ) {
      this.getEnabledDates(productType);
    }
    if (
      isClone &&
      (productType?.productType?.code && productType?.productType?.code?.toLowerCase() !== "deviation")
    ) {
      this.onDestinationChange();
    }

    if(isCloneEdited) {
      // fill up the dropdown
      if (productType?.productType?.code === "Package" || productType?.productType?.code === "Hotel" || productType?.productType?.code === "Flight") {      
        const newController = new AbortController();
        this.setState(
          { controller: newController, isRequestPending: true },
          () => {
            this.props
              .dispatch(
                getHotels(
                  productType?.destination?.providerCode,
                  productType?.departureDate,
                  newController,
                  productType?.isManualQuote
                )
              )
              .then((response) => {
                if (response.payload.data) {
                  this.setState({
                    masterHotels: response.payload.data,
                    isRequestPending: false
                  });
                  // products[0].isDisabled = false;
                }
              });
          }
        );
      }
      //Cruise
      if (productType?.productType?.code === "Cruise") {
        let params = {
          lang: "en",
          departure: productType?.departure?.providerCode,
          arrival: productType?.destination?.providerCode,
          duration: 1825
        };     
        console.log("params", params);
        this.props.dispatch(getFlightsDates(params));
        
        this.props
          .dispatch(
            getCruise(
              productType?.destination?.providerCode,
              GetLanguageCode(),
              productType?.departureDate,
              productType?.isManualQuote
            )
          )
          .then((response) => {
            this.setState({ masterHotels: response.payload.data });
          });
      }
    }    

    this.setState({
      currentStep: 2,
      isEdit: true,
      optionIndex: index,
      deviationOptionIndex: index,
      quote
    });

    if (isClone) {
      this.HandleClone();
    }
  };

  attachMasterRoomTypes = async (product, productType) => {

    let { destination, departureDate } = productType;

    if (product.code && productType?.productType?.code !== "Cruise") {
      await this.props
        .dispatch(
          getRoomTypes(
            destination?.providerCode,
            departureDate,
            product.code,
            productType?.isManualQuote
          )
        )
        .then((response) => {
          product.masterRoomTypes = response.payload.data;
        });
    }
    if (productType?.productType?.code === "Cruise") {
      let { cruiseData } = this.props;

      const cabins = cruiseData.data.find(
        (cruise) => cruise.code === product?.code
      )?.cabins;
      product.masterRoomTypes = cabins;
    }
  }

  setDeviationEditOption = (index, deviationIndex) => {
    let { quote, travelQuote } = this.state;
    let { isClone, cloneType } = this.props;
    quote = JSON.parse(JSON.stringify(travelQuote));

    let { productTypes, deviationTypes } = quote;
    let productType = productTypes[index];

    let deviationType = deviationTypes[deviationIndex];

    // re-init deviationType object
    if(deviationType) {
      deviationType?.deviation?.forEach((deviation, i) => {
        if(deviation?.destination?.code) {
          let newProductList = [];
          for (let k = 0; k < (i * 4); k++) {
            newProductList.push(null);
          }
          deviation?.products?.filter((product) => product && product.productType !== "Cruise").forEach((product, j) => {
            newProductList[i * 4 + j] = product;
          });
          deviation?.products?.filter((product) => product && product.productType === "Cruise").forEach((product, j) => {
            newProductList[i * 4 + j + 2] = product;
          });
          deviation.products = newProductList;
        }
      });
    }

    // re-init products Object and roomTypes Object
    if(productType) {
      if(productType.products) {

        const isIQMUser = commonUtilities.isIQMUser();

        productType.products.forEach((product, index) => {
          if(product.roomTypes) {
            const countOfRoomtypes = product.roomTypes.length;
            [...Array(CONFIGURATION.NUMBER_OF_ROOM_TYPES - countOfRoomtypes)].forEach((i, index) => {
                let roomType = JSON.parse(JSON.stringify(roomTypeModel));
                roomType.isDisabled = (index !== 0);
                product.roomTypes.push(roomType)
              }
            );
          }
          else {
            let roomTypes = JSON.parse(JSON.stringify(roomTypesModel));
            product.roomTypes = roomTypes;
          }
        });
        const countOfProducts = productType?.products?.length;
        [...Array(isIQMUser ? CONFIGURATION.NUMBER_OF_PRODUCTS_IQM - countOfProducts: CONFIGURATION.NUMBER_OF_PRODUCTS - countOfProducts)].forEach((i, index) => {
            let product = JSON.parse(JSON.stringify(productModel));
            product.isDisabled = (index !== 0);
            productType.products.push(product)
          }
        );
      }
      else {
        let products = JSON.parse(JSON.stringify(productsModel));
        productType.products = products;
      }
    }

    productType?.products?.forEach((product, index) => {
      if (product.name.trim().length > 0) {
        const { products } = productType;
        products[index].isDisabled = false;
        if (products?.length > index + 1) {
          products[index + 1].isDisabled = false;
        }
      }
    });

    productType?.products?.forEach((product) => {
      const { roomTypes } = product;
      if(!product.isDisabled && !product.masterRoomTypes) {
        this.attachMasterRoomTypes(product, productType);
      }
      roomTypes?.forEach((roomType, index) => {
        if (roomType?.name?.trim()?.length > 0) {
          product.roomTypes[index].isDisabled = false;
          if (roomTypes?.length > index + 1) {
            roomTypes[index + 1].isDisabled = false;
          }
        }
      });
    });

    this.setState({
      currentStep: 2,
      isEdit: true,
      optionIndex: index,
      deviationOptionIndex: deviationIndex,
      quote
    });

  };
  onAddProductType = () => {
    let { quote, travelQuote } = this.state;

    quote = JSON.parse(JSON.stringify(travelQuote));

    let productType = JSON.parse(JSON.stringify(productTypeModel));

    // re-init when adding new option
    productType.language = GetLanguageCode();
    productType.productType.name = Localize("qma-holiday-package");

    productType.sequence = quote.productTypes.length + 1;
    quote.productTypes.push({ ...productType });

    quote.productTypes?.forEach((productType) => {
      productType?.products?.forEach((product) => {
        product.isDisabled = true;

        product?.roomTypes?.forEach((roomType) => {
          roomType.isDisabled = true;
        });
      });
    });

    this.setState({
      quote,
      currentStep: 2,
      isEdit: false,
      optionIndex: quote.productTypes.length - 1,
      isAdd: true
    });
  };

  updateTravelQuote = () => {
    let { quote, travelQuote } = this.state;
    travelQuote = JSON.parse(JSON.stringify(quote));
    this.setState({ travelQuote });
  };

  isStep1DataValid = (isNextClick) => {
    let { quote, travelQuote, errors } = this.state;
    validateQuote.isStep1DataValid(quote, errors);

    let { groupName, groupType, noOfTravellers } = errors;
    this.setState({ quote, errors });

    if (isNextClick && !(groupName || groupType || noOfTravellers)) {
      travelQuote = JSON.parse(JSON.stringify(quote));
      this.setState({ travelQuote });
      this._next();
    }
  };

  handleStep1Data = (event) => {
    let { quote } = this.state;
    let { groupInformation } = quote;
    let value = event?.target?.value;
    let name = event?.target?.name?.trim();

    groupInformation[name] = value;
    this.setState({ groupInformation });
  };

  isStep2DataValid = (isNextClick) => {
    let { quote, travelQuote, errors, optionIndex, deviationOptionIndex } =
      this.state;

    validateQuote.isStep2DataValid(
      quote,
      errors,
      optionIndex,
      deviationOptionIndex
    );

    this.setState({ quote, travelQuote, errors });
    let {
      destination,
      departure,
      noOfAdults,
      product,
      roomTypes,
      deviationError,
      date
    } = errors;
    if (
      isNextClick &&
      !(
        destination ||
        departure ||
        noOfAdults ||
        product ||
        date ||
        roomTypes.findIndex((roomType) => roomType !== "") !== -1 ||
        Object.values(deviationError)?.some((e) => e !== "")
      )
    ) {
      this._next();
    }
  };

  handleStep3Data = (event) => {
    let { quote, travelQuote } = this.state;
    let value = event?.target?.value;

    quote.additionalRequirements = value;
    travelQuote.additionalRequirements = value;
    this.setState({ quote, travelQuote });
  };

  handleStep2Data = (productType) => {


    let { quote, optionIndex } = this.state;
    let { productTypes } = quote;
    let deviationType = JSON.parse(JSON.stringify(deviationTypeModel));

    if (productType?.productType?.code === "Deviation") {
      if (
        quote.deviationTypes?.length === 0 ||
        quote.deviationTypes[quote.deviationTypes?.length - 1]?.departure
          ?.length !== 0
      ) {
        deviationType.language = GetLanguageCode();
        deviationType.sequence = quote.deviationTypes?.length + 1;
        quote.deviationTypes.push({ ...deviationType });

        this.setState({
          deviationOptionIndex: quote.deviationTypes?.length - 1
        });
      }
    }
    productTypes[optionIndex] = productType;
    this.setState({ quote, productTypes });
  };

  handleStep2DeviationDates = (product) => {
    let { quote, deviationOptionIndex } = this.state;
    let { deviationTypes } = quote;
    deviationTypes[deviationOptionIndex].returnDate = product.returnDate;
    deviationTypes[deviationOptionIndex].departureDate = product.departureDate;
    deviationTypes[deviationOptionIndex].duration = product.duration;

    this.setState({ quote });
  };

  handleStep2DeviationData = (deviationTypes) => {
    const { quote, deviationOptionIndex, errors } = this.state;

    const quoteClone = structuredClone(quote);
    quoteClone.deviationTypes = deviationTypes;

    const errorString = Localize("qma-mandatory");

    errors.deviationError.departure =
      deviationTypes[deviationOptionIndex]?.departures?.length < 1
        ? errorString
        : "";
    errors.deviationError.destination = !deviationTypes[
      deviationOptionIndex
    ]?.deviation.some((f) => f.destination?.code !== "")
      ? errorString
      : "";

    errors.noOfAdults = deviationTypes[deviationOptionIndex]?.noOfAdults === "" ? errorString : "";

    errors.deviationError.hotel = !deviationTypes[
      deviationOptionIndex
    ]?.deviation?.some((f) => f.products.some((e) => e?.code !== ""))
      ? errorString
      : "";
    errors.deviationError.room = !deviationTypes[
      deviationOptionIndex
    ]?.deviation?.some((f) =>
      f.products.some((e) => e?.roomTypes?.[0]?.code !== "")
    )
      ? errorString
      : "";

    this.setState({ quote: quoteClone, errors });
  };

  resetStep2 = (previousIsDeviation) => {
    let { errors } = this.state;
    let productType = commonUtilities.getProductTypeFromState(this.state);

    productType.destination = clearData.clearDestination(this.state);
    productType.departure = clearData.clearDeparture(this.state);
    productType.departureDate = clearData.clearDates(this.state)?.departureDate;
    productType.returnDate = clearData.clearDates(this.state)?.returnDate;
    productType.noOfAdults = clearData.clearAdultsAndChildren(
      this.state
    )?.noOfAdults;
    productType.noOfChildren = clearData.clearAdultsAndChildren(
      this.state
    )?.noOfChildren;

    errors.departure = "";
    errors.destination = "";
    errors.noOfAdults = "";
    errors.deviationError = {};

    const { quote, deviationOptionIndex } = this.state;

    let deviationType = JSON.parse(JSON.stringify(deviationTypeModel));

    let clonedDeviationTypes = structuredClone(quote).deviationTypes;
    if(previousIsDeviation && clonedDeviationTypes) {
      clonedDeviationTypes[clonedDeviationTypes.length - 1] = deviationType;
    }

    const deviationReset = clonedDeviationTypes.filter(
      (e) => e.departures?.length > 0
    );
    quote.deviationTypes = deviationReset;
    this.setState({
      quote,
      productType,
      errors
    });
    this.clearProducts();
  };

  // function to get cruises / hotels data
  getCruisesHotelsData = async (destinations, departureDate) => {
    const filterDestinations = destinations?.filter(
      (destination) => destination && destination?.code !== ""
    );
    
    const promises = [];

    const CruiseDestination = [{code: "61", name: "", providerCode: "61"},{code: "111", name: "", providerCode: "111"}];
    CruiseDestination?.forEach((destination, index) => {
      promises.push(
        this.props
          .dispatch(
            getCruise(
              destination?.providerCode,
              GetLanguageCode(),
              departureDate
            )
          )
          .then((response) => {
            return {
              type: "cruises",
              data: response.payload.data,
              index
            };
          })
      );
    });

    filterDestinations?.forEach((destination, index) => {
      promises.push(
        this.props
          .dispatch(
            getHotels(
              ["61", "111"]?.includes(destination?.providerCode)
                ? destination?.code
                : destination?.providerCode,
              departureDate
            )
          )
          .then((response) => {
            return {
              type: "hotels",
              data: response?.payload?.data,
              index
            };
          })
      );

    });

    const { cruises: cruisesData, hotels: hotelsData } = await Promise.all(
      promises
    ).then((data) =>
      data.reduce(
        (accumulator, current) => {
          if (current.type === "cruises") {
            accumulator[current.type].push(...current.data);
          } else {
            // TODO: Review to see if this needs to be updated with the same logic as cruises to combine multiple lists
            accumulator[current.type][current.index] = current.data;
          }

          return accumulator;
        },
        { cruises: [], hotels: [] }
      )
    );

    return {
      cruisesData,
      hotelsData
    };
  };

  onDestinationChange = async (destinations) => {    
    let { isClone } = this.props;
    let { masterHotels, errors, minimumDate, maximumDate } = this.state;
    let productType = commonUtilities.getProductTypeFromState(this.state);
    let { destination, departure, code, products, language, isManualQuote } = productType;
    const { departureDate, returnDate } = clearData.clearDates(this.state);

    productType.departureDate = departureDate;
    productType.returnDate = returnDate;
    productType.duration = CONFIGURATION.MINIMUM_VACATION_DAYS - 1;

    if (!isClone) {
      this.setState({
        departure: clearData.clearDeparture(this.state),
        productType
      });
      this.clearProducts();
      this.clearDateSelection();
    }

    if (productType?.productType?.code?.toLowerCase() === CONSTANTS.DEVIATION) {
      const { quote, deviationOptionIndex } = this.state;
      const { departureDate } = quote.deviationTypes[deviationOptionIndex];

      await this.props
        .dispatch(
          getDeviationDeparture(
            destinations
              ?.filter((e) => e !== null)
              ?.map((e) =>
                ["61", "111"]?.includes(e.providerCode)
                  ? e.code
                  : e.providerCode
              )
          )
        )
        .then((response) => {
          console.log("response initial selected ", response);
        });

      // if departure date is selected, dispatch call to get hotel / cruise data
      if (departureDate) {
        const { cruisesData, hotelsData } = await this.getCruisesHotelsData(
          destinations,
          departureDate
        );

        this.setState({
          deviationCruises: cruisesData,
          deviationHotels: hotelsData
        });
      }
    } else {
      if (
        productType?.productType?.code?.toLowerCase() !== CONSTANTS.HOTEL &&
        productType?.productType?.code.toLowerCase() !== CONSTANTS.CRUISE
      ) {
        this.props.dispatch(
          getDepartureCities(
            destination?.providerCode,
            productType?.productType?.code,
            productType?.isManualQuote
          )
        );
      }
      if (productType?.productType?.code.toLowerCase() === CONSTANTS.CRUISE) {
        this.props.dispatch(
          getDepartureCities(
            destination?.code,
            productType?.productType?.code,
            productType?.isManualQuote
          )
        );
      }

      if (
        productType?.departure?.providerCode.toLowerCase() !== "" &&
        productType?.departure?.providerCode.toLowerCase() !== "deviation"
      ) {
        this.getEnabledDates();
      }

      masterHotels = [];
      if (
        departureDate &&
        (productType?.productType?.code === "Hotel" ||
          productType?.productType?.code == "Package")
      ) {
        if (this.state.isRequestPending) {
          this.state.controller.abort();
        }

        const newController = new AbortController();
        this.setState(
          { controller: newController, isRequestPending: true },
          () => {
            this.props
              .dispatch(
                getHotels(
                  destination?.providerCode,
                  departureDate,
                  newController,
                  isManualQuote
                )
              )
              .then((response) => {
                masterHotels = response.payload.data;
                this.setState({ masterHotels, isRequestPending: false });
              });
          }
        );
      }

      errors.destination = "";
      this.setState({
        errors
      });
    }
  };

  onDepartureChange = () => {
    let { errors } = this.state;
    let productType = commonUtilities.getProductTypeFromState(this.state);
    let { departure, products, language, destination } = productType;
    const { departureDate, returnDate } = clearData.clearDates(this.state);

    productType.departureDate = departureDate;
    productType.returnDate = returnDate;
    this.clearProducts();

    if (departure.providerCode === "") {
      this.setState({ departure: clearData.clearDeparture(this.state) });
      this.clearDateSelection();
    }

    if (
      productType?.destination?.code.toLowerCase() !== "" &&
      productType?.departure?.code.toLowerCase() !== ""
    ) {
      let duration =
        this.props?.citiesMasterData?.data?.options?.RolloverMaxAvailableDays;
      let params = {
        lang: language,
        departure: departure.providerCode,
        arrival: destination.providerCode,
        duration: duration
      };
      this.props.dispatch(getFlightsDates(params));
    }
    errors.departure = "";
    this.setState({
      errors
    });
  };

  onDateRangeChange = async (cloneInit) => {
    let {
      productType,
      products,
      destination,
      departure,
      departureDate,
      returnDate,
      isManualQuote
    } = commonUtilities.getProductTypeFromState(this.state);

    const { quote, deviationOptionIndex } = this.state;
    const deviationDepartureDate =
      quote?.deviationTypes?.[deviationOptionIndex]?.departureDate;

    let { errors } = this.state;

    if (departureDate && returnDate) {
      errors.date = "";
      this.setState({ errors });
    }

    if (
      departureDate &&
      (productType?.code === "Hotel" || productType?.code == "Package")
    ) {
      products[0].isDisabled = true;
      if(!cloneInit) {
        this.clearProducts();
      }

      if (this.state.isRequestPending) {
        this.state.controller.abort();
      }

      const newController = new AbortController();
      this.setState(
        { controller: newController, isRequestPending: true },
        () => {
          this.props
            .dispatch(
              getHotels(
                destination?.providerCode,
                departureDate,
                newController,
                isManualQuote
              )
            )
            .then((response) => {
              if (response.payload.data) {
                this.setState({
                  masterHotels: response.payload.data,
                  isRequestPending: false
                });
                products[0].isDisabled = false;
              }
            });
        }
      );
    }

    // On date change Cruise list call
    if (departureDate && productType?.code.toLowerCase() === CONSTANTS.CRUISE) {
      products[0].isDisabled = true;
      this.clearProducts();
      this.props
        .dispatch(
          getCruise(
            destination?.providerCode,
            GetLanguageCode(),
            departureDate,
            isManualQuote
          )
        )
        .then((response) => {
          this.setState({ masterHotels: response.payload.data });
          products[0].isDisabled = false;
        });
    }

    if (
      deviationDepartureDate &&
      productType?.code.toLowerCase() === "deviation"
    ) {
      const { deviation } = quote?.deviationTypes?.[deviationOptionIndex];
      this.clearDeviationProducts();
      const filterDestinations = deviation?.map(
        (destination) => destination.destination
      );

      const { cruisesData, hotelsData } = await this.getCruisesHotelsData(
        filterDestinations,
        deviationDepartureDate
      );

      this.setState({
        deviationCruises: cruisesData,
        deviationHotels: hotelsData
      });
    }
  };

  getEnabledDates = (productType) => {
    
    if(!productType) {
      productType = commonUtilities.getProductTypeFromState(this.state);
    }

    if (
      !productType &&
      productType?.productType?.code?.toLowerCase() === "deviation"
    ) {
      return;
    }
    let { destination, departure, language } = productType || {};
    let duration =
      this.props?.citiesMasterData?.data?.options?.RolloverMaxAvailableDays;
    let params = {
      lang: language || "en",
      departure: departure?.providerCode,
      arrival: destination?.providerCode,
      duration: duration
    };
    if (departure?.code !== "" && destination?.code !== "" && duration) {
      this.props.dispatch(getFlightsDates(params));
    }
  };

  onNoOfAdultChange = () => {
    let { errors } = this.state;
    let productType = commonUtilities.getProductTypeFromState(this.state);
    let { noOfAdults } = productType;

    if (noOfAdults === "" || noOfAdults > 0) {
      errors.noOfAdults = "";
      this.setState({ errors });
    }
  };

  onDevationNoOfAdultChange = () => {
    let { errors } = this.state;
    let productType = commonUtilities.getDeviationProductTypeFromState(this.state);
    let { noOfAdults } = productType;

    if (noOfAdults === "" || noOfAdults > 0) {
      errors.noOfAdults = "";
      this.setState({ errors });
    }
  };
  onProductChange = async (productIndex, cloneInit) => {
    let { errors } = this.state;
    let productType = commonUtilities.getProductTypeFromState(this.state);
    let { destination, products, departureDate } = productType;
    let product = products[productIndex];

    if(!cloneInit) {
      product.roomTypes?.map((roomType) => {
        clearData.clearRoomType(roomType);
      });
    }
    if (product.code === "") {
      errors.roomTypes.forEach(
        (roomType, index) => (errors.roomTypes[index] = "")
      );
    }

    await this.attachMasterRoomTypes(product, productType);

    if(product.roomTypes && product.roomTypes.length > 0) {
      product.roomTypes[0].isDisabled = false;
    }
    this.setState(product);
    
    errors.product = "";

    this.setState({ product, errors });
  };

  onRoomTypeChange = (error) => {
    let { errors } = this.state;
    errors = error;

    this.setState({ errors });
  };

  clearProduct = (product) => {
    let { errors } = this.state;

    product.masterRoomTypes = [];
    product.roomTypes?.map((roomType) => {
      clearData.clearRoomType(roomType);
    });

    errors.product = "";
    this.setState({ product, errors });
  };

  clearProducts = () => {
    let { errors } = this.state;
    let productType = commonUtilities.getProductTypeFromState(this.state);
    let { products } = productType;

    products?.map((product) => {
      clearData.clearProduct(product);
      product.masterRoomTypes = [];

      product.roomTypes?.map((roomType) => {
        clearData.clearRoomType(roomType);
      });

      this.setState({ product });
    });

    errors.product = "";
    this.setState({ errors });
  };

  clearDeviationProducts = () => {
    const { quote, deviationOptionIndex } = this.state;
    const deviationType = quote?.deviationTypes?.[deviationOptionIndex];

    deviationType?.deviation?.forEach((product) => {
      product.products = [];
    });

    this.setState({ quote });
  };

  getDestinations = (productType) => {


    if (productType?.code) {
      this.props.dispatch(getDestinations(productType?.code));
    }
  };

  clearDateSelection = () => {
    let { clearSelectedDate } = this.state;
    clearSelectedDate = !clearSelectedDate;
    this.setState({ clearSelectedDate });
  };

  render() {
    let {
      contentfulMasterData,
      cruises,
      citiesMasterData,
      reviewQuoteIndex,
      createOptionSource,
      currentRoute,
      quoteData,
      quotesData,
      reQuoteData,
      isReQuote,
      isClone,
      cloneIndex,
      flightsDates
    } = this.props;
    const contentfulData = contentfulMasterData?.data?.items;
    const wizardInformation = commonUtilities?.getContentfulDataById(
      contentfulData,
      "qma-wizard-information"
    );
    let {
      quote,
      travelQuote,
      errors,
      currentStep,
      optionIndex,
      masterHotels,
      minimumDate,
      maximumDate,
      isAdd,
      isEdit,
      isCloneEdited,
      deviationOptionIndex,
      clearSelectedDate,
      deviationCruises,
      deviationHotels,
      getQuoteError,
      getQuoteLoading
    } = this.state;
    const isIQMUser = commonUtilities.isIQMUser();

    return (
      getQuoteLoading ? 
      <div className="row margin-top-1x">
        <StayTuned />
      </div>
      :
      <div className="row">
        <ProgressIndicator steps={3} activeStep={currentStep} />

        <div className="row border">
          <div className="wizard-container">
            {!isIQMUser
              ? currentStep !== 1 && (
                  <div className="row margin-bottom-3x">
                    {wizardInformation}
                  </div>
                )
              : ""}

            <CreateQuoteStepOne
              data={{ quote, currentStep }}
              events={{
                handleStep1Data: this.handleStep1Data
              }}
              errors={errors}
            />
            <CreateQuoteStepTwo
              data={{
                currentStep,
                optionIndex,
                quote,
                travelQuote,
                cruises,
                citiesMasterData,
                maximumDate,
                minimumDate,
                masterHotels,
                contentfulData,
                createOptionSource,
                currentRoute,
                quoteData,
                quotesData,
                reviewQuoteIndex,
                isReQuote,
                isEdit,
                isClone,
                cloneIndex,
                deviationOptionIndex,
                clearSelectedDate,
                flightsDates,
                deviationCruises,
                deviationHotels
              }}
              events={{
                handleStep2Data: this.handleStep2Data,
                handleStep2DeviationData: this.handleStep2DeviationData,
                handleStep2DeviationDates: this.handleStep2DeviationDates,
                getDestinations: this.getDestinations,
                onDestinationChange: this.onDestinationChange,
                onDepartureChange: this.onDepartureChange,
                onDateRangeChange: this.onDateRangeChange,
                onProductChange: this.onProductChange,
                onNoOfAdultChange: this.onNoOfAdultChange,
                onDevationNoOfAdultChange: this.onDevationNoOfAdultChange,
                onRoomTypeChange: this.onRoomTypeChange,
                resetStep2: this.resetStep2,
                clearErrors: this.clearErrors
              }}
              errors={errors}
            />
            <CreateQuoteStepThree
              data={{
                travelQuote,
                currentStep,
                createOptionSource,
                currentRoute,
                quoteData,
                quotesData,
                reviewQuoteIndex,
                reQuoteData,
                isReQuote,
                isClone,
                cloneIndex,
                isCloneEdited
              }}
              events={{
                handleStep3Data: this.handleStep3Data
              }}
              handleAdditionalReq={(event) => this.handleAdditionalReq(event)}
              submitGetQuote={this.submitGetQuote}
              handleQuoteOptionDelete={(index) =>
                this.handleQuoteOptionDelete(index)
              }
              handleQuoteDeviationOptionDelete={(index) =>
                this.handleQuoteDeviationOptionDelete(index)
              }
              setEditOption={(index) => this.setEditOption(index)}
              setDeviationEditOption={(index, deviationIndex) =>
                this.setDeviationEditOption(index, deviationIndex)
              }
              setAddOption={this.onAddProductType}
            />
          </div>
          <div className="wizard-footer">
            <PreviousButton
              events={{ previous: this._prev, cancel: this._cancelEdit }}
              data={{ currentStep, isEdit, isAdd }}
            />
            {/* <CancelButton events={{ previous: this._prev, cancel: this._cancelEdit }} data={{ currentStep, isEdit, isAdd }} /> */}
            <NextButton
              events={{
                isStep1DataValid: this.isStep1DataValid,
                isStep2DataValid: this.isStep2DataValid
              }}
              data={{ currentStep }}
            />
          </div>
          <GetQuoteError
            isShow={getQuoteError}
            onCancel={(value) => this.onGetQuoteErrorCancel(value)}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  citiesMasterData: state.citiesMasterData,
  departureCitiesMasterData: state.departureCitiesMasterData,
  vacationDuration: state.vacationDuration,
  hotels: state.hotels,
  cruiseData: state.cruise,
  roomTypesMasterData: state.roomTypesMasterData,
  contentfulMasterData: state.contentfulMasterData,
  quoteData: state.quote,
  quotesData: state.quotes,
  reQuoteData: state.reQuoteDetails,
  flightsDates: state.flightsDates,
  quote: state.quote
});

export default connect(mapStateToProps)(withRouter(CreateQuoteWizard));
