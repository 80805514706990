import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Localize } from "utilities/localize";

const TimeOutModal = ({ handleNavigate }) => {    
    return (
        <div>
            <Dialog
                open={true}
                onClose={handleNavigate}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {Localize("quote-timeout-title")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {Localize("quote-timeout-message")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleNavigate} autoFocus>{Localize("quote-timeout-button")}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}; 

export { TimeOutModal };