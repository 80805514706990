import React, { Component } from "react";
import { connect } from "react-redux";
import Slider from "react-slick";
import { FormHelperText } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./hotelDetails.css";

import Hotel from "./../hotel/hotel";
import commonUtilities from "utilities/common";

class HotelDetails extends Component {
    render() {
        let { data, events, errors } = this.props;
        let productType = commonUtilities.getProductType(this.props);
        if (!productType) return;
    
        let { products } = productType;
        let title = productType?.productType?.code === "Cruise" ? "Cruise" : "Hotel";

        const settings = {
            dots: false,
            arrows: true,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            centerMode: false,
            nextArrow: <ChevronRightIcon color="primary" />,
            prevArrow: <ChevronLeftIcon color="primary" />
        };

        return (
            <div className="row slider">
                <Slider {...settings}>
                    {products?.map((product, index) => (
                        <div className="col-3" key={index}>
                            <Hotel
                                index={index}
                                data={data}
                                events={events}
                                productType={productType}
                                title={title}
                                error={errors && errors?.product && index === 0}
                                errors={errors}
                                disabled={productType?.departure?.code?.trim().length === 0}
                            />

                            {errors?.product && index === 0 ? (
                                <FormHelperText error={errors?.product && index === 0}>
                                    {errors?.product?.trim().length > 0 && errors?.product}
                                </FormHelperText>
                            ) : (
                                ""
                            )}
                        </div>
                    ))}
                </Slider>
            </div>
        );

    }
}

const mapStateToProps = (state) => ({
    productTypes: state.productTypes
});

export default connect(mapStateToProps)(HotelDetails);
