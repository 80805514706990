import { combineReducers } from "redux";

import cabinsReducer from "./modules/common/store/cabinsReducer";
import citiesReducer from "./modules/common/store/citiesReducer";
import contentfulReducer from "./modules/common/store/contentfulReducer";
import departureCitiesReducer from "./modules/common/store/departureCitiesReducer";
import groupProductTypesReducer from "./modules/common/store/groupProductTypeReducer";
import groupTypesReducer from "./modules/common/store/groupTypesReducer";
import hotelsReducer from "./modules/common/store/hotelsReducer";
import profileReducer from "./modules/common/store/profileReducer";
import quoteItineraryReducer from "./modules/common/store/quoteItineraryReducer";
import quoteReducer from "./modules/common/store/quoteReducer";
import quotesReducer from "./modules/common/store/quotesReducer";
import roomTypesReducer from "./modules/common/store/roomTypesReducer";
import sfdcReducer from "./modules/common/store/sfdcReducer";
import flightsDatesReducer from "./modules/common/store/flightDatesReducer";
import emailsReducer from "./modules/common/store/emailReducer";
import cruiseReducer from "modules/common/store/cruiseReducer";
import reQuoteReducer from "./modules/common/store/reQuoteReducer";
import cloneReducer from "./modules/common/store/cloneReducer";
import deviationDestinationsReducer from "./modules/common/store/deviationDestinationsReducer";
import deviationDepartureCitiesReducer from "./modules/common/store/deviationDepartureCitiesReducer";
import groupContractReducer from "modules/common/store/groupContractReducer";
import invoiceReducer from "modules/common/store/invoiceReducer";
import quoteLetterReducer from "modules/common/store/quoteLetterReducer";
import loaderReducer from "modules/common/store/loaderReducer";


const rootReducer = combineReducers({
    profile: profileReducer,
    quote: quoteReducer,
    quotes: quotesReducer,
    groupTypes: groupTypesReducer,
    productTypes: groupProductTypesReducer,
    citiesMasterData: citiesReducer,
    departureCitiesMasterData: departureCitiesReducer,
    hotels: hotelsReducer,
    roomTypesMasterData: roomTypesReducer,
    cabinsMasterData: cabinsReducer,
    contentfulMasterData: contentfulReducer,
    quoteItinerary: quoteItineraryReducer,
    sfdc: sfdcReducer,
    flightsDates: flightsDatesReducer,
    flightsReturnDates: flightsDatesReducer,
    emails: emailsReducer,
    cruise: cruiseReducer,
    reQuoteDetails: reQuoteReducer,
    cloneDetails: cloneReducer,
    destinations: deviationDestinationsReducer,
    departures: deviationDepartureCitiesReducer,
    groupContract: groupContractReducer,
    inVoice: invoiceReducer,
    quoteLetter: quoteLetterReducer,
    isLoaderSpinning: loaderReducer
});

export default rootReducer;
