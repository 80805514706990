export const travelQuoteError = {
    groupName: "",
    groupType: "",
    noOfTravellers: "",
    destination: "",
    departure: "",
    noOfAdults: "",
    product: "",
    roomTypes: ["", "", ""],
    deviationError: {}
};
