import React, { Component } from "react";
import { FormHelperText, OutlinedInput } from "@mui/material";

import { Localize } from "utilities/localize";
import { withRouter } from "utilities/withRouter";
import validateQuote from "modules/createQuote/validateQuote";
import "./groupNoOfTravellers.css";

class GroupNoOfTravellers extends Component {

    render() {

        const { quote, errors, noOfTravellers, error, handleStep1Data } = this.props;

        return (
            <div className="row group-no-of-travellers">
                <div className="row">
                    {Localize("qma-total-number-of-travellers")}
                </div>
                <OutlinedInput
                    fullWidth
                    type="text"
                    name="noOfTravellers"
                    id="outlined-total-number-of-travellers"
                    value={noOfTravellers}
                    inputProps={{ maxLength: 3 }}
                    onChange={(event) => {
                        const re = /^[0-9\b]+$/;
                        if (event.target.value === "" || re.test(event.target.value)) {
                            handleStep1Data(event);
                            validateQuote.isGroupNumberOfTravellersValid(quote, errors);
                        }
                    }}
                    aria-describedby="outlined-total-number-of-travellers"
                    error={error?.trim().length > 0}
                />

                <FormHelperText error={error?.trim().length > 0}>
                    {error?.trim().length > 0 && (
                        error
                    )}
                </FormHelperText>

            </div>
        );
    }
}

export default withRouter(GroupNoOfTravellers);
