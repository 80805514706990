import React, { useState } from "react";
import Button from "@mui/material/Button";
import { Localize } from "utilities/localize";
import { withRouter } from "utilities/withRouter";

function Confirmation(props) {

    const { data, isShow, onSubmit, onCancel, isButtonDisabled } = props;
    const { selectedData, selectedAction, selectedItinerary } = data;
    //const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [waitingMessage, setWaitingMessage] = useState("");
    
    const handleClick = () => {
        isShow ? setWaitingMessage("Please wait, we are processing your request.") : setWaitingMessage("");
        //setIsButtonDisabled(true);
        onSubmit(data);
        //setIsButtonDisabled(false);
    }

    return (
        <div className={isShow ? "show" : "hide"}>

            <div className="popup-container-bg"></div>

            <div className="popup-container">

                <div className="popup-container-border">

                    <div className="popup-heading margin-bottom-1x">
                        {selectedAction === "Available" ? (selectedData?.unavailablePopUpTitleText) : (selectedData?.activeInactivePopUpTitleText) }
                    </div>

                    <div className="popup margin-bottom-1x">
                        {selectedAction === "Available" ? (selectedData?.unavailablePopUpText) : (selectedData?.activeInactiveText)}                    
                    </div>

                    <div className="row margin-top-1x margin-bottom-2x">
                        <div className="row popup-heading-1x">
                            {Localize("qma-make-change")}
                        </div>
                        <div className="row margin-top">
                            <strong>Option: </strong> {selectedData?.option}
                        </div>
                        <div className="row">
                            <strong>Itinerary: </strong> {selectedData?.itineraryText}
                        </div>
                    </div>

                    <div className="row">

                        <Button data-testid={"yes-button"} 
                            onClick={() => handleClick (data)}
                            disabled={isButtonDisabled}
                            className="margin-right-1x" 
                            variant="contained">
                            {Localize("qma-yes")}
                        </Button>

                        <Button data-testid={"no-button"} 
                            onClick={() => onCancel()} 
                            disabled={isButtonDisabled}
                            variant="outlined">
                            {Localize("qma-no")}
                        </Button>

                    </div>
                    <div className="row popup-heading-1x">
                        {waitingMessage}
                    </div>
                </div>

            </div>

        </div>

    );
}

export default withRouter(Confirmation);