import React from "react";
import { Header } from "@sunwing/components";
import { NavLink } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { Localize } from "utilities/localize";

import "./appHeader.css";
import commonUtilities from "utilities/common";
import Moment from "moment";
import "moment/locale/fr";



const AppHeader = () => {
    const { authState, oktaAuth } = useOktaAuth();
    const auth = authState?.isAuthenticated;

    let userName = authState?.idToken?.claims?.name;
    let isIQMUser = false;

    isIQMUser = commonUtilities.isIQMUser();

    const handleLogOut = () => {
        localStorage.removeItem("userLanguage");
        localStorage.removeItem("okta-original-uri-storage");
        localStorage.removeItem("okta-cache-storage");
        localStorage.removeItem("okta-shared-transaction-storage");

        oktaAuth.signOut();
    };

    const language = localStorage.getItem("userLanguage");

    const logoUrl = language === "en" ? "https://assets.sunwingtravelgroup.com/image/upload/f_svg/v1711811546/WestJet/DIGITAL/VWQ/LOGOS/vacances-westjet-quebec-logo-en.svg"
    : "https://assets.sunwingtravelgroup.com/image/upload/f_svg/v1711811546/WestJet/DIGITAL/VWQ/LOGOS/vacances-westjet-quebec-logo-fr.svg";
    
    if (auth) {
        let lang = localStorage?.getItem("userLanguage");
        Moment.locale(lang);

        const englishLinks = [
            {
                id: "1",
                linkText: "New group travel quote",
                target: "_self",
                url: "/home"
            },
            {
                id: "2",
                linkText: "Review all group quotes",
                target: "_self",
                url: "/review"
            }
        ];

        const frenchLinks = [
            {
                id: "1",
                linkText: "Nouveau devis de voyage de groupe",
                target: "_self",
                url: "/home"
            },
            {
                id: "2",
                linkText: "Revoir une Soumission déjà existant",
                target: "_self",
                url: "/review"
            }
        ];

        const links = language === "en" ? englishLinks : frenchLinks;

        const oktaWelcome = language === "en" ? "Hi" : "Bonjour";

        return (
            <div className="row app-header" data-testid="header-component">
                <Header
                    labels={
                        {
                            oktaWelcome: oktaWelcome
                        }
                    }
                    data-testid="app-header"
                    currentLanguage="en"
                    languagePages={[]}
                    logoOnly={isIQMUser}
                    logo={ <div className="logo-container">
                        <img src={logoUrl} alt="Westjet vacation Quebec" />
                        </div>
                    }
                    links={links}
                    menuLinks={null}
                    profileData={{
                        isUserLoggedIn: true,
                        userName: userName
                    }}
                    renderLink={(link, { classNames }) => (
                        <NavLink to={link.url} className={(classNames.link, classNames.isActive)}>
                            {link.linkText}
                        </NavLink>
                    )}
                >
                    <div
                        className="user-menu"
                        slot="okta"
                    >
                        <div data-testid="logout-btn" onClick={handleLogOut}>{Localize("qma-sign-out")}</div>
                    </div>
                </Header>
            </div>
        );
    }
};

export default AppHeader;