import commonUtilities from "utilities/common";

async function executeQuery(endpoint, config) {
    try {

        const response = await fetch(endpoint, config)
            .then((res) => res.json())
            .then((json) => {
                return json;
            });

        return Promise.resolve(response);

    } catch (err) {
        return Promise.reject(err.message);
    }
}
export async function client(endpoint, { body, method, ...customConfig } = {}) {

    let accessToken;
    await commonUtilities.getOktaToken().then(res => accessToken = res?.accessToken || null);

    const headers = {
        "Authorization": "Bearer " + accessToken,
        "Content-Type": "application/json",
        "Lead-Number": (window.location.pathname.split("/").length > 0) ? window.location.pathname.split("/")[2] : null
    };

    const config = {
        method: method, ...customConfig,
        headers: { ...headers, ...customConfig.headers }
    };

    if (body) {
        config.body = JSON.stringify(body);
    }

    return executeQuery(endpoint, config);

}

client.get = function (endpoint, customConfig = {}) {
    return client(endpoint, { ...customConfig, method: "GET" });
};

client.post = function (endpoint, body, customConfig = {}) {
    return client(endpoint, { ...customConfig, method: "POST", body });
};

client.put = function (endpoint, body, customConfig = {}) {
    return client(endpoint, { ...customConfig, method: "PUT", body });
};
