import * as actions from "./actions";

export default function quoteItineraryReducer(state = {}, action) {
    switch (action.type) {

    case actions.GET_QUOTE_ITINERARY_SUCCESS:
    case actions.GET_QUOTE_ITINERARY_FAIL:
    case actions.UPDATE_QUOTE_ITINERARY_SUCCESS:
    case actions.UPDATE_QUOTE_ITINERARY_FAIL:
    {
        return action.payload;
    }
    default:
        return state;

    }
}
