import React, { useMemo } from "react";
import { Route, useRoute } from "wouter";

import SignIn from "modules/okta/signin";
import { useOktaAuth } from "@okta/okta-react";
import AppFooter from "@APP_FOOTER";
import AppHeader from "@APP_HEADER";
import commonUtilities from "utilities/common";

const PrivateRoute = (props) => {
    const { component: Element, path } = props;
    const [match, params] = useRoute(path);
    const { authState } = useOktaAuth();
    const isLoggedIn = useMemo(() => authState?.isAuthenticated || false, [authState]);

    const hasAccess = !commonUtilities.isCustomerUser() || (commonUtilities.isCustomerUser() && process.env.REACT_APP_BRAND === 'swg');

    return (
        <Route exact path={path}>
            {!isLoggedIn ? (
                <SignIn />
            ) : (hasAccess ? (
                <div className="base-container"> 
                    <AppHeader />
                    <div className="main">
                        <Element params={params ? params : {}}/>
                    </div>
                    <AppFooter />
                </div>
            ) : null)}
        </Route>
    );
};

export default PrivateRoute;
