import moment from "moment";

export const getResponse = (data) => {
    return {
        loading: true,
        status: "SUCCESS",
        data
    };
};

export const getErrorResponse = (error) => {
    let errors = [{ errorMessage: error }];
    return {
        loading: true,
        status: "PRECONDITION_FAILED",
        error,
        errors,
        message: null,
        token: null,
        payload: null
    };
};

export const allQuoteOptionsExpired = (quote) => {
    return quote?.quoteOptions?.filter(option => option.noOfDaysToExpire !== 0)?.length === 0;
};

export const getManualQuoteStartDate = () => {
    return moment().add(1, "days").add(8, "months").format("YYYY-MM-DD");
};