import React from "react";
import { Icon } from "@ICON_REFERENCE";
import { Localize } from "utilities/localize";

export default function NextButton(props) {
    let { data, events } = props;
    let { currentStep } = data;

    if (currentStep > 2) return;

    return (
        <div className="col-3-no-margin-r wizard-button pull-right" >
            <div className="pull-right next-link-button font-bold" data-testid="next-button" onClick={() => {
                (currentStep === 1)
                    ? events?.isStep1DataValid(true) : events?.isStep2DataValid(true);
            }}>
                <div className="pull-left font-16">
                    {Localize("qma-navigation-next")}
                </div>
                <div className="pull-left">
                    <Icon name="arrow-right" className="arrow-r" />
                </div>
            </div>
        </div>
    );
}

