import { client } from "api/client";
import * as actions from "modules/common/store/actions";
import moment from "moment";
import { CONFIGURATION } from "utilities/configuration";
import { GetLanguageCode } from "utilities/localize";
import * as utility from "utilities/utils";

export default function getHotels( providerCode, startDate, controller={}, isManualQuote) {
    const LANGUAGE = GetLanguageCode();
    const DESTINATION_PROVIDER_CODE = providerCode.toLowerCase();
    const SEARCH_ALIAS = CONFIGURATION.SEARCH_ALIAS;
    const START_DATE = isManualQuote ? utility.getManualQuoteStartDate() : moment(startDate).format("YYYY-MM-DD");
    const BRAND_CODE = CONFIGURATION.SEARCH_BRAND_CODE;
    const HOTEL_URL = process.env.REACT_APP_HOTEL + "?lang=" + LANGUAGE + "&airport=" + DESTINATION_PROVIDER_CODE + "&alias=" + SEARCH_ALIAS + "&s_dt=" + START_DATE + (BRAND_CODE === "" ? "" : ("&brand=" + BRAND_CODE));

    return async (dispatch) => {
        return await client.get(HOTEL_URL, { signal: controller.signal }).then((response) => {

            let hotels = [];

            response?.hotels?.map(hotel => {
                hotels.push({ 
                    code: hotel?.Code, 
                    name: hotel?.Name, 
                    city: hotel?.Destination?.Name, 
                    country: hotel?.Destination?.CountryName, 
                    location: hotel?.Address, 
                    url: hotel?.Images?.hasOwnProperty("Img4x3") ? hotel?.Images["Img4x3"]?.length ? "https:" + hotel?.Images["Img4x3"][0]?.Url : "" : "" });
            });
            hotels.sort((a, b) => a.name.localeCompare(b.name));
            return dispatch({
                type: actions.GET_HOTELS_SUCCESS,
                payload: utility.getResponse(hotels)
            });

        })
            .catch((error) => {
                return dispatch({
                    type: actions.GET_HOTELS_FAIL,
                    payload: utility.getErrorResponse(error)
                });

            });

    };
}
