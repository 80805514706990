import React, { Component } from "react";
import { connect } from "react-redux";
import { Section } from "@sunwing/components";
import { withRouter } from "utilities/withRouter";
import * as actions from "modules/common/store/actions";
import StayTuned from "components/common/stayTuned/stayTuned";
import PageHeader from "components/common/pageHeader/pageHeader";
import { TimeOutModal } from "components/timeOutModal/timeOutModal";


import CreateQuoteWizard from "./createQuoteWizard";

import "./createQuote.css";

class CreateQuote extends Component {
    constructor(props) {
        super(props);
    };

    render() {
        return (
            <div className="row create-quote-container">
                {this.props.quote?.timedOut ? (
                    <div className="row margin-top-1x">
                        <Section>
                            <PageHeader />
                        </Section>
                        <StayTuned />
                        <TimeOutModal handleNavigate={() => {
                            this.props.navigate("/review");
                            this.props.dispatch({
                                type: actions.QUOTE_RESET
                            });
                        }}/>
                    </div>
                ) : (
                    <div className="app-container">
                        <Section>
                            <PageHeader />
                            <CreateQuoteWizard/>
                        </Section>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    quote: state.quote
});
export default connect(mapStateToProps)(withRouter(CreateQuote));