import { client } from "api/client";
import * as actions from "modules/common/store/actions";
import * as endPoints from "utilities/endPoints";
import * as utility from "utilities/utils";


export default function getGroupEventTypes(languageCode) {

    return async (dispatch) => {

        return await client.post(process.env.REACT_APP_GROUP_TYPES, endPoints.API_PARAMS.GetGroupTypesPayload(languageCode)).then((response) => {

            let groupTypes = [];

            response?.data?.qmaEventTypeCollection?.items.map(item => {
                groupTypes.push({ name: item.eventTypeName, code: item.eventKey });
                   
            });

            return dispatch({
                type: actions.GET_GROUP_TYPES_SUCCESS,
                payload: utility.getResponse(groupTypes)
            });

        })
            .catch((error) => {

                return dispatch({
                    type: actions.GET_GROUP_TYPES_FAIL,
                    payload: utility.getErrorResponse(error)
                });

            });

    };

}
