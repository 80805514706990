import React, { useState }  from "react";
import Button from "@mui/material/Button";

import { Localize } from "utilities/localize";
import { withRouter } from "utilities/withRouter";
import "./index.scss";

function MarkAsComplete(props) {
    const { data, isShow, handleMarkAsComplete, handleCancel } = props;
    const { quoteItinerary } = data;
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [waitingMessage, setWaitingMessage] = useState("");
    const handleClick = () => {
        setWaitingMessage("Please wait, we are processing your request.");
        setIsButtonDisabled(true);
        handleMarkAsComplete();
    }

    return (
        <div className={isShow ? "show" : "hide"}>

            <div className="popup-container-bg delete-modal"></div>

            <div className="popup-container">

                <div className="popup-body">

                    <div className="popup-heading margin-bottom-1x">
                        {quoteItinerary?.data?.markAsCompletePopUpTitle}
                    </div>

                    <div className="popup margin-bottom-1x">
                        {quoteItinerary?.data?.markAsCompletePopUpText}
                    </div>

                    <div className="row popup-heading-1x">
                        {Localize("qma-make-change")}
                    </div>
                   
                    <div className="row margin-top-3x yes-no-option-btn">
                        <Button
                            data-testid={"deletemodal-yes-button"}
                            onClick={() => handleClick ()}
                            disabled={isButtonDisabled}
                            className="margin-right-1x yes-btn"
                            variant="contained"
                        >
                            {props.localize("qma-yes")}
                        </Button>
                        <Button 
                            data-testid={"deletemodal-no-button"} 
                            className="no-btn" 
                            disabled={isButtonDisabled}
                            onClick={() => handleCancel()} variant="outlined">
                            {props.localize("qma-no")}
                        </Button>
                    </div>
                    <div className="row popup-heading-1x">
                        {waitingMessage}
                    </div>

                </div>

            </div>

        </div>
    );
}

export default withRouter(MarkAsComplete);
