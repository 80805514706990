import React, { Component } from "react";
import { connect } from "react-redux";
import { Autocomplete, Button, Checkbox, FormLabel, MenuItem, TextField } from "@mui/material";

import getDeviationDestinations from "services/destination/destinationDeviation.service";
import getDeviationDeparture from "services/destination/departureDeviation.service";
import commonUtilities from "utilities/common";
import { Localize } from "utilities/localize";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Style } from "./departureCityMultiSelect.scss";

class MultiSelectDestinations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuOpened: false,
            destinations: [],
            selectedValues: []
        };
    }
    componentDidMount() {
        this.getDestinations();
    }

    getDestinations() {
        this.props.dispatch(getDeviationDestinations()).then((response) => {
            let data = response?.payload?.data;
            if (data && data?.length > 0) {
                let { destinations } = this.state;
                data.map(country => {
                    country?.cities?.map(city => {
                        city["isChecked"] = false;
                    });
                });
                destinations = data;
                this.setState({ destinations });
            }

        });
    }
    saveDestinations = () => {
        let { menuOpened, selectedValues } = this.state;
        let { handleStep2Data, onDestinationChange } = this.props;
        let productType = commonUtilities.getProductType(this.props);

        let selectedCities = [];
        menuOpened = false;
        this.setState({ menuOpened });

        if (selectedValues?.length > 0) {
            selectedValues.map(city => {
                selectedCities.push(city.providerCode);
            });

            // handleStep2Data({ ...productType });
            // onDestinationChange();
            this.props.dispatch(getDeviationDeparture(selectedCities)).then(response => {
                console.log("response ", response);
            });

        }


    };
    clearSelections = () => {
        let { destinations, selectedValues } = this.state;
        destinations.map(country => {
            country?.cities?.map(city => {
                city["isChecked"] = false;
            });
        });
        selectedValues = [];
        this.setState({ destinations, selectedValues });

    };

    // handleData = (event, city) => {
    //     let { handleStep2Data, onDestinationChange } = this.props;

    //     let productType = commonUtilities.getProductType(this.props);
    //     console.log("producttype", productType);

    //     const { destination } = productType;

    //     destination.code = city ? city.code : "";
    //     destination.name = city ? city.name : "";
    //     destination.providerCode = city ? city.providerCode : "";

    //     handleStep2Data({ ...productType });
    //     onDestinationChange();
    // };
    updateSelectedValues(value, city, countryIndex, cityIndex) {
        let { selectedValues, destinations } = this.state;
        if (value) {
            selectedValues.push({
                code: city?.code,
                name: city?.name,
                providerCode: city?.providerCode
            });
            destinations[countryIndex].cities[cityIndex].isChecked = true;
        } else {
            let foundIndex = selectedValues?.findIndex(e => e.code === city.code);
            selectedValues?.splice(foundIndex, 1);
            destinations[countryIndex].cities[cityIndex].isChecked = false;
        }
        this.setState({ selectedValues });
    }

    getRenderText(cities) {
        let text = [];
        if (cities?.length > 1) {
            cities.map(city => {
                text.push(`${"[" + city?.code + "] " + city?.name}`);
            });
            return text.join(", ");
        } else {
            return `${"[" + cities[0]?.code + "] " + cities[0]?.name}`;
        }
    }

    render() {
        let { citiesMasterData, error } = this.props;
        let { menuOpened, selectedValues, destinations } = this.state;
        let productType = commonUtilities.getProductType(this.props);


        if (!productType) return;

        let { destination } = productType;
        let cities = citiesMasterData?.data?.cities
            ? citiesMasterData?.data?.cities || []
            : [];
        let selectPlaceHolder = Localize("qma-select-label");

        return (
            <div >
                <div data-testid="label-destination">
                    Destinations
                </div>

                <input type="text"
                    className={this.state.menuOpened ? "departure-city-input menu-open" : "departure-city-input"}
                    onClick={() => this.setState({ menuOpened: true })}
                    value={selectedValues?.length > 0 ? this.getRenderText(selectedValues) : selectPlaceHolder}
                    readOnly="true"
                />
                <span className={this.state.menuOpened ? "select-caret invert" : "select-caret"}><ArrowDropDownIcon /></span>
                {menuOpened ?
                    <div className="departure-city-wrapper ">
                        <div className="departure-city-popup">
                            <div className="select-info">Select up to 2 destinations</div>
                            <div className="list-container">
                                <div className="countries-container">
                                    <ul>
                                        {destinations?.map((country, countryIndex) => (
                                            <li className="countries" keys={countryIndex}>
                                                <div className="country-label">{country?.countryName}</div>
                                                <div className="cities-container">
                                                    {country?.cities?.map((city, cityIndex) => (
                                                        <div className="cities">
                                                            <Checkbox
                                                                id={`${"chekbox-" + countryIndex + "-" + cityIndex}`}
                                                                disabled={selectedValues?.length > 1 && !city.isChecked}
                                                                checked={city.isChecked}
                                                                onChange={(e) => this.updateSelectedValues(e?.target?.checked, city, countryIndex, cityIndex)} />
                                                            <div className="cities-label">{city?.name}</div>
                                                        </div>
                                                    ))}

                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                        </div>
                        <div className="bottom-section">
                            <Button variant="outlined" size="small" className="ft-right" onClick={() => this.saveDestinations()}> Done</Button>
                            <Button variant="text" size="small" className="ft-right" onClick={() => this.clearSelections()}> Clear all</Button>
                        </div>

                    </div> : null

                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    citiesMasterData: state.citiesMasterData
});

export default connect(mapStateToProps)(MultiSelectDestinations);