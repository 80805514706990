import * as actions from "./actions";

export default function roomTypesReducer(state = [], action) {
    switch (action.type) {

    case actions.GET_ROOM_TYPES_SUCCESS:
    case actions.GET_ROOM_TYPES_FAIL:
    {
        return action.payload;
    }
    default:
        return state;

    }
}
