import { client } from "api/client";
import * as actions from "modules/common/store/actions";
import * as endPoints from "utilities/endPoints";
import * as utility from "utilities/utils";
import commonUtilities from "utilities/common";
import { CONSTANTS } from "utilities/constants";

let productTypes = [
    { sequence: 1, code: "Package" }
    , { sequence: 2, code: "Flight" }
    , { sequence: 3, code: "Hotel" }
    , { sequence: 4, code: "Cruise" }
    , { sequence: 5, code: "Deviation" }
];

export default function getGroupProductTypes(languageCode) {

    return async (dispatch) => {
        const isIQMUser = commonUtilities.isIQMUser();
        return await client.post(process.env.REACT_APP_PRODUCT_TYPES, endPoints.API_PARAMS.GetProductTypesPayload(languageCode)).then((response) => {
          
            let groupTypes = [];
            response?.data?.tourOperatorCollection?.items.map(tourOperatorItem => {

                tourOperatorItem?.linkedFrom?.qmaProductTypeCollection?.items.map(item =>{

                    if(item.productTypeInternalOnly && !isIQMUser) {
                        productTypes = productTypes.filter((productType) => productType.code.trim().toUpperCase() !== item.productKey.trim().toUpperCase());
                    }
                    productTypes.map(productType => {
                        if(productType.code.trim().toUpperCase() === item.productKey.trim().toUpperCase()) {
                            if (!isIQMUser) {
                                if (productType.code.trim().toUpperCase() !== CONSTANTS.DEVIATION) {
                                    groupTypes.push({ sequence: productType.sequence, name: item.productTypeName, code: productType.code });
                                }
                            } else {
                                groupTypes.push({ sequence: productType.sequence, name: item.productTypeName, code: productType.code });
                            }
                        }
                    }); 
                });

            });

            return dispatch({
                type: actions.GET_PRODUCT_TYPES_SUCCESS,
                payload: utility.getResponse(groupTypes.sort((a, b) => a.sequence - b.sequence))
            });

        })
            .catch((error) => {

                return dispatch({
                    type: actions.GET_PRODUCT_TYPES_FAIL,
                    payload: utility.getErrorResponse(error)
                });

            });

    };

}
