export const CONSTANTS = {

    DEFAULT_STRING_VALUE: "",
    VACATION_PAGE_INFORMATION: "qma-my-group-vacation-page-information",
    QUOTE_OPTION_SECTION_HEADING: "qma-wizard-information",
    DATE_FORMAT: "DD MMM YYYY",
    DEFAULT_NO_OF_TRAVELLERS: 10,
    MINIMUM_VACATION_DAYS: 8,
    HOLIDAY_PACKAGE: "Holiday package",
    HOTEL_ONLY: "Hotel only",
    AVAILABLE: "Available",
    UNAVAILABLE: "Unavailable",
    PENDING: "Pending",
    ACTIVE: "Active",
    INACTIVE: "Inactive",
    CONFIRMED: "Confirmed",
    MODAL: "MODAL",
    QUOTE: "quote",
    SHOW: "show",
    HIDE: "hide",
    DEVIATION: "DEVIATION",
    EXCURSION: "EXCURSION",
    MANUAL: "MANUAL"

};
