import React, { Component } from "react";
import "./progressIndicator.css";

export default class ProgressIndicator extends Component {
    render() {
        const { steps, activeStep } = this.props;
        return (
            <div className="progress-indicator-container">
                <div
                    data-testid={"progress-indicator"}
                    className={"progress-indicator"}
                    style={{ width: `${(100 / steps) * activeStep}%` }}
                >
          &nbsp;
                </div>
            </div>
        );
    }
}
