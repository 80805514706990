import moment from "moment";
import { CONFIGURATION } from "utilities/configuration";
import { Localize } from "utilities/localize";
import { productsModel } from "./products";

export const productTypeModel = {
    sequence: 1,
    optionID: 0,
    quoteID: 0,
    productType: {
        code: "Package",
        name: Localize("qma-holiday-package")
    },
    destination: {
        providerCode: "",
        code: "",
        name: ""
    },
    departure: {
        providerCode: "",
        code: "",
        name: ""
    },
    departureDate: null,
    returnDate: null,
    noOfAdults: "",
    noOfChildren: "",
    isManualQuote: false,
    language: "en",
    duration: CONFIGURATION.MINIMUM_VACATION_DAYS - 1,
    products: productsModel
};


export const deviationTypeModel = {
    sequence: 1,
    optionID: 0,
    quoteID: 0,
    productType: {
        code: "Deviation",
        name: "Deviation"
    },
    departures: [],
    departureDate: null,
    returnDate: null,
    isManualQuote: false,
    noOfAdults: "",
    noOfChildren: "",
    language: "en",
    deviation: [
        {
            destination: {
                providerCode: "",
                code: "",
                name: ""
            },
            products: []
        },
        {
            destination: {
                providerCode: "",
                code: "",
                name: ""
            },
            products: []
        }
    ]
};