import * as actions from "./actions";

export default function citiesReducer(state = [], action) {
    switch (action.type) {

    case actions.GET_CITIES_SUCCESS:
    case actions.GET_CITIES_FAIL:
    {
        return action.payload;
    }
    default:
        return state;

    }
}
