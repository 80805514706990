import React from "react";
import { Navigate } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";

function SignIn() {

    const { authState, oktaAuth } = useOktaAuth();
    //console.log(authState);

    return authState?.isAuthenticated && authState?.accessToken ?
        <div className="row">
            { window?.location?.href?.includes("lead") ? <div>Lead</div> : <Navigate to="/home" />}
        </div>

        : null
    ;
  
}

export default SignIn;
