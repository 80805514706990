import { client } from "api/client";
import email from "components/common/email/email";
import * as actions from "modules/common/store/actions";
import * as utility from "utilities/utils";

function getEmailList(params) {
    return async (dispatch) => {
 
        const url = process.env.REACT_APP_EMAIL_GET;
    
        return await client.get(`${url}/${params.quoteNumber}`).then((response) => {
      
            return dispatch({
                type: actions.GET_EMAIL_LIST_SUCCESS,
                payload: utility.getResponse(response)
            });
        })
            .catch((error) => {
                return dispatch({
                    type: actions.GET_EMAIL_LIST_FAIL,
                    payload: utility.getErrorResponse(error)
                });
            });
    };
}

function setEmailUpdate(params) {
    return async (dispatch) => {
 
        const url = process.env.REACT_APP_EMAIL_ADD;
    
        return await client.post(`${url}/${params?.quoteNumber}`, params?.emails).then((response) => {
      
            return dispatch({
                type: actions.GET_EMAIL_ADD_SUCCESS,
                payload: utility.getResponse(response)
            });
        })
            .catch((error) => {
                return dispatch({
                    type: actions.GET_EMAIL_ADD_FAIL,
                    payload: utility.getErrorResponse(error)
                });
            });
    };
}

function sendEmail(params) {
    return async (dispatch) => { 
    
        return await client.post(process.env.REACT_APP_EMAIL, params).then((response) => {
      
            return dispatch({
                type: actions.GET_EMAIL_ADD_SUCCESS,
                payload: utility.getResponse(response)
            });
        })
            .catch((error) => {
                return dispatch({
                    type: actions.GET_EMAIL_ADD_FAIL,
                    payload: utility.getErrorResponse(error)
                });
            });
    };
}


const emailService = {
    getEmailList,
    setEmailUpdate,
    sendEmail
};

export default emailService;

